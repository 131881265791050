<template>
  <div ref="treemap" class="treemap-container"></div>
</template>

<script>
import * as d3 from 'd3'
import tooltip from '@/util/tooltip'
import { colorsForDocumentTypes } from '@/util/util.js'

export default {
  props: {
    data: {
      type: Object, // expects hierarchical data in JSON format
      required: true
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 300
    }
  },
  mounted() {
    this.drawTreemap()
    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  watch: {
    data: {
      handler(newData) {
        this.drawTreemap(newData)
      },
      deep: true
    }
  },
  methods: {
    colorsForDocumentTypes,
    onResize() {
      this.drawTreemap() // Re-draw the treemap when the window is resized
    },
    drawTreemap() {
      const container = this.$refs.treemap
      const width = container.offsetWidth // Get width of the container
      const height = container.offsetHeight // Get height of the container

      const margin = { top: 0, right: 20, bottom: 20, left: 20 }
      const svgWidth = width - margin.left - margin.right
      const svgHeight = height - margin.top - margin.bottom

      // Clear previous chart content
      d3.select(this.$refs.treemap).selectAll('*').remove()

      // Set up the SVG container
      const svg = d3
        .select(this.$refs.treemap)
        .append('svg')
        .attr('viewBox', `0 0 ${width} ${height}`)
        .attr('preserveAspectRatio', 'xMidYMid meet')
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`)

      // Create the root hierarchy from data
      const root = d3
        .hierarchy(this.data)
        .sum((d) => d.value)
        .sort((a, b) => b.value - a.value)

      // Create a Treemap layout
      d3
        .treemap()
        .tile(d3.treemapSquarify)
        .size([svgWidth, svgHeight])
        .round(true)
        .padding(1)(root)

      // Create the tooltip div element
      const tooltip = d3
        .select(this.$refs.treemap)
        .append('div')
        .attr('class', 'tooltip')
        .style('position', 'fixed')
        .style('opacity', 0)
        .style('background-color', 'rgba(0, 0, 0, 0.7)')
        .style('color', 'white')
        .style('padding', '5px')
        .style('border-radius', '4px')
        .style('pointer-events', 'none') // Avoid tooltip interfering with mouse events

      // Draw each node as a rectangle
      svg
        .selectAll('rect')
        .data(root.leaves())
        .enter()
        .append('rect')
        .attr('x', (d) => d.x0)
        .attr('y', (d) => d.y0)
        .attr('width', (d) => d.x1 - d.x0)
        .attr('height', (d) => d.y1 - d.y0)
        .attr('stroke', 'white')
        .style('fill', function (d) {
          let color = colorsForDocumentTypes(
            root
              .leaves()
              .map((leaf) => leaf.data.name)
              .indexOf(d.data.name)
          )
          return color
        })
        .on('mouseover', function (event, d) {
          tooltip.style('opacity', 1).html(`${d.data.name} : ${d.value}`)
          d3.select(this).attr('opacity', 1)
        })
        .on('mousemove', function (event) {
          // Position tooltip based on viewport coordinates
          tooltip
            .style('left', `${event.clientX - 120}px`)
            .style('top', `${event.clientY - 20}px`)
        })
        .on('mouseout', function () {
          tooltip.style('opacity', 0)
          d3.select(this).attr('opacity', 1)
        })

      // Add labels with conditional rendering
      svg
        .selectAll('text')
        .data(root.leaves())
        .enter()
        .append('text')
        .attr('x', (d) => d.x0 + 5)
        .attr('y', (d) => d.y0 + 20)
        .text((d) => {
          const textWidth = d.x1 - d.x0
          return textWidth > 110 ? `${d.data.name} (${d.value})` : '' // Display only if width is sufficient
        })
        .attr('fill', 'black')
        .attr('font-size', '14px')
        .attr('pointer-events', 'none')
    }
  }
}
</script>

<style scoped>
.treemap-container {
  width: 100%;
  height: 50vh;
  max-height: 350px;
}
.treemap rect {
  transition: opacity 0.3s;
}
.tooltip {
  position: absolute;
  font-size: 12px;
  z-index: 10;
}
</style>
