<template>
  <div
    class="nested-navigation-container"
    ref="innerNavigationContainer"
    v-if="
      (currentModule === 'voc' ||
        currentModule === 'voe' ||
        currentModule === 'reports-repository' ||
        currentModule === 'data-management-platform' )&&
      !$route.meta.hideNestedTopNav
    "
  >
    <div class="middle-layer">
      <div class="page-groups-list">
        <template v-for="item in pageGroup">
          <div
            class="page-group"
            v-if="item.isDropdown"
            :class="[item.group === $route.meta.group && 'active']"
            :key="item.routeName"
            :to="getDestinationRouteObject(item)"
          >
            <span>
              {{ item.label }}
              <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
            </span>
            <ul class="dropdown">
              <li
                class="list-item"
                v-for="item in pageList(item.group)"
                :class="{ selected: active_page === item.routeName }"
                :key="item.routeName"
              >
                <router-link :to="{ name: item.routeName }">{{
                  item.label
                }}</router-link>
              </li>
            </ul>
          </div>
          <router-link
            class="page-group"
            v-if="!item.isDropdown"
            :class="[item.group === $route.meta.group && 'active']"
            :to="getDestinationRouteObject(item)"
            :key="item.routeName"
            >{{ item.label }}</router-link
          >
        </template>
      </div>
      <div
        class="last-updated"
        v-if="currentModule === 'voc' || currentModule === 'voe'"
      >
        <p class="last-updated-text">Last Updated:</p>
        <p class="last-updated-date" v-if="lastUpdatedDate">
          {{ lastUpdatedDate }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { checkPathPermission } from '@/util/permissions.utils'
export default {
  name: 'NestedTopNavigation',
  computed: {
    ...mapState('client', ['lastUpdatedDateVoc', 'lastUpdatedDateVoe']),
    ...mapState('user', ['userDetails']),
    ...mapState('common', ['active_page', 'currentModule']),

    lastUpdatedDate() {
      if (this.currentModule === 'voc' || this.currentModule === 'tam') {
        return this.lastUpdatedDateVoc
      } else if (this.currentModule === 'voe') {
        return this.lastUpdatedDateVoe
      }
      return undefined
    },
    pageGroup() {
      if (this.currentModule === 'voc') {
        return [
          {
            routeName: 'summary',
            label: 'Statistics',
            group: 'statistics',
            isDropdown: true
          },
          {
            routeName: 'csat',
            label: 'Performance Drivers',
            group: 'drivers',
            isDropdown: true
          },
          {
            routeName: 'comments',
            label: 'Comments',
            group: 'comments',
            isDropdown: false
          }
        ]
      } else if (this.currentModule === 'voe') {
        return [
          {
            routeName: 'voeSummary',
            label: 'Statistics',
            group: 'statistics',
            isDropdown: true
          },
          {
            routeName: 'voeTrends',
            label: 'Performance Drivers',
            group: 'drivers',
            isDropdown: true
          },
          {
            routeName: 'voeComments',
            label: 'Comments',
            group: 'voeComments',
            isDropdown: false
          }
        ]
      } else if (this.currentModule === 'reports-repository') {
        return [
          {
            routeName: 'investmentResearch',
            label: 'Investment Research',
            group: 'investment-research',
            isDropdown: true
          }
        ]
      } else if (this.currentModule === 'data-management-platform') {
         let dmpSubNavigation = [
          {
            routeName: 'workflowManagement',
            label: 'Workflow Management',
            group: 'workflow-management',
            isDropdown: true
          }
        ]
        if (checkPathPermission('dashboard', this.userDetails.roleName)) {
          dmpSubNavigation = [
            {
              routeName: 'dmpDashboard',
              label: 'Dashboard',
              group: 'dmpDashboard',
              isDropdown: false
            },
            ...dmpSubNavigation
          ]
        }
        if (checkPathPermission('user-management', this.userDetails.roleName)) {
          dmpSubNavigation = [
            ...dmpSubNavigation,
            {
              routeName: 'userManagement',
              label: 'User Management',
              group: 'user-management',
              isDropdown: false
            }
          ]
        }
        return dmpSubNavigation
      }

      return undefined
    }
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.updateAnalyticsEvent()
        if (to.name !== 'comments' && to.name !== 'voeComments') {
          this.updateSelectedAttribute([])
        }
        if (to.meta.module !== from.meta.module) {
          this.updatecustomSortedAttributes([])
          this.updateSelectedAttribute([])
          this.updateSelectedSSTopic(null)
        }
      }
    },
    currentModule(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getLastUpdatedDate()
      }
      if (oldValue === 'esg-controversies') {
        this.updateSearchText(null)
      }
    }
  },
  mounted() {
    this.getLastUpdatedDate()
  },
  updated() {
    const container = this.$refs.innerNavigationContainer
    if (container) {
      document
        .querySelector(':root')
        .style.setProperty('--inner-nav-height', container.clientHeight + 'px')
    }
  },
  methods: {
    ...mapActions('client', ['getLastUpdatedDateVoc', 'getLastUpdatedDateVoe']),
    ...mapActions('common', ['updateActivePage']),
    ...mapActions('analytics', ['updateEvent']),
    ...mapActions('sunriseSunset', [
      'updatecustomSortedAttributes',
      'updateSelectedAttribute',
      'updateSelectedSSTopic'
    ]),
    ...mapActions('esgControversies', ['updateSearchText']),
    /**
     * This method handles which module's last date calculation needs to be called
     * @public
     */
    getLastUpdatedDate() {
      if (this.currentModule === 'voc' || this.currentModule === 'tam') {
        this.getLastUpdatedDateVoc()
      } else if (this.currentModule === 'voe') {
        this.getLastUpdatedDateVoe()
      }
    },
    pageList(group) {
      if (this.currentModule === 'voe') {
        switch (group) {
          case 'drivers':
            return [
              {
                routeName: 'voeTrends',
                label: 'Emerging - Critical Priorities'
              },
              {
                routeName: 'voeEsat',
                label: 'Employee Satisfaction (ESAT) Measure'
              },
              {
                routeName: 'voeEvpMatrix',
                label: 'Employee Value Proposition (EVP) Matrix'
              }
            ]
          default:
            return [
              {
                routeName: 'voeSummary',
                label: 'Employee Satisfaction Index (ESI)'
              },
              { routeName: 'voeReports', label: 'Perception Watch' }
            ]
        }
      } else if (this.currentModule === 'voc') {
        switch (group) {
          case 'drivers':
            return [
              { routeName: 'csat', label: 'CSAT' },
              { routeName: 'vendor-csat', label: 'CSAT by Vendors' },
              { routeName: 'trends', label: 'Trending and Declining' },
              {
                routeName: 'competitive-positioning',
                label: 'Competitive Positioning'
              }
            ]
          default:
            return [
              { routeName: 'summary', label: 'Summary' },
              { routeName: 'reports', label: 'Reports' },
              { routeName: 'nps', label: 'Net Promoter Score' }
            ]
        }
      } else if (this.currentModule === 'data-management-platform') {
        switch (group) {
          case 'workflow-management':
            const workflowMangSubMenus = []

            // Assigned Entities
            if (
              checkPathPermission(
                'assigned-entities',
                this.userDetails.roleName
              )
            ) {
              workflowMangSubMenus.push({
                routeName: 'assigned-entities',
                label: 'Assigned Entities'
              })
            }

            // Exception Handling
            if (
              checkPathPermission(
                'exception-handling',
                this.userDetails.roleName
              )
            ) {
              workflowMangSubMenus.push({
                routeName: 'exception-handling',
                label: 'Exception Handling'
              })
            }

            // Corporate Action Details
            if (
              checkPathPermission('corporate-action', this.userDetails.roleName)
            ) {
              workflowMangSubMenus.push({
                routeName: 'corporate-action-details',
                label: 'Corporate Action Details'
              })
            }

            return workflowMangSubMenus
        }
      } else if (this.currentModule === 'tam') {
        return [{ routeName: 'tam', label: 'Customer Adoption Trends' }]
      } else if (this.currentModule === 'reports-repository') {
        const routeList = []
        const reportModule = this.userDetails.modules.find(
          (module) => module.module_name === 'reports-repository'
        )
        if (
          reportModule.moduleProperties &&
          reportModule.moduleProperties.subTypes &&
          reportModule.moduleProperties.subTypes.length > 0
        ) {
          for (const report of reportModule.moduleProperties.subTypes) {
            if (report.reportType === 'Equity Reports') {
              // Need to add equity type as the first option hence we will use unshift
              routeList.unshift({ routeName: 'equityReports', label: 'Equity' })
            }
            if (report.reportType === 'Fixed Income') {
              routeList.push({
                routeName: 'fixedIncome',
                label: 'Fixed Income'
              })
            }
          }
        }
        return routeList
      }
    },

    /** This method updates the analytics event
     * @public
     */
    updateAnalyticsEvent() {
      this.updateEvent({
        userId: this.userDetails.userId,
        event: 'page_view',
        label: '',
        pageUrl: window.location.href
      })
    },
    getDestinationRouteObject(pageGroupItem) {
      if (pageGroupItem.routeName === 'entities') {
        // Giving static value for now later it will be removed

        return { name: pageGroupItem.routeName, params: { sgaId: 'SGAK0001' } }
      }
      return { name: pageGroupItem.routeName }
    }
  }
}
</script>

<style lang="scss" scoped>
.nested-navigation-container {
  display: block;
  .middle-layer {
    display: flex;
    align-items: center;
    background: var(--page-groups-list-background);
    justify-content: space-between;
    padding: 25px 20px 0px;
  }
  .last-updated {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    p {
      display: flex;
    }
    .last-updated-text {
      color: var(--last-updated-text-color);
      font-family: Quicksand;
      margin-right: 5px;
      font-size: 14px;
    }
    .last-updated-date {
      color: var(--last-updated-date-color);
      font-family: Quicksand;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .page-groups-list {
    display: flex;
    .page-group {
      display: flex;
      font-weight: 500;
      padding: 6px 11px;
      font-size: 16px;
      color: var(--page-group-font-color);
      position: relative;
      svg {
        width: 13px;
        height: 13px;
        margin-left: 5px;
        & > * {
          fill: var(--page-group-font-color);
        }
      }
      &:hover {
        background: var(--hover-page-group-background-color);
        color: var(--hover-page-group-font-color);
        cursor: pointer;
        svg > * {
          fill: var(--hover-page-group-font-color);
        }
        .dropdown {
          display: flex;
        }
      }
      .dropdown {
        display: none;
        flex-direction: column;
        border: 0.5px solid var(--nav-dropdown-border-color);
        position: absolute;
        background: var(--nav-dropdown-background);
        min-width: 100%;
        width: max-content;
        max-width: 400px;
        top: 100%;
        left: 0;
        z-index: 100;
        .list-item {
          a {
            padding: 7px 12px;
            display: flex;
            color: var(--page-list-font-color);
            width: 100%;
          }
          &:hover {
            a {
              color: var(--hover-page-list-font-color);
            }
            background: var(--hover-nav-dropdown-background);
          }
        }
      }
    }
    .active {
      background: var(--active-page-group-background-color);
      color: var(--active-page-group-font-color);
      svg > * {
        fill: var(--active-page-group-font-color);
      }
    }
    .selected {
      background: #b0beed;
    }
  }
}

@media screen and (min-width: 1700px) {
  .nested-navigation-container {
    .middle-layer {
      padding: 15px 30px 0;
    }
    .page-groups-list {
      .page-group {
        padding: 7px 20px;
        font-size: 20px;
      }
    }
  }
}
</style>
