<template>
  <div class="audit-details-container">
    <div class="local-loader-container" v-if="loading">
      <div class="inava-loader"></div>
    </div>
    <Grid
      ref="grid"
      :data-items="gridData"
      :columns="columns"
      :pageable="gridPageable"
      :take="take"
      :skip="skip"
      :total="totalRecords"
      @datastatechange="dataStateChange"
      @pagechange="pageChangeHandler"
    >
    </Grid>
  </div>
</template>
<script>
import { Grid } from '@progress/kendo-vue-grid'
import { mapActions, mapGetters, mapState } from 'vuex'
import { process } from '@progress/kendo-data-query'

export default {
  components: {
    Grid: Grid
  },

  data: function () {
    return {
      updatedData: [],
      editID: null,
      group: [{ field: 'UnitsInStock' }],
      expandedItems: [],
      loading: false,
      gridPageable: {
        buttonCount: 4,
        info: true,
        type: 'numeric',
        pageSizes: [10, 50, 100, 200],
        previousNext: true
      },
      gridData: [],
      auditDetailsItems: [],
      skip: 0,
      take: 10,
      total: this.totalRecords,
      columns: [
        {
          field: 'attributeName',
          title: 'Attribute Name'
        },
        {
          field: 'oldValue',
          title: 'Previous Value'
        },
        {
          field: 'newValue',
          title: 'New Value'
        },

        { field: 'changedBy', title: 'User' },
        {
          field: 'timeStamp',
          title: 'Timestamp'
        }
      ]
    }
  },
  computed: {
    ...mapState('audit', ['auditDetails', 'limit', 'offset', 'totalRecords']),
    ...mapGetters('dmp', ['getEntityDetails'])
  },

  watch: {
    auditDetails: {
      handler() {
        this.auditDetailsItems = this.auditDetails
        this.updateGridData()
      },
      immediate: true
    },
    limit(newVal) {
      this.take = newVal
    },
    offset(newVal) {
      this.skip = newVal
    },
    totalRecords(newVal) {
      this.total = newVal
    }
  },

  methods: {
    ...mapActions('audit', ['getAuditDetails', 'setLimitAndOffset']),

    pageChangeHandler: async function (event) {
      this.skip = event.page.skip // offset
      this.take = event.page.take // limit
      this.setLimitAndOffset({ limit: this.take, offset: this.skip })
      this.loading = true
      const { sgaId } = this.getEntityDetails
      await this.getAuditDetails(sgaId)
      this.loading = false
    },
    updateGridData() {
      const processedData = this.processGridData(this.auditDetailsItems)
      this.gridData = processedData.data
      this.total = processedData.total
    },
    processGridData(data) {
      return process(data, {
        take: this.take,
        skip: 0 // hardcoding skip to 0 as data is fetched through server side pagination
      })
    },
    dataStateChange(event) {
      this.take = event.data.take
      this.skip = event.data.skip
      this.sort = event.data.sort
      this.updateGridData()
    }
  }
}
</script>

<style lang="scss">
.audit-details-container {
  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    background: rgba(0, 0, 0, 0.1);
    z-index: 9;
    .inava-loader {
      position: relative;
      display: flex;
      margin: auto;
      border-radius: 50%;
      border: none;
      z-index: 900;
      width: 70px;
      height: 70px;
      background-image: '@/assets/Inava-logo-GIF.gif';
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .k-grid .k-grid-header {
    background: #ebebeb;
  }
  .k-table-td {
    font-size: 14px;
    color: #444444;
  }
  .k-column-title {
    font-size: 14px;
    color: #000000;
  }
}
@media (min-width: 1450px) and (max-width: 1700px) {
  .audit-details-container {
    .k-table-td {
      font-size: 16px;
    }
    .k-column-title {
      font-size: 16px;
    }
  }
}
@media (min-width: 1700px) {
  .audit-details-container {
    .k-table-td {
      font-size: 18px;
    }
    .k-column-title {
      font-size: 18px;
    }
  }
}
</style>
