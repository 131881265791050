import Vue from 'vue'
import Snackbar from '@/components/Snackbar'

export default {
  namespaced: true,
  state: {
    exceptionHandlingList: [],
    exceptionHandlingDuplicates: [],
    exceptionTypeFilter: [],
    limit: 10,
    offset: 0,
    totalRecords: 0,
    searchQuery: '',
    filterOptions: null
  },
  actions: {
    async getExceptionHandling({ commit, state }) {
      try {
        // Construct the request payload
        const requestPayload = {
          searchQuery: state.searchQuery,
          limit: state.limit,
          exceptionType: state.exceptionTypeFilter || [],
          offset: state.offset || 0
        }
        // Make a POST request and pass the payload in the request body
        const response = await Vue.prototype.$http.post(
          'dmp/exception-handling', // API endpoint
          requestPayload // Send data as the body of the request
        )

        // Handle the response
        if (response.data.status === 200 && response.data.data) {
          commit('SET_EXCEPTION_HANDLING', response.data.data)
        } else if (response.data.status === 204) {
          // Handle no content
          commit('SET_EXCEPTION_HANDLING', [])
          // TODO: Decide what action to take for status 204
        }

        // Common Pagination Set Logic
        const paginationData = {
          limit: response.data.limit || 10,
          offset: response.data.offset || 0,
          totalRows: parseInt(response.data.totalRows) || 0
        }
        commit('SET_EXCEPTIONAL_PAGINATION_DATA', paginationData)
      } catch (error) {
        // Handle any errors
        throw new Error(error)
      }
    },
    setLimitAndOffset({ commit }, { limit = 10, offset = 0 } = {}) {
      commit('SET_LIMIT_AND_OFFSET', { limit, offset })
    },
    resetPaginationData({ commit }) {
      commit('SET_LIMIT_AND_OFFSET', { limit: 10, offset: 0 })
    },
    updateSearchQuery({ commit }, searchQuery) {
      commit('SET_SEARCH_QUERY', searchQuery)
    },
    async getExceptionHandlingDuplicates({ commit }, sgaId) {
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/exception-handling/duplicates?sgaId=${sgaId}`
        )
        if (response.data.status === 200 && response.data.data) {
          commit('SET_EXCEPTION_HANDLING_DUPLICATES', response.data.data)
        } else {
          // If API data is null or the status is not 200, set an empty array
          commit('SET_EXCEPTION_HANDLING_DUPLICATES', [])
        }
      } catch (error) {
        // Handle any errors here
      }
    },
    async updateMasterEntity({ commit }, updateMasterPayload) {
      try {
        const response = await Vue.prototype.$http.put(
          '/dmp/exception-handling/master-selection',
          updateMasterPayload
        )
        return response.data.status === 200 && response.data.data > 0
      } catch (error) {
        throw new Error(error)
      }
    },
    async updateDuplicateEntity({ commit }, updateDuplicatePayload) {
      try {
        const response = await Vue.prototype.$http.put(
          '/dmp/exception-handling/duplicate-selection',
          updateDuplicatePayload
        )
        return response.data.status === 200 && response.data.data > 0
      } catch (error) {
        throw new Error(error)
      }
    },
    async processAllDuplicates({ commit }, duplicateBaseSGAID) {
      try {
        const response = await Vue.prototype.$http.put(
          `/dmp/exception-handling/duplicate-processed?duplicateBaseSGAID=${duplicateBaseSGAID}`
        )
        return response.data.status === 200 && response.data.data > 0
      } catch (error) {
        throw new Error(error)
      }
    },
    async resetProcessedEntity({ commit }, resetProcessedEntityPayload) {
      try {
        const response = await Vue.prototype.$http.put(
          '/dmp/exception-handling/clear-processed',
          resetProcessedEntityPayload
        )
        return response.data.status === 200 && response.data.data > 0
      } catch (error) {
        throw new Error(error)
      }
    },
    async updateInvalidEntity({ commit }, { updateInvalidPayload, sgaId }) {
      try {
        const response = await Vue.prototype.$http.put(
          `/dmp/exception-handling/update-invalid-entity?sgaId=${sgaId}`,
          updateInvalidPayload
        )
        return response.data.status === 200 && response.data.data > 0
      } catch (error) {
        throw new Error(error)
      }
    },
    updateExceptionTypeFilter({ commit }, exceptionTypeFilter) {
      commit('SET_EXCEPTIONTYPE_FILTER', exceptionTypeFilter)
    },
    updateExceptionalHandlingData({ commit }, exceptionHandlingData) {
      commit('SET_EXCEPTION_HANDLING', exceptionHandlingData)
    },
    async removeDuplicateEntity({ commit }, { notDupEntityIds, sgaId }) {
      try {
        const response = await Vue.prototype.$http.put(
          `/dmp/exception-handling/remove-duplicate-entity`,
          {
            duplicateBaseSGAID: sgaId,
            notDupEntityIds
          }
        )

        if (response.data && response.data.status === 200) {
          Snackbar({
            message: 'Entity removed successfully.',
            type: 'is-success'
          })
          return true
        } else {
          Snackbar({ message: 'Entity remove failed.', type: 'is-warning' })
          return false
        }
      } catch (error) {
        Snackbar({ message: 'Something went wrong', type: 'is-danger' })
        throw new Error(error)
      }
    },
    updatePaginationData({ commit }, paginationData) {
      commit('SET_EXCEPTIONAL_PAGINATION_DATA', paginationData)
    }
  },

  mutations: {
    SET_EXCEPTION_HANDLING(state, exceptionHandling) {
      state.exceptionHandlingList = exceptionHandling
    },
    SET_SEARCH_QUERY(state, searchQuery) {
      state.searchQuery = searchQuery
    },
    SET_EXCEPTIONTYPE_FILTER(state, exceptionTypeFilter) {
      state.exceptionTypeFilter = exceptionTypeFilter
    },
    SET_EXCEPTIONAL_PAGINATION_DATA(state, paginationData) {
      state.limit = paginationData.limit
      state.offset = paginationData.offset
      state.totalRecords = paginationData.totalRows
    },
    SET_LIMIT_AND_OFFSET(state, { limit = 10, offset = 0 } = {}) {
      state.limit = limit
      state.offset = offset
    },
    SET_EXCEPTION_HANDLING_DUPLICATES(state, exceptionHandling) {
      const exceptionHandlingWithSelected = exceptionHandling?.map(
        (item, index) => ({
          ...item,
          selected: false,
          isMaster: item?.masterEntity === item.entityId,
          isDuplicate: item?.isEntityProcessed === 'true'
        })
      )

      state.exceptionHandlingDuplicates = exceptionHandlingWithSelected
    }
  }
}
