var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-grid-container"},[(_vm.loading)?_c('div',{staticClass:"local-loader-container"},[_c('div',{staticClass:"inava-loader"})]):_vm._e(),_c('div',{staticClass:"grid-toolbar"},[_vm._m(0),_c('div',{staticClass:"grid-toolbar-filters"},[_c('span',{staticClass:"dropdown-container"},[_c('MultiSelectorVue',{attrs:{"label":"Work Type","placeholder":"","selected":_vm.selectedWorkTypes,"list":_vm.workTypeList},on:{"onChange":_vm.onChangeWorkTypeSelection}})],1),_c('span',{staticClass:"k-textbox k-grid-search k-display-flex"},[_c('k-input',{style:({ width: '430px' }),attrs:{"placeholder":'Search by Entity Name or SGA ID or Request ID or Ultimate Parent Name',"value":_vm.searchWord,"inputPrefix":'prefix'},on:{"input":_vm.filterGridData},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('span',{staticClass:"k-input-icon k-icon k-i-search"})]},proxy:true}])})],1)])]),_c('Grid',{ref:"grid",staticClass:"grid-data-table",style:('height: 100%;maxWidth: 100%;'),attrs:{"data-items":_vm.gridData,"take":_vm.take,"skip":_vm.skip,"sort":_vm.sort,"filter":_vm.filter,"total":_vm.total,"columns":_vm.columns,"sortable":true,"pageable":_vm.gridPageable,"resizable":true,"column-menu":true,"row-render":_vm.cellFunction,"filterOperators":_vm.filterOperators,"groupable":true,"group":_vm.group,"collapsed-groups":_vm.collapsedGroups,"expand-field":'expanded'},on:{"datastatechange":_vm.dataStateChange,"groupchange":_vm.onGroupChange,"expandchange":_vm.expandChange},scopedSlots:_vm._u([{key:"statusTemplate",fn:function({ props }){return [_c('custom',{attrs:{"column":props.column,"filterable":props.filterable,"filter":props.filter,"unique-data":false,"gridData":_vm.entityDataItem},on:{"filterchange":_vm.handleFilterChange,"closemenu":() => {
            props.onClosemenu()
          }}})]}},{key:"workTypeTemplate",fn:function({ props }){return [(props.dataItem.sgaId)?_c('td',{staticClass:"k-table-td worktype-column-td",attrs:{"role":"gridcell","colspan":"1"}},[(props.dataItem && props.dataItem.workType)?_c('div',{staticClass:"work-type-column-container"},_vm._l((props.dataItem.workType),function(workType,index){return _c('div',{key:index,class:['work-type-content', _vm.getWorkTypeClass(workType)],on:{"click":function($event){return _vm.goToEntities(
                props.dataItem.sgaId,
                props.dataItem.assignmentId,
                workType,
                props.dataItem.workType
              )}}},[_vm._v(" "+_vm._s(workType)+" ")])}),0):_vm._e()]):_vm._e()]}},{key:"tatTemplate",fn:function({ props }){return [(props.dataItem.sgaId)?_c('td',{staticClass:"k-table-td worktype-column-td",attrs:{"role":"gridcell","colspan":"1"}},[(props.dataItem && props.dataItem.TAT)?_c('div',{staticClass:"work-type-column-container"},[_vm._v(" "+_vm._s(_vm.tatDateFormat(props.dataItem.TAT))+" ")]):_vm._e()]):_vm._e()]}},{key:"legalEntityTemplate",fn:function({ props }){return [(props.dataItem.isUpdated)?_c('td',{staticClass:"k-table-td",attrs:{"role":"gridcell","colspan":"1"}},[_c('div',{staticClass:"legal-entity-container"},[_vm._v(" "+_vm._s(props.dataItem.legalEntityName)+" "),_c('b-icon',{attrs:{"icon":"flag","size":"is-small"}})],1)]):_c('td',{staticClass:"k-table-td",attrs:{"role":"gridcell","colspan":"1"}},[_c('div',{staticClass:"legal-entity-container"},[_vm._v(" "+_vm._s(props.dataItem.legalEntityName)+" ")])])]}},{key:"onHoldStatusTemplate",fn:function({ props }){return [_c('td',[_c('GridDropDownCell',{attrs:{"options":_vm.onHoldDropDownOptions,"disabled":_vm.isOnHoldDisabled(props.dataItem.status),"defaultSelect":_vm.getOnHoldStatus(props.dataItem.isOnHoldValue)},on:{"change":(selectedValue) =>
              _vm.onHoldStatusChange(selectedValue, props.dataItem)}})],1)]}}])}),[_c('ConfirmationDialog',{staticClass:"on-hold-dialog",attrs:{"visible":_vm.confirmationDialogData.visible,"title":_vm.confirmationDialogData.title,"bodyText":_vm.confirmationDialogData.text,"cancelButtonText":_vm.confirmationDialogData.cancelButtonText,"confirmButtonText":_vm.confirmationDialogData.confirmButtonText,"closeDialog":_vm.closeDialog,"triggerFunction":_vm.confirmationDialogData.triggerFunction}},[(_vm.isOnHoldDropdown)?_c('SearchableSelect',{staticClass:"form-input-field",attrs:{"value":_vm.onHoldReason,"label":"On Hold Reason","placeholder":'Select on hold reason',"data":_vm.onHoldReasons,"value_field":"value","text_field":"text","isMandatory":true,"mandatorySubmit":_vm.onHoldSubmit,"submitted":_vm.onHoldSubmit},on:{"onChangeName":_vm.handleOnHoldReason}}):_vm._e()],1)]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-toolbar-title"},[_c('p',[_vm._v("Assigned Entities")])])
}]

export { render, staticRenderFns }