import Snackbar from '@/components/Snackbar'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import Vue from 'vue'
import { PARSE_ADDRESS_FIELD_MAPPING } from '../../constant/data'
import store from '../index'

const getDefaultState = () => {
  return {
    isAddEntitiesEnabled: true,
    componentKey: 1,
    physicalAddress: {},
    registeredAddress: {},
    registeredAddressBVDField: {},
    physicalAddressBVDField: {},
    country: [],
    addressTypeDetails: [],
    countrySubDivision: [],
    physicalSubDivision: { text: '', value: '' },
    regSubDivision: { text: '', value: '' },
    isBVDEvidenceTrigger: false,
    shouldOverride: false,
    nuts3: [],
    entityDetails: {
      sgaId: null,
      assignmentId: null,
      workType: null
    },
    entityDetailsForTab: {
      sgaId: null,
      assignmentId: null,
      workType: null
    },
    stepperForm: null,
    postcodeRegex: [],
    isNuts3Loading: false
  }
}

const axiosCancelToken = {
  nuts3: null
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getEntityDetails: (state) => {
      return state.entityDetails
    },
    getStepperForm: (state) => {
      return state.stepperForm
    }
  },
  actions: {
    getAddressDetails({ commit }, sgaId) {
      // Reset store
      commit('SET_REGISTERED_ADDRESS', {})
      commit('SET_PHYSICAL_ADDRESS', {})
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/dmp/address?sgaId=${sgaId}`)
          .then((response) => {
            if (response.data.status === 200) {
              if (response.data && response.data.data) {
                commit('SET_REGISTERED_ADDRESS', response.data.data.registered)
                commit('SET_PHYSICAL_ADDRESS', response.data.data.domicile)
                resolve()
              }
            } else if (response.data.status === 204) {
              //  todo: action to be decided
              resolve()
            } else {
              // TODO: Async/Await the process
              resolve()
            }
            // commit('LOADING', false)
          })
          .catch((error) => {
            reject(error)
            // commit('LOADING', false)
          })
      })
    },

    async updateAddressDetails({ commit }, updateAddresses) {
      try {
        const { sgaId, addressDetails } = updateAddresses
        const response = await Vue.prototype.$http.put(
          `/dmp/address?sgaId=${sgaId}`,
          addressDetails
        )
        // Returned response for status of submission
        return response?.data?.status
      } catch (error) {
        throw new Error(error)
      }
    },

    getAddressType({ commit, state }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/dmp/address/addressType')
          .then((response) => {
            if (response.data.status === 200) {
              if (response.data && response.data.data) {
                commit('ADDRESS_TYPE', response.data.data)
                resolve()
              }
            }
            if (response.data.status === 204) {
              resolve()
              //  todo: action to be decided
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateComponentKey({ commit }, newKey) {
      commit('SET_COMPONENT_KEY', newKey)
    },
    updateEntitiesStatus({ commit }, { entityStatus, entityDetails }) {
      commit('SET_ENTITIES_STATUS', entityStatus)
      commit('SET_ENTITY_DETAILS', entityDetails)
    },

    updateRegisteredAddress({ commit }, formData) {
      commit('SET_REGISTERED_ADDRESS', formData)
    },
    updatePhysicalAddress({ commit }, formData) {
      commit('SET_PHYSICAL_ADDRESS', formData)
    },

    getCountryList({ commit, state }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/dmp/address/country')
          .then((response) => {
            if (response.data.status === 200) {
              if (response.data && response.data.data) {
                commit('SET_COUNTRY', response.data.data)
                resolve()
              }
            }
            if (response.data.status === 204) {
              resolve()

              //  todo: action to be decided
            }
            // commit('LOADING', false)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getStateList({ commit }, countryId) {
      return new Promise((resolve, reject) => {
        commit('SET_STATE', [])
        Vue.prototype.$http
          .get(`/dmp/address/state?countryId=${countryId}`)
          .then((response) => {
            if (response.data.status === 200) {
              if (response.data && response.data.data) {
                commit('SET_STATE', response.data.data)
              }
            }
            resolve(response)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    // Vuex action
    async copyFieldToAddress({ commit, state }, obj) {
      const { data, field, shouldOverride } = obj
      commit('SHOULD_OVERRIDE', shouldOverride)
      const bvdRegister = {}
      const bvdDomicile = {}

      // Concatenate address line 1, postcode, town name, country, country subdivision into full address
      const toConcatenateFields = [
        data.fullAddress,
        data.postCode,
        data.town,
        data.country?.text,
        data?.subDivision?.text
      ]

      // Concatenate address components in one iteration
      const concatenatedAddress = toConcatenateFields
        .filter(Boolean) // Remove undefined or empty values
        .join(', ')

      // Update full address field in data if necessary
      if (concatenatedAddress) {
        data.fullAddress = concatenatedAddress
      }

      for (const key in data) {
        // Check if the property exists in registeredAddress or physicalAddress
        if (
          (key in state.registeredAddress || key in state.physicalAddress) &&
          Object.prototype.hasOwnProperty.call(data, key)
        ) {
          if (field === 'registered' && key in state.registeredAddress) {
            bvdRegister[key] = data[key]
            commit('SET_REGISTERED_ADDRESS_FIELD', bvdRegister)
          } else if (field === 'physical' && key in state.physicalAddress) {
            bvdDomicile[key] = data[key]
            commit('SET_PHYSICAL_ADDRESS_FIELD', bvdDomicile)
          } else {
            if (key in state.registeredAddress) {
              bvdRegister[key] = data[key]
            }
            if (key in state.physicalAddress) {
              bvdDomicile[key] = data[key]
            }
            commit('SET_PHYSICAL_ADDRESS_FIELD', bvdDomicile)
            commit('SET_REGISTERED_ADDRESS_FIELD', bvdRegister)
          }
        }
      }
    },

    // Define other actions as needed
    // Print updated this.registerAddress

    async updateFullAddress({ commit }, entityFlagObj) {
      const { sgaId, fullAddress } = entityFlagObj
      const reqData = {
        sgaId: sgaId,
        address: fullAddress
      }

      try {
        const response = await Vue.prototype.$http.put(
          '/dmp/address/update-full-address',
          reqData
        )

        if (response?.data && response.data?.status === 200) {
          // return true if updated successfully
          return true
        }
      } catch (error) {
        throw new Error(error)
      }
    },


    async getNuts3List({ commit }, { postcode, countryId }) {
      try {
        commit('SET_NUTS3', [])
        commit('SET_NUTS3_LOADING', true)

        if (axiosCancelToken.nuts3) {
          commit('SET_NUTS3_LOADING', true)
          axiosCancelToken.nuts3('Cancelling previous request')
        }

        // Create a new cancel token
        const source = axios.CancelToken.source()
        axiosCancelToken.nuts3 = source.cancel

        const response = await Vue.prototype.$http.get(
          `/dmp/address/nuts3?postcode=${postcode}&countryId=${countryId}`,
          {
            cancelToken: source.token
          }
        )

        if (response && response.data) {
          if (response.data?.status === 200 && response.data.data) {
            commit('SET_NUTS3', response.data.data)
          }
        }
        commit('SET_NUTS3_LOADING', false)

        return response
      } catch (error) {
        if (!axios.isCancel(error)) {
          // throw new Error(error)
        } else {
          // Handle cancellation
        }
      }
    },

    async getPostcodeRegexList({ commit }) {
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/address/postcode-regex'
        )
        if (response?.data && response.data?.status === 200) {
          commit('SET_POSTCODE_REGEX', response.data.data)
          // return true if get successfully
          return true
        }
      } catch (error) {
        throw new Error(error)
      }
    },

    updateStateList({ commit }, stateList) {
      commit('SET_STATE', stateList)
    },

    updateNuts3List({ commit }, nuts3List) {
      commit('SET_NUTS3', nuts3List)
    },

    updateStepperForm({ commit }, stepperForm) {
      commit('SET_STEPPER_FORM', stepperForm)
    },

    resetAddressDetails({ commit }) {
      commit('SET_REGISTERED_ADDRESS', {})
      commit('SET_PHYSICAL_ADDRESS', {})
    },

    resetFormsDetails({ commit }) {
      commit('RESET_FORM_DETAILS')
    },
    async parseAddressDetails({ commit, state }, addressDetails) {
      const addressDetailsObj = {
        address: addressDetails
      }

      // Get required variables from env file
      const parseAddressUrl = process.env.VUE_APP_PARSE_ADDRESS_URL
      const clientKey = process.env.VUE_APP_PARSE_ADDRESS_CLIENT_KEY
      const clientSecret = process.env.VUE_APP_PARSE_ADDRESS_CLIENT_SECRET

      if (!parseAddressUrl || !clientKey || !clientSecret) {
        Snackbar({
          message:
            'Something went wrong with configuration, please contact your administrator.',
          type: 'is-danger'
        })
        return null
      }

      // Use axios to make the POST request, and avoid including unnecessary headers
      const response = await axios.post(parseAddressUrl, addressDetailsObj, {
        headers: {
          'Client-Key': clientKey, // Specify only necessary headers
          'Client-Secret': clientSecret
        }
      })

      const addressResponse = response.data
      const parsedAddress = addressResponse.parsed_address

      const fieldMapping = {
        [PARSE_ADDRESS_FIELD_MAPPING.COMPANY_NAME]: 'companyName',
        [PARSE_ADDRESS_FIELD_MAPPING.DEPARTMENT]: 'department',
        [PARSE_ADDRESS_FIELD_MAPPING.SUB_DEPARTMENT]: 'subDepartment',
        [PARSE_ADDRESS_FIELD_MAPPING.STREET_NAME]: 'streetName',
        [PARSE_ADDRESS_FIELD_MAPPING.BUILDING_NUMBER]: 'buildingNumber',
        [PARSE_ADDRESS_FIELD_MAPPING.BUILDING_NAME]: 'buildingName',
        [PARSE_ADDRESS_FIELD_MAPPING.FLOOR]: 'floor',
        [PARSE_ADDRESS_FIELD_MAPPING.POST_BOX]: 'postBox',
        [PARSE_ADDRESS_FIELD_MAPPING.ROOM]: 'room',
        [PARSE_ADDRESS_FIELD_MAPPING.POSTCODE]: 'postCode',
        [PARSE_ADDRESS_FIELD_MAPPING.COUNTRY]: 'country',
        [PARSE_ADDRESS_FIELD_MAPPING.TOWN_NAME]: 'town',
        [PARSE_ADDRESS_FIELD_MAPPING.TOWN_LOCATION_NAME]: 'townLocation',
        [PARSE_ADDRESS_FIELD_MAPPING.DISTRICT_NAME]: 'districtName',
        [PARSE_ADDRESS_FIELD_MAPPING.COUNTRY_SUB_DIVISION]: 'subDivision'
      }

      // We need to find the country based on the country value from the country list
      const countryList = state.country
      let countryObj = countryList.find(
        (country) =>
          country.text === parsedAddress[PARSE_ADDRESS_FIELD_MAPPING.COUNTRY]
      )

      // We need to find the country subdivision based on the country subdivision value from the country subdivision list
      const countrySubDivisionList = state.countrySubDivision
      const countrySubDivisionObj = countrySubDivisionList.find(
        (countrySubDivision) =>
          countrySubDivision.text ===
          parsedAddress[PARSE_ADDRESS_FIELD_MAPPING.COUNTRY_SUB_DIVISION]
      )

      // If the country is still not found, then we need to check if the parseAddress has country
      // If it has country, then we can probably split the country code and name as it's in the format 'US-United States' and then do a search on the country list based on country code
      if (!countryObj && parsedAddress[PARSE_ADDRESS_FIELD_MAPPING.COUNTRY]) {
        // We need to first check if '-' is present in the country field
        if (parsedAddress[PARSE_ADDRESS_FIELD_MAPPING.COUNTRY].includes('-')) {
          const countryCode =
            parsedAddress[PARSE_ADDRESS_FIELD_MAPPING.COUNTRY].split('-')[0]

          // find the country
          countryObj = countryList.find(
            (country) => country.countryCode === countryCode
          )
        }
      }

      // Map the fields from the response based on the field mapping if the key exists
      const mappedParsedAddress = Object.keys(parsedAddress).reduce(
        (acc, key) => {
          // In case of country and subdivision we need to replace it with the values we found above
          if (fieldMapping[key]) {
            const fieldValue = parsedAddress[key]
            acc[fieldMapping[key]] = {
              value: fieldValue,
              isDisabled: !fieldValue // Add isDisabled field
            }
            if (fieldMapping[key] === 'country') {
              acc[fieldMapping[key]].value = countryObj
              acc[fieldMapping[key]].isDisabled = !countryObj
            } else if (fieldMapping[key] === 'subDivision') {
              acc[fieldMapping[key]].value = countrySubDivisionObj
              acc[fieldMapping[key]].isDisabled = !countrySubDivisionObj
            }
          }
          return acc
        },
        {}
      )

      if (!isEmpty(mappedParsedAddress)) {
        return mappedParsedAddress
      }
      return null
    }
  },
  mutations: {
    SET_COMPONENT_KEY(state, newKey) {
      state.componentKey = newKey
    },
    SHOULD_OVERRIDE(state, shouldOverride) {
      state.shouldOverride = shouldOverride
    },
    SET_ENTITIES_STATUS(state, entityStatus) {
      state.isAddEntitiesEnabled = entityStatus
    },
    SET_REGISTERED_ADDRESS(state, formData) {
      state.registeredAddress = formData
    },
    SET_PHYSICAL_ADDRESS(state, formData) {
      state.physicalAddress = formData
    },
    SET_REGISTERED_ADDRESS_FIELD(state, data) {
      state.registeredAddressBVDField = data
    },
    SET_PHYSICAL_ADDRESS_FIELD(state, data) {
      state.physicalAddressBVDField = data
    },

    SET_COUNTRY(state, countryList) {
      state.country = countryList.map((countryObj) => {
        // Assign new key
        countryObj.value = countryObj.id
        countryObj.text = `${countryObj.countryCode}-${countryObj.name}`

        // Delete old key
        delete countryObj.id
        delete countryObj.name

        return countryObj
      })
    },
    ADDRESS_TYPE(state, addressTypeDetails) {
      state.addressTypeDetails = addressTypeDetails
    },

    SET_STATE(state, stateList) {
      state.countrySubDivision = stateList.map((stateObj) => {
        // Assign new key
        stateObj.value = stateObj.id
        stateObj.text = `${stateObj.subDivisionCode}-${stateObj.name}`
        stateObj.stateCode = stateObj.subDivisionCode.split('-')[1]

        // Delete old key
        delete stateObj.id
        delete stateObj.name

        return stateObj
      })
    },

    SET_NUTS3(state, nuts3List) {
      state.nuts3 = nuts3List.map((nuts3Obj) => {
        // Assign new key
        nuts3Obj.value = nuts3Obj.id
        nuts3Obj.text = `${nuts3Obj.id}-${nuts3Obj.name}`

        // Delete old key
        delete nuts3Obj.id
        delete nuts3Obj.name

        return nuts3Obj
      })
    },

    SET_NUTS3_LOADING(state, loading) {
      state.isNuts3Loading = loading
    },

    ENTITY_SUB_TYPE_VALUE(state, entitySubType) {
      state.entitySubType = entitySubType
    },

    SET_ENTITY_TYPE(state, entityTypeList) {
      state.entityType = entityTypeList.map((entityTypeObj) => {
        // Assign new key

        entityTypeObj.value = entityTypeObj.id
        entityTypeObj.text = `${entityTypeObj.id}-${entityTypeObj.name}`

        // Delete old key
        delete entityTypeObj.id
        delete entityTypeObj.name

        return entityTypeObj
      })
    },

    SET_ENTITY_SUBTYPE(state, entitySubTypeList) {
      state.entitySubType = entitySubTypeList.map((entitySubTypeObj) => {
        // Assign new key
        entitySubTypeObj.value = entitySubTypeObj.id
        entitySubTypeObj.text = `${entitySubTypeObj.id}-${entitySubTypeObj.name}`

        // Delete old key
        delete entitySubTypeObj.id
        delete entitySubTypeObj.name

        return entitySubTypeObj
      })
    },

    SET_ENTITY_DETAILS(state, { sgaId, assignmentId, workType, isCAWorkType }) {
      state.entityDetails.sgaId = sgaId || state.entityDetails.sgaId
      state.entityDetails.assignmentId =
        assignmentId || state.entityDetails.assignmentId
      state.entityDetails.workType = workType || state.entityDetails.workType
      state.entityDetails.isCAWorkType = isCAWorkType || false
    },

    SET_ENTITY_DETAILS_FOR_TAB(
      state,
      { sgaId, assignmentId, workType, isCAWorkType }
    ) {
      state.entityDetailsForTab.sgaId = sgaId || state.entityDetailsForTab.sgaId
      state.entityDetailsForTab.assignmentId =
        assignmentId || state.entityDetailsForTab.assignmentId
      state.entityDetailsForTab.workType =
        workType || state.entityDetailsForTab.workType
      state.entityDetailsForTab.isCAWorkType = isCAWorkType || false
    },

    SET_STEPPER_FORM(state, stepperForm) {
      state.stepperForm = stepperForm
    },

    RESET_FORM_DETAILS(state) {
      // Form - storeName
      // Basic Details - basicDetails
      store.state.basicDetails.basicDetails = {}

      // Address Details - dmp
      state.physicalAddress = {}
      state.registeredAddress = {}

      // Industry Details - industry
      store.state.industry.industryDetail = {}

      // Identifiers - identifiers
      store.state.identifiers.identifiersDetails = {}

      // AnaCredit Details - anaCredit
      store.state.anaCredit.anaCreditDetails = {}

      // 2052A Details - details2052a
      store.state.details2052a.details2052a = null

      // Relationships - relationship
      store.state.relationship.relationshipData = []
    },

    SET_POSTCODE_REGEX(state, postcodeRegex) {
      state.postcodeRegex = postcodeRegex
    }
  }
}
