<template>
  <div>
    <div v-if="loader" class="inava-loader dashboard-loader"></div>

    <div v-if="!loader">
      <div class="year-title">Summary</div>
      <div class="card-container">
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">Unique Companies</div></slot>
            <slot
              ><div class="card-value">
                {{ formatNumber(updatedCards.uniquecompanycount) }}
              </div></slot
            >
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">Unique URLs</div></slot>
            <slot
              ><div class="card-value">
                {{ formatNumber(updatedCards.uniqueurlcount) }}
              </div></slot
            >
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">Document Types</div></slot>
            <slot
              ><div class="card-value">
                {{ formatNumber(updatedCards.documenttypescount) }}
              </div></slot
            >
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">Country</div></slot>
            <slot
              ><div class="card-value">
                {{
                  updatedCards.countrycount
                    ? formatNumber(updatedCards.countrycount)
                    : 0
                }}
              </div></slot
            >
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">Sectors</div></slot>
            <slot
              ><div class="card-value">
                {{
                  updatedCards.sectorcount
                    ? formatNumber(updatedCards.sectorcount)
                    : 0
                }}
              </div></slot
            >
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">Sub Sectors</div></slot>
            <slot
              ><div class="card-value">
                {{
                  updatedCards.subsectors
                    ? formatNumber(updatedCards.subsectors)
                    : 0
                }}
              </div></slot
            >
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">Documents</div></slot>
            <slot
              ><div class="card-value">
                {{
                  selectedProjectList.length > 0
                    ? formatNumber(updatedCards.uniquedocumentscount)
                    : 0
                }}
              </div></slot
            >
          </div>
        </div>
      </div>
      <div class="year-title">Companies</div>
      <div class="card-container">
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">0 Document</div></slot>
            <slot
              ><div class="card-value">
                {{
                  selectedProjectList.length > 0
                    ? formatNumber(
                        companyDocumentcount
                          ? companyDocumentcount.document_count_0
                          : 0
                      )
                    : 0
                }}
              </div></slot
            >
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">1 Document</div></slot>
            <slot
              ><div class="card-value">
                {{
                  selectedProjectList.length > 0
                    ? formatNumber(
                        companyDocumentcount
                          ? companyDocumentcount.document_count_1
                          : 0
                      )
                    : 0
                }}
              </div></slot
            >
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">2 Documents</div></slot>
            <slot
              ><div class="card-value">
                {{
                  selectedProjectList.length > 0
                    ? formatNumber(
                        companyDocumentcount
                          ? companyDocumentcount.document_count_2
                          : 0
                      )
                    : 0
                }}
              </div></slot
            >
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">3 Documents</div></slot>
            <slot
              ><div class="card-value">
                {{
                  selectedProjectList.length > 0
                    ? formatNumber(
                        companyDocumentcount
                          ? companyDocumentcount.document_count_3
                          : 0
                      )
                    : 0
                }}
              </div></slot
            >
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">4 Documents</div></slot>
            <slot
              ><div class="card-value">
                {{
                  selectedProjectList.length > 0
                    ? formatNumber(
                        companyDocumentcount
                          ? companyDocumentcount.document_count_4
                          : 0
                      )
                    : 0
                }}
              </div></slot
            >
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">5-10 Documents</div></slot>
            <slot
              ><div class="card-value">
                {{
                  selectedProjectList.length > 0
                    ? formatNumber(
                        companyDocumentcount
                          ? companyDocumentcount.document_count_btw_5_10
                          : 0
                      )
                    : 0
                }}
              </div></slot
            >
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <slot><div class="card-label">> 10 Documents</div></slot>
            <slot
              ><div class="card-value">
                {{
                  selectedProjectList.length > 0
                    ? formatNumber(
                        companyDocumentcount
                          ? companyDocumentcount.document_count_10_or_more
                          : 0
                      )
                    : 0
                }}
              </div></slot
            >
          </div>
        </div>
      </div>

      <div class="document-container">
        <div class="card bar-chart-cards">
          <div class="chart-header">
            <slot><div class="chart-label">Document Volume</div></slot>
          </div>
          <div v-if="documentVolumeChartData && selectedProjectList.length > 0">
            <BarChart
              :data="documentVolumeChartData"
              :categories="barCategories"
            />
          </div>
          <div v-else>
            <div>
              <NoDataContainerVue />
            </div>
          </div>
        </div>
        <div class="card bar-chart-cards">
          <div class="chart-header">
            <slot
              ><div class="chart-label">
                Document Classification Accuracy
              </div></slot
            >
          </div>
          <div v-if="areaChartData && selectedProjectList.length > 0">
            <AreaChart
              :data="areaChartData"
              :categories="areaCategories"
              :width="550"
              :height="220"
            />
          </div>
          <div v-else>
            <NoDataContainerVue />
          </div>
        </div>
      </div>
      <div class="card tree-map-chart-card">
        <div class="chart-header">
          <slot><div class="chart-label">Document Distribution</div></slot>
        </div>

        <div
          v-if="
            treeChartData &&
            treeChartData.children &&
            treeChartData.children.length > 0 &&
            selectedProjectList.length > 0
          "
        >
          <TreeMap :data="treeChartData" :width="950" :height="400" />
        </div>
        <div v-else>
          <NoDataContainerVue class="mt-5" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import MultiSelectorVue from '@/components/PMT/MultiSelector.vue'
import BarChart from '../../components/PMT/BarChart.vue'
import LineChart from '../../components/PMT/LineChart.vue'
import TreeMap from '../../components/PMT/TreeMap.vue'
import AreaChart from '../../components/PMT/AreaChart.vue'
import Snackbar from '@/components/Snackbar'
import NoDataContainerVue from '../../components/Common/NoDataContainer/NoDataContainer.vue'
export default {
  components: {
    BarChart,
    LineChart,
    MultiSelectorVue,
    TreeMap,
    AreaChart,
    NoDataContainerVue
  },
  data() {
    return {
      treeChartData: {
        name: 'root',
        children: []
      },
      companyDocumentcount: {
        document_count_0: 0,
        document_count_1: 0,
        document_count_2: 0,
        document_count_3: 0,
        document_count_4: 0,
        document_count_btw_5_10: 0,
        document_count_10_or_more: 0
      },
      documentTypeList: [],
      selectedDocumentType: [],
      updatedCards: {},
      barCategories: [],
      documentVolumeChartData: [],
      areaCategories: ['Correct', 'Incorrect'],
      areaChartData: [],
      dashboardFilters: { date: null, projectName: [] },
      loader: true
    }
  },
  methods: {
    ...mapActions('pmtDashboard', [
      'getPmtDashboardCardData',
      'getBarChartData',
      'getTreeMapChartData',
      'getAccuracyChartData',
      'getCompanyDocumentData'
    ]),
    ...mapActions('pmtDocuments', ['getDocumentTypeList']),
    formatNumber(value) {
      // Convert the number to a string
      let numStr = value.toString()
      let lastThree = numStr.slice(-3)
      let otherNumbers = numStr.slice(0, -3)

      // Apply commas to the part of the number other than the last three digits
      if (otherNumbers !== '') {
        lastThree = ',' + lastThree
      }
      return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree
    },
    onChangeCountries(selectedDocumentType) {
      let params = selectedDocumentType.map((el) => el.id)
      this.selectedDocumentType = selectedDocumentType
      this.getTreeMapChartData({ type: params })
    },
    calculateDocumentCount(data) {
      const count = this.companyDocumentcount // Access directly from data
      data.forEach((company) => {
        const docCount = parseInt(company.document_count, 10)
        switch (true) {
          case docCount === 0:
            count.document_count_0++
            break
          case docCount === 1:
            count.document_count_1++
            break
          case docCount === 2:
            count.document_count_2++
            break
          case docCount === 3:
            count.document_count_3++
            break
          case docCount === 4:
            count.document_count_4++
            break
          case docCount >= 5 && docCount <= 10:
            count.document_count_btw_5_10++
            break
          case docCount > 10:
            count.document_count_10_or_more++
            break
        }
      })
      return count
    }
  },
  computed: {
    ...mapState('pmtDashboard', [
      'pmtDashboardCardData',
      'barChartData',
      'barChartCategories',
      'treeMapData',
      'accuracyChartData',
      'companyDocumentData'
    ]),
    ...mapState('filters', ['loading']),
    ...mapState('pmtDocuments', ['pmtDocumentTypeData']),
    ...mapState('pmtDashboardFilters', [
      'selectedProjectList',
      'activePmtDashboardTimePeriod'
    ])
  },
  watch: {
    pmtDashboardCardData(newCardData) {
      this.updatedCards = newCardData
    },
    companyDocumentData(newCompanyData) {
      if (newCompanyData && newCompanyData.length) {
        this.companyDocumentcount = this.calculateDocumentCount(newCompanyData)
      } else {
        this.companyDocumentcount = this.companyDocumentcount || {
          document_count_0: 0,
          document_count_1: 0,
          document_count_2: 0,
          document_count_3: 0,
          document_count_4: 0,
          document_count_btw_5_10: 0,
          document_count_10_or_more: 0
        }
      }
    },
    barChartData(newChartData) {
      this.documentVolumeChartData = newChartData
    },
    barChartCategories(newChartCategories) {
      this.barCategories = newChartCategories
    },
    treeMapData(newTreeMapData) {
      this.treeChartData.children = newTreeMapData
    },
    accuracyChartData(newAccuracyData) {
      this.areaChartData = newAccuracyData
    },
    pmtDocumentTypeData(newDocType) {
      let docTypeList = newDocType.map((el) => {
        return {
          id: el.id,
          name: el.type
        }
      })
      this.documentTypeList = docTypeList
      this.selectedDocumentType = docTypeList
    },
    async activePmtDashboardTimePeriod(newPeriodTime) {
      this.dashboardFilters.date = newPeriodTime
      this.loader = true
      try {
        await Promise.all([
          this.getPmtDashboardCardData(this.dashboardFilters),
          this.getBarChartData(this.dashboardFilters),
          this.getAccuracyChartData(this.dashboardFilters),
          this.getTreeMapChartData(this.dashboardFilters),
          this.getCompanyDocumentData(this.dashboardFilters)
        ]).then(() => {
          this.loader = false
        })
      } catch (error) {
        Snackbar({
          message: error,
          type: 'is-danger'
        })
      }
    },
    async selectedProjectList(newProjectList) {
      this.dashboardFilters.projectName = newProjectList
      this.loader = true
      try {
        await Promise.all([
          this.getPmtDashboardCardData(this.dashboardFilters),
          this.getBarChartData(this.dashboardFilters),
          this.getAccuracyChartData(this.dashboardFilters),
          this.getTreeMapChartData(this.dashboardFilters),
          this.getCompanyDocumentData(this.dashboardFilters)
        ]).then(() => {
          this.loader = false
        })
      } catch (error) {
        Snackbar({
          message: error,
          type: 'is-danger'
        })
      }
    }
  }
}
</script>

<style scoped>
.tree-map-chart-card {
  height: 400px !important;
  width: 100% !important;
  margin-top: 20px !important;
}
.bar-chart-cards {
  height: 270px !important;
  width: 100% !important;
  margin-top: 20px !important;
}
.dashboard-loader {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.local-loader-container {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  background: var(--primary);
  border-radius: 4px;
  z-index: 5;
}
.container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.card-container {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.card {
  flex: 1;
  height: 100px;
  width: 130px;
  border-radius: 0px;
  border: none;
  box-shadow: none;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  white-space: nowrap;
  margin-left: -14px;
}

.card-label {
  color: #888888;
  font-size: 13px;
}
.card-value {
  font-size: 25px;
  font-weight: bold;
}
.chart-header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.chart-label {
  color: #535eeb;
  font-size: 15px;
}
.no-record-found {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
}
.document-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin: 0 auto;
}
</style>
