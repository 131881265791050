import Vue from 'vue'
import store from '../index'
import { dateFormat } from '../../util/util'
import moment from 'moment'

const getDefaultState = () => {
  return {
    componentKey: 1,
    auditDetails: [],
    limit: 10,
    offset: 0,
    totalRecords: 0
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getAuditDetails({ commit, state }, sgaId) {
      try {
        // Construct the request payload
        const requestPayload = {
          limit: state.limit,
          offset: state.offset || 0,
          sgaId: sgaId
        }
        // Make a POST request and pass the payload in the request body
        const response = await Vue.prototype.$http.post(
          'dmp/audit', // API endpoint
          requestPayload // Send data as the body of the request
        )
        // Handle the response
        if (response.data.status === 200 && response.data.data) {
          commit('SET_AUDIT', response.data.data)
        } else if (response.data.status === 204) {
          // Handle no content
          commit('SET_AUDIT', [])
          // TODO: Decide what action to take for status 204
        }

        // Common Pagination Set Logic
        const paginationData = {
          limit: response.data.limit || 10,
          offset: response.data.offset || 0,
          totalRows: parseInt(response.data.totalRows) || 0
        }
        commit('SET_AUDIT_PAGINATION_DATA', paginationData)
      } catch (error) {
        // Handle any errors
        throw new Error(error)
      }
    },
    setLimitAndOffset({ commit }, { limit = 10, offset = 0 } = {}) {
      commit('SET_LIMIT_AND_OFFSET', { limit, offset })
    },
    resetPaginationData({ commit }) {
      commit('SET_LIMIT_AND_OFFSET', { limit: 10, offset: 0 })
    }
  },
  mutations: {
    SET_AUDIT(state, audit) {
      state.auditDetails = []

      audit.forEach((auditItem) => {
        const auditTime = moment.utc(auditItem.createdAt).local()

        const attributeName = auditItem.attributeName
        let oldValue = auditItem.oldValue
        let newValue = auditItem.newValue
        const changedBy = `${auditItem.firstName || ''} ${
          auditItem.lastName || ''
        }`
        const timeStamp = auditTime.format('ddd MMM DD YYYY HH:mm:ss')
        // Check and remove prefix if attributeName is "fullAddress"
        if (
          attributeName === 'DOM-Full Address' ||
          attributeName === 'REG-Full Address'
        ) {
          if (oldValue?.startsWith('##CLEAR##')) {
            oldValue = oldValue.slice('##CLEAR##'.length)
          }
          if (newValue?.startsWith('##CLEAR##')) {
            newValue = newValue.slice('##CLEAR##'.length)
          }
        }

        const auditData = {
          attributeName,
          oldValue,
          newValue,
          changedBy,
          timeStamp
        }

        state.auditDetails.push(auditData)
      })
    },
    SET_AUDIT_PAGINATION_DATA(state, paginationData) {
      state.limit = paginationData.limit
      state.offset = paginationData.offset
      state.totalRecords = paginationData.totalRows
    },
    SET_LIMIT_AND_OFFSET(state, { limit = 10, offset = 0 } = {}) {
      state.limit = limit
      state.offset = offset
    }
  }
}
