<template>
  <div class="data-grid-container">
    <div v-if="loading" class="local-loader-container">
      <div class="inava-loader"></div>
    </div>
    <div class="grid-toolbar">
      <div class="grid-toolbar-title">
        <p>Assigned Entities</p>
      </div>
      <div class="grid-toolbar-filters">
        <span class="dropdown-container">
          <MultiSelectorVue
            label="Work Type"
            placeholder=""
            :selected="selectedWorkTypes"
            :list="workTypeList"
            @onChange="onChangeWorkTypeSelection"
          />
        </span>
        <span class="k-textbox k-grid-search k-display-flex">
          <k-input
            :style="{ width: '430px' }"
            :placeholder="'Search by Entity Name or SGA ID or Request ID or Ultimate Parent Name'"
            :value="searchWord"
            :inputPrefix="'prefix'"
            @input="filterGridData"
          >
            <template v-slot:prefix>
              <span class="k-input-icon k-icon k-i-search"></span>
            </template>
          </k-input>
        </span>
      </div>
    </div>
    <Grid
      ref="grid"
      :style="'height: 100%;maxWidth: 100%;'"
      class="grid-data-table"
      :data-items="gridData"
      :take="take"
      :skip="skip"
      :sort="sort"
      :filter="filter"
      :total="total"
      @datastatechange="dataStateChange"
      :columns="columns"
      :sortable="true"
      :pageable="gridPageable"
      :resizable="true"
      :column-menu="true"
      :row-render="cellFunction"
      :filterOperators="filterOperators"
      :groupable="true"
      :group="group"
      :collapsed-groups="collapsedGroups"
      @groupchange="onGroupChange"
      @expandchange="expandChange"
      :expand-field="'expanded'"
    >
      <template v-slot:statusTemplate="{ props }">
        <custom
          :column="props.column"
          :filterable="props.filterable"
          :filter="props.filter"
          :unique-data="false"
          :gridData="entityDataItem"
          @filterchange="handleFilterChange"
          @closemenu="
            () => {
              props.onClosemenu()
            }
          "
        />
      </template>
      <template v-slot:workTypeTemplate="{ props }">
        <td
          v-if="props.dataItem.sgaId"
          class="k-table-td worktype-column-td"
          role="gridcell"
          colspan="1"
        >
          <div
            class="work-type-column-container"
            v-if="props.dataItem && props.dataItem.workType"
          >
            <div
              v-for="(workType, index) in props.dataItem.workType"
              :key="index"
              @click="
                goToEntities(
                  props.dataItem.sgaId,
                  props.dataItem.assignmentId,
                  workType,
                  props.dataItem.workType
                )
              "
              :class="['work-type-content', getWorkTypeClass(workType)]"
            >
              {{ workType }}
            </div>
          </div>
        </td>
      </template>

      <template v-slot:tatTemplate="{ props }">
        <td
          v-if="props.dataItem.sgaId"
          class="k-table-td worktype-column-td"
          role="gridcell"
          colspan="1"
        >
          <div
            class="work-type-column-container"
            v-if="props.dataItem && props.dataItem.TAT"
          >
            {{ tatDateFormat(props.dataItem.TAT) }}
          </div>
        </td>
      </template>

      <template v-slot:legalEntityTemplate="{ props }">
        <td
          v-if="props.dataItem.isUpdated"
          class="k-table-td"
          role="gridcell"
          colspan="1"
        >
          <div class="legal-entity-container">
            {{ props.dataItem.legalEntityName }}
            <b-icon icon="flag" size="is-small"></b-icon>
          </div>
        </td>
        <td v-else class="k-table-td" role="gridcell" colspan="1">
          <div class="legal-entity-container">
            {{ props.dataItem.legalEntityName }}
          </div>
        </td>
      </template>

      <template v-slot:onHoldStatusTemplate="{ props }">
        <!-- On Hold Status DropDown -->
        <td>
          <GridDropDownCell
            :options="onHoldDropDownOptions"
            :disabled="isOnHoldDisabled(props.dataItem.status)"
            @change="
              (selectedValue) =>
                onHoldStatusChange(selectedValue, props.dataItem)
            "
            :defaultSelect="getOnHoldStatus(props.dataItem.isOnHoldValue)"
          >
          </GridDropDownCell>
        </td>
      </template>
    </Grid>
    <template>
      <ConfirmationDialog
        class="on-hold-dialog"
        :visible="confirmationDialogData.visible"
        :title="confirmationDialogData.title"
        :bodyText="confirmationDialogData.text"
        :cancelButtonText="confirmationDialogData.cancelButtonText"
        :confirmButtonText="confirmationDialogData.confirmButtonText"
        :closeDialog="closeDialog"
        :triggerFunction="confirmationDialogData.triggerFunction"
      >
        <!--  -->
        <SearchableSelect
          v-if="isOnHoldDropdown"
          :value="onHoldReason"
          label="On Hold Reason"
          :placeholder="'Select on hold reason'"
          :data="onHoldReasons"
          value_field="value"
          text_field="text"
          @onChangeName="handleOnHoldReason"
          class="form-input-field"
          :isMandatory="true"
          :mandatorySubmit="onHoldSubmit"
          :submitted="onHoldSubmit"
        />
      </ConfirmationDialog>
    </template>
  </div>
</template>

<script>
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import DMPGridDropDown from '@/components/DMP/DMPGridDropDown.vue'
import MultiSelectorVue from '@/components/Dropdowns/MultiSelector.vue'
import Snackbar from '@/components/Snackbar'
import { entityStatus, workTypeEntities } from '@/constant/data.js'
import { userRoles } from '@/util/permissions.utils'
import { process } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'
import { Input } from '@progress/kendo-vue-inputs'
import { mapActions, mapState } from 'vuex'
import {
  assignedEntitiesColumns,
  filterOperators,
  onHoldDropDownOptions
} from './assignedEntitiesData'
import ColumnMenu from './columnMenu.vue'
import SearchableSelect from '@/components/DMP/SearchableSelect'

export default {
  name: 'WorkFlowManagement',
  components: {
    Grid,
    'k-input': Input,
    MultiSelectorVue,
    custom: ColumnMenu,
    GridDropDownCell: DMPGridDropDown,
    ConfirmationDialog,
    SearchableSelect
  },
  data: function () {
    return {
      loading: this.isLoading,
      selectedWorkTypes: [],
      searchWord: '',
      expandField: 'expanded',
      gridPageable: {
        buttonCount: 6,
        info: true,
        type: 'numeric',
        pageSizes: [10, 50, 100, 200],
        previousNext: true
      },
      gridData: [],
      skip: 0,
      take: 10,
      total: 0,
      sort: [{ field: 'TAT', dir: 'desc' }],
      filter: null,
      customFilter: null,
      expandedItems: [],
      entityDataItem: [],
      userRoles,
      group: [],
      collapsedGroups: [],
      columns: assignedEntitiesColumns,
      filterOperators,
      workTypeEntities,
      entityStatus,
      onHoldDropDownOptions,
      onHoldReason: null,
      onHoldSubmit: false,
      isClose: true,
      isOnHoldDropdown: true,
      confirmationDialogData: {
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      }
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('workflow', [
      'asignedEntityDetails',
      'workType',
      'isLoading',
      'onHoldReasons'
    ]),

    workTypeList: {
      get() {
        return this.workType
      },
      set(workType) {
        return workType
      }
    }
  },

  watch: {
    workTypeList() {
      this.selectedWorkTypes = this.workTypeList
    },
    asignedEntityDetails() {
      this.onChangeWorkTypeSelection(this.selectedWorkTypes)
    },
    isLoading() {
      this.loading = this.isLoading
    }
  },
  async mounted() {
    await this.getWorkType()
    await this.getOnHoldReasons()
    await this.getAssignedEntityDetails(this.userDetails.userId)
    await this.updateRoleBasedFilter()
  },
  methods: {
    ...mapActions('dmp', ['updateEntitiesStatus']),
    ...mapActions('workflow', [
      'getAssignedEntityDetails',
      'getWorkType',
      'getOnHoldReasons',
      'updateOnHoldStatus'
    ]),

    onColumnsSubmit(columnsState) {
      this.columns = columnsState
    },
    updateRoleBasedFilter() {
      if (this.userRoles.ANALYST_CHECKER === this.userDetails.roleName) {
        this.filter = {
          logic: 'and',
          filters: [
            {
              filters: [
                { field: 'status', operator: 'contains', value: 'Review' }
              ],
              logic: 'or'
            }
          ]
        }
      } else {
        this.filter = {
          logic: 'and',
          filters: [
            {
              filters: [
                { field: 'status', operator: 'contains', value: 'Todo' },
                { field: 'status', operator: 'contains', value: 'In Progress' }
              ],
              logic: 'or'
            }
          ]
        }
      }
    },

    async goToEntities(sgaId, assignmentId, workType, workTypes) {
      let isCAWorkType = false
      if (workType === this.workTypeEntities.CORPORATE_ACTION) {
        isCAWorkType = true
        workType = this.getPriorityWorkTypeForCA(workTypes)
      }
      await this.updateEntitiesStatus({
        entityStatus: false,
        entityDetails: { sgaId, assignmentId, workType, isCAWorkType }
      })
      await this.$router.push({ name: 'entities' })
    },

    tatDateFormat(initialDate) {
      return initialDate.format('ddd MMM DD YYYY HH:mm:ss')
    },

    handleOnHoldReason(onHoldReason) {
      this.onHoldReason = onHoldReason
    },

    onChangeWorkTypeSelection(value) {
      this.selectedWorkTypes = value
      this.entityDataItem = []
      this.entityDataItem = this.asignedEntityDetails.filter((item) => {
        // Check if any workType id from selectedWorkTypes is present in the workType array of each object
        return (
          item.workType &&
          Array.isArray(item.workType) &&
          item.workType.some((entityWorkType) =>
            this.selectedWorkTypes.some(
              (newWorkType) => newWorkType.id === entityWorkType
            )
          )
        )
      })
      this.skip = 0
      this.updateGridData()
    },

    filterGridData(event) {
      this.searchWord = event.value
      this.customFilter = this.constructFilter(this.searchWord.toLowerCase(), [
        'sgaId',
        'requestId',
        'legalEntityName',
        'ultimateParentName'
      ])
      this.skip = 0
      this.updateGridData(true)
    },

    handleFilterChange: function (filter) {
      this.filter = filter
      this.skip = 0
      this.updateGridData()
    },

    updateGridData() {
      let filteredData = this.entityDataItem
      if (this.customFilter) {
        // Filter your dataItems based on the custom filter before sending them to the grid.
        filteredData = this.entityDataItem.filter((item) => {
          return this.customFilter.filters.some((filter) => {
            if (item[filter.field]) {
              return item[filter.field]
                .toString()
                .toLowerCase()
                .includes(filter.value)
            }
            return null
          })
        })
      }

      const processedData = this.processGridData(filteredData)
      this.gridData = processedData.data
      this.total = processedData.total
    },

    processGridData(data) {
      return process(data, {
        take: this.take,
        skip: this.skip,
        sort: this.sort,
        group: this.group,
        filter: this.filter
      })
    },

    constructFilter(inputValue, filterFields) {
      const filters = []
      const isValueArray = Array.isArray(inputValue)
      const isFieldArray = Array.isArray(filterFields)

      const addFilters = (field, value) => {
        filters.push({
          field: field,
          operator: 'contains',
          value: value,
          ignoreCase: true
        })
      }

      if (isValueArray) {
        inputValue.forEach((value) => {
          if (isFieldArray) {
            filterFields.forEach((field) => addFilters(field, value))
          } else {
            addFilters(filterFields, value)
          }
        })
      } else {
        if (isFieldArray) {
          filterFields.forEach((field) => addFilters(field, inputValue))
        } else {
          addFilters(filterFields, inputValue)
        }
      }
      return {
        logic: 'or',
        filters: filters
      }
    },
    expandChange: function (event) {
      this.collapsedGroups = event.collapsedGroups
      if (event.dataItem) {
        event.dataItem[event.target.$props.expandField] = event.value
      }
    },
    formatCamelCaseToTitle(camelCaseString) {
      // Insert a space before each uppercase letter, except for the first character
      const spacedString = camelCaseString.replace(/([A-Z])/g, ' $1')

      // Capitalize the first letter of the resulting string
      const capitalizedString =
        spacedString.charAt(0).toUpperCase() + spacedString.slice(1)

      return capitalizedString
    },
    onGroupChange: function (event) {
      // Determine if we're adding or removing a group by comparing lengths
      const isAddingGroup = event.group.length > this.group.length

      if (isAddingGroup) {
        // Extract the field name of the new grouping request
        const newGroupField = event.group[event.group.length - 1]?.field

        // Check if this field is already used in existing groups
        const isFieldAlreadyGrouped = this.group.some(
          (group) => group.field === newGroupField
        )

        if (isFieldAlreadyGrouped) {
          const formattedFieldName = this.formatCamelCaseToTitle(newGroupField) // Format the field name
          // If the field is already grouped, show a warning and don't update the groupings
          Snackbar({
            message: `Column '${formattedFieldName}' is already grouped.`,
            type: 'is-warning'
          })
          return // Stop further processing
        }
      }

      // Update groupings for both adding a new group and removing an existing one
      this.group = event.group
      this.updateGridData()
    },
    dataStateChange(event) {
      this.filter = event.data.filter
      this.take = event.data.take
      this.skip = event.data.skip
      this.sort = event.data.sort
      this.group = event.data.group

      if (this.selectedWorkTypes && this.selectedWorkTypes.length === 0) {
        this.gridData = []
      } else {
        this.updateGridData()
      }
    },

    // RENDERING UTILITIES
    getWorkTypeClass(workType) {
      switch (workType) {
        case 'Corporate Action':
          return 'work-type-corporate-action'
        case 'Data Enrichment':
          return 'work-type-data-enrichment'
        case 'New Onboarding':
          return 'work-type-new-onboarding'
        case 'Periodic Review':
          return 'work-type-periodic-review'
        default:
          return ''
      }
    },
    getRowClass(dataItem) {
      if (
        dataItem.workType &&
        dataItem.workType.includes('Corporate Action') &&
        dataItem.workType.length === 1
      ) {
        return 'highlight-row'
      }
      return ''
    },
    cellFunction(h, trElement, defaultSlots, props) {
      const rowClass = this.getRowClass(props.dataItem)
      const existingClass = trElement.data && trElement.data.class
      const combinedClass = `${existingClass} ${rowClass}`

      const trProps = {
        class: combinedClass,
        style: {}
      }

      if (props.dataItem.priority === 'high') {
        const greenStyle = {
          backgroundColor: 'rgb(245,119,119,0.6)'
        }
        Object.assign(trProps, { style: greenStyle })
      }

      return h('tr', trProps, defaultSlots)
    },
    getPriorityWorkTypeForCA(workTypes) {
      const workTypeOrder = [
        this.workTypeEntities.NEW_ONBOARDING,
        this.workTypeEntities.DATA_ENRICHEMENT,
        this.workTypeEntities.PERIODIC_REVIEW
      ]
      let redirectionWorktype = null
      for (let index = 0; index < workTypeOrder.length; index++) {
        if (workTypes && workTypes.includes(workTypeOrder[index])) {
          redirectionWorktype = workTypeOrder[index]
          break
        }
      }
      return redirectionWorktype || this.workTypeEntities.CORPORATE_ACTION
    },

    getOnHoldStatus(isOnHoldValue) {
      return this.onHoldDropDownOptions.find(
        (option) => option.value === isOnHoldValue
      )
    },

    isOnHoldDisabled(entityStatus) {
      return [this.entityStatus.SUBMIT, this.entityStatus.STAGING].includes(
        entityStatus
      )
    },

    onHoldStatusChange(selectedValue, dataItem) {
      const { sgaId, legalEntityName, requestId } = dataItem
      // Get the previous status
      const originStatus = this.onHoldDropDownOptions.find(
        (option) => option.value !== selectedValue?.value
      )

      // common pop up info
      this.confirmationDialogData.visible = true
      this.confirmationDialogData.title = 'On Hold Confirmation'
      this.confirmationDialogData.text = `Are you sure you want to change On-Hold status from <b>${originStatus?.text} to ${selectedValue?.text}</b>? <br/> <br/>
                              SGA ID: ${sgaId}  <br/> Legal Entity Name: ${legalEntityName}</br> Request Id: ${requestId}`

      if (selectedValue.value === this.onHoldDropDownOptions[0].value) {
        this.isOnHoldDropdown = true
        this.confirmationDialogData.triggerFunction = async () => {
          // Update case for false
          this.onHoldSubmit = true
          this.isClose = false
          if (this.onHoldReason !== null) {
            this.isClose = true // fix: pop up not closing after confirm

            await this.updateOnHoldStatus({
              sgaId: [dataItem.sgaId],
              isOnHold: selectedValue?.value,
              onHoldReasonId: this.onHoldReason.value
            })

            await this.getAssignedEntityDetails(this.userDetails.userId)

            this.onHoldReason = null
          }
        }
      } else {
        this.isOnHoldDropdown = false
        this.confirmationDialogData.triggerFunction = async () => {
          await this.updateOnHoldStatus({
            sgaId: [dataItem.sgaId],
            isOnHold: selectedValue?.value,
            onHoldReasonId: null
          })
          await this.getAssignedEntityDetails(this.userDetails.userId)
        }
      }
    },
    closeDialog() {
      if (this.isClose) {
        this.confirmationDialogData.visible = false
        this.onHoldReason = null
      } else {
        this.confirmationDialogData.visible = true
        this.isClose = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.data-grid-container {
  margin-left: 27px;
  margin-right: 27px;
  display: flex;
  flex-direction: column;
  height: 100%;

  local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    background: rgba(0, 0, 0, 0.4);
    z-index: 9;
    .inava-loader {
      position: relative;
      display: flex;
      margin: auto;
      border-radius: 50%;
      border: none;
      z-index: 900;
      width: 70px;
      height: 70px;
      background-image: '@/assets/Inava-logo-GIF.gif';
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  ::v-deep .k-grid td,
  .k-grid .k-table-td {
    border-block-end-width: 1px;
  }
  ::v-deep .mdi-flag::before {
    content: '\F023B';
    font-size: 25px;
  }

  ::v-deep .k-grid-md td,
  .k-grid-md .k-table-td {
    padding: 5px 12px;
  }

  .grid-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 26px;
    padding-bottom: 26px;
    border: none;

    .grid-toolbar-title {
      p {
        text-align: left;
        font-weight: 600;
        font-style: normal;
        font-size: 28px;
        line-height: 15px;
        font-family: Quicksand;
        letter-spacing: 0px;
        color: #00218a;
        opacity: 1;
        line-height: 1.5rem;
      }
    }

    .grid-toolbar-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .dropdown-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.6rem;
        width: 100%;

        ::v-deep .data-source-container {
          display: flex;
          align-items: center;

          .top-label {
            font-size: 14px;
            line-height: 20px;
            font-family: Quicksand;
            font-weight: 600;
            font-style: normal;
            color: #7d7d7d;
            margin-right: 0.6rem;
            margin-top: 0.3rem;
          }
          .dropdown-wrap {
            // height: 40px;
            .data-source-header {
              background-color: #ffffff;
              border: 1px solid #cccccc;

              // color: black;
              .button-container {
                .ellipsis {
                  color: black;
                  font-size: 14px;
                  line-height: 20px;
                  font-family: Quicksand;
                  font-weight: 600;
                  font-style: normal;
                }
                svg path {
                  stroke-width: 1;
                  stroke: black;
                }
              }
            }

            .data-source-abs {
              background: #ffffff 0% 0% no-repeat padding-box;
              color: #000000;

              .custom-checkbox {
                p {
                  color: #000000;
                }
              }

              .custom-checkbox:hover {
                background: rgba(196, 200, 248, 0.3) 0% 0% no-repeat padding-box;
              }
            }
          }
        }
      }

      ::v-deep .k-grid-search {
        height: 36px;
        width: 100%;
        .k-input {
          border: 1px solid #cccccc;

          input {
            // height: 44px;
            font-size: 13px;
            line-height: 20px;
            font-weight: 600;
            font-family: Quicksand;
            font-style: normal;
          }

          .k-input-inner {
            margin-left: 0.1rem;
          }

          .k-input-prefix {
            margin-left: 0.5rem;
            color: #cccccc;
          }
        }
      }
    }
  }

  .legal-entity-container {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    align-items: center;
    flex-direction: row;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .work-type-column-container {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    align-items: center;
    flex-direction: row;
    padding-top: 4px;
    padding-bottom: 4px;

    .work-type-content {
      border: 1px solid #444444;
      border-radius: 4px;
      opacity: 1;
      width: 140px;
      height: 30px;

      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      font-family: Quicksand;
      letter-spacing: 0.16px;
      color: #444444;
      opacity: 1;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .work-type-corporate-action {
      color: #e10d0d;
      border: 1px solid #e10d0d;
    }
    .work-type-data-enrichment {
      color: #00218a;
      border: 1px solid #00218a;
    }
    .work-type-new-onboarding {
      color: #03b62a;
      border: 1px solid #03b62a;
    }

    .work-type-periodic-review {
      border: 1px solid #00218a;
      color: #00218a;
    }
  }

  .on-hold-dialog {
    ::v-deep .k-dialog-wrapper .k-dialog {
      width: 30%;
    }

    ::v-deep .k-window-content {
      overflow: unset;

      .popup-msg {
        text-align: left;
        margin-left: 0;
      }
    }
  }
}

::v-deep .k-alt {
  background-color: none;
}

::v-deep .highlight-row {
  background-color: rgb(245, 245, 245);
}

::v-deep .k-column-title {
  font-size: 14px;
  font-family: Quicksand;
  color: #000000;
  letter-spacing: 0.18px;
}

::v-deep .k-table-td {
  font-size: 14px;
  letter-spacing: 0.16px;
  color: #000000;
}

::v-deep .k-table-thead {
  background: #dfdfdf 0% 0% no-repeat padding-box;
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .data-grid-container {
    .grid-toolbar {
      .grid-toolbar-title {
        p {
          font-size: 31px;
          line-height: 23px;
        }
      }

      .grid-toolbar-filters {
        .dropdown-container {
          ::v-deep .data-source-container {
            .top-label {
              font-size: 16px;
            }

            .dropdown-wrap {
              .data-source-header {
                .button-container {
                  .ellipsis {
                    font-size: 16px;
                  }
                }
              }

              .data-source-abs {
                .custom-checkbox {
                  font-size: 16px;
                }
              }
            }
          }
        }

        ::v-deep .k-grid-search {
          .k-input {
            input {
              font-size: 14px;
            }
          }
        }
      }
    }

    .work-type-column-container {
      .work-type-content {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  ::v-deep .k-column-title {
    font-size: 16px;
    font-family: Quicksand;
    color: #000000;
    letter-spacing: 0.18px;
  }

  ::v-deep .k-table-td {
    font-size: 15px;
    letter-spacing: 0.16px;
    color: #000000;
  }
}

@media (min-width: 1700px) {
  .data-grid-container {
    .grid-toolbar {
      .grid-toolbar-title {
        p {
          font-size: 36px;
          line-height: 23px;
        }
      }

      .grid-toolbar-filters {
        .dropdown-container {
          ::v-deep .data-source-container {
            .top-label {
              font-size: 18px;
            }

            .dropdown-wrap {
              .data-source-header {
                .button-container {
                  .ellipsis {
                    font-size: 18px;
                  }
                }
              }

              .data-source-abs {
                .custom-checkbox {
                  font-size: 18px;
                }
              }
            }
          }
        }

        ::v-deep .k-grid-search {
          .k-input {
            input {
              font-size: 16px;
            }
          }
        }
      }
    }

    .work-type-column-container {
      .work-type-content {
        font-size: 16px;
        line-height: 20px;
        font-family: Quicksand;
      }
    }
  }

  ::v-deep .k-column-title {
    font-size: 18px;
    font-family: Quicksand;
    color: #000000;
    letter-spacing: 0.18px;
  }

  ::v-deep .k-table-td {
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #000000;
  }
}
</style>
