<template>
  <div class="address-details-section">
    <div class="local-loader-container" v-if="loading">
      <div class="inava-loader"></div>
    </div>
    <h2 class="section-title">Address Details</h2>
    <div class="mandatory-note">
      <p>
        Physical Address is mandatory. Text boxes marked as
        <span class="mandatory">*</span> are mandatory.
      </p>
    </div>
    <div>
      <div class="address-type">
        <div class="address-title">
          <p>Physical/Business Address <span class="mandatory">*</span></p>
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="getEvidenceFormStatus(domicilAddrEvd.evdKey)"
              @click.native="toggleEvidenceForm(domicilAddrEvd.evdKey)"
              :btnLoading="getBtnLoadingStatus(domicilAddrEvd.evdKey)"
              :isDisabled="isEvidenceBtnDisabled(domicilAddrEvd.formLabel)"
            />
          </div>
        </div>
      </div>
      <div class="required-error" v-if="domicilAddrEvd.errorMessage">
        Please upload evidence for Physical/Business Address
      </div>
      <div class="address-form-component-container">
        <address-form-component
          :label="addressType.physical"
          :setAddress="physical"
          :departmentReadonly="isDepartment"
          :subDepartmentReadOnly="isSubDepartment"
          :submitted="submitted"
          :countrySubDivision="phySubDivision"
          :nuts3Data="phyNuts3Code"
          @getSubDivision="getPhySubDivision"
          @getNuts3List="getNuts3ByListForPhyAddr"
          @updatePhysicalAddress="updatePhysicalAddress"
          :disableValidation="loading"
          :countryList="country"
          :isLoadingNuts3Field="isLoadingPhyNuts3Field"
          :countryRegex="getRegexForCountry"
          :isMandatorySubmit="isMandatorySubmit"
          @resetCountryRelatedFields="resetCountryRelatedFields"
          :isEmitCountryChange="isEmitCountryChange"
        />
        <div class="parse-address-button">
          <b-button
            @click="handleParseAddress('physicalAddress')"
            class="parse-button"
            size="is-small"
            :disabled="!physical.fullAddress"
            >Parse Address</b-button
          >
        </div>
      </div>
    </div>
    <div class="collpase-window">
      <div
        class="address-type"
        :class="{ 'collapsed-title': isOpenBusinessAddress }"
      >
        <div class="address-title">
          <p>Registered Address</p>
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(registeredAddrEvd.evdKey)
              "
              @click.native="toggleEvidenceForm(registeredAddrEvd.evdKey)"
              :btnLoading="getBtnLoadingStatus(registeredAddrEvd.evdKey)"
              :isDisabled="isEvidenceBtnDisabled(registeredAddrEvd.formLabel)"
            />
          </div>
        </div>
      </div>
      <div class="button-component">
        <button class="collapse-button" @click="toggleRegisteredAddress()">
          <span v-if="isOpenRegistredAddress">
            <p>Collapse</p>
            <span class="k-icon k-i-arrow-60-up icon-size"></span>
          </span>
          <span v-else>
            <p>Expand</p>
            <span class="k-icon k-i-arrow-60-down icon-size"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="required-error" v-if="registeredAddrEvd.errorMessage">
      Please upload evidence for Registered Address
    </div>
    <section v-if="isOpenRegistredAddress" class="section address-details-">
      <div class="address-form-component-container">
        <div class="card-content">
          <div class="content">
            <div class="checkbox-container">
              <label for="checkbox1" class="checkAddress">
                <input
                  type="checkbox"
                  id="checkbox1"
                  name="checked"
                  value="yes"
                  class="checkbox"
                  @change="copyAddressField"
                  v-model="copyFields"
                />
                <p>Same as physical address</p>
              </label>
            </div>
            <address-form-component
              :label="addressType.register"
              :setAddress="register"
              :submitted="submitted"
              :countryList="country"
              :countrySubDivision="regSubDivision"
              :nuts3Data="regNuts3Code"
              @updateRegisterAddress="updateRegisterAddress"
              @getNuts3List="getNuts3ByListForRegAddr"
              @getSubDivision="getRegsubDivision"
              :disableValidation="loading"
              :countryRegex="getRegexForCountry"
              :isMandatorySubmit="isMandatorySubmit"
              :isLoadingNuts3Field="isLoadingRegNuts3Field"
              @resetCountryRelatedFields="resetCountryRelatedFields"
              :isEmitCountryChange="isEmitCountryChange"
            />
          </div>
        </div>
        <div class="parse-address-button-registered">
          <b-button
            @click="handleParseAddress('registeredAddress')"
            size="is-small"
            :disabled="!register.fullAddress"
            >Parse Address</b-button
          >
        </div>
      </div>
    </section>
    <ConfirmationDialog
      :visible="dialog.visible"
      :title="dialog.title"
      :bodyText="dialog.text"
      :cancelButtonText="dialog.cancelButtonText"
      :confirmButtonText="dialog.confirmButtonText"
      :closeDialog="dialog.closeDialog"
      :triggerFunction="dialog.triggerFunction"
    />

    <ParseAddressPopup
      v-if="parsedAddressDetails"
      :isVisible="showParsedAddressPopup"
      :closeDialog="closeParsedAddressPopup"
      :parsedAddressDetails="parsedAddressDetails"
      @confirmParsedAddress="handleParsedAddressConfirmation"
    />

    <template>
      <ActionButton
        @SaveData="saveData"
        @saveAndNext="saveAndNext"
        @goBack="goBack"
      ></ActionButton>
    </template>
  </div>
</template>
<script>
import ActionButton from '@/components/DMP/ActionButton.vue'
import AddEvidence from '@/components/DMP/AddEvidence.vue'
import AddressFormComponent from '@/components/DMP/AddressFormComponent.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  activeStepIndexName,
  registeredAddressType,
  physicalAddressType
} from '@/constant/data.js'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import ParseAddressPopup from '@/views/DataManagement/AddressDetails/ParseAddressPopup.vue'
import Snackbar from '@/components/Snackbar'
import { isValidEnglishASCII } from '@/util/util'
import { isEmpty, isObject, isEqual } from 'lodash'

export default {
  name: 'AddressForm',
  components: {
    ActionButton,
    AddEvidence,
    AddressFormComponent,
    ConfirmationDialog,
    ParseAddressPopup
  },
  props: {
    updateStepper: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      loading: false,
      copyFields: false,
      isOpenBusinessAddress: true,
      isOpenRegistredAddress: true,
      isDepartment: false,
      isSubDepartment: false,
      phySubDivision: [],
      regSubDivision: [],
      regNuts3Code: [],
      phyNuts3Code: [],
      clonePhyNuts3Code: [],
      cloneRegNuts3Code: [],
      register: {
        addrLine1: null,
        fullAddress: null,
        department: null,
        subDepartment: null,
        streetName: null,
        buildingNumber: null,
        buildingName: null,
        floor: null,
        room: null,
        postBox: null,
        postCode: null,
        town: null,
        townLocation: null,
        districtName: null,
        country: null,
        nuts3: null,
        subDivision: null,
        companyName: null,
        evidence: null
      },
      physical: {
        addrLine1: null,
        fullAddress: null,
        department: null,
        subDepartment: null,
        streetName: null,
        buildingNumber: null,
        buildingName: null,
        floor: null,
        room: null,
        postBox: null,
        postCode: null,
        town: null,
        townLocation: null,
        districtName: null,
        country: null,
        nuts3: null,
        subDivision: null,
        companyName: null,
        evidence: null
      },
      business: {},
      setCopyField: false,
      submitted: false,
      physicalFormValidation: true,
      registeredFormValidation: false,
      // Evidence field mapping
      domicilAddrEvd: {
        type: 'DOM',
        evdKey: 'Physical/Business Address',
        evdFieldDB: 'evidence',
        formLabel: 'physical',
        errorMessage: false
      },
      registeredAddrEvd: {
        type: 'REG',
        evdKey: 'Registered Address',
        evdFieldDB: 'evidence',
        formLabel: 'register',
        errorMessage: false
      },
      inputNameMapping: {},
      localEvidenceIdList: [],
      // confirmation popup
      dialog: {
        visible: false,
        title: 'Discard changes',
        text: 'Would you like to discard the changes & go back <br>to Basic Details screen?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {},
        closeDialog: () => {}
      },
      snackbarPayload: {
        // Set default payload
        message: null,
        type: 'is-warning',
        duration: 3000
      },

      addressFields: [
        {
          name: 'fullAddress',
          isTextField: true,
          maxLength: 500,
          regex: 'pipeAndHash'
        },
        {
          name: 'companyName',
          isTextField: true,
          maxLength: 125
        },
        {
          name: 'department',
          isTextField: true,
          maxLength: 70
        },
        {
          name: 'subDepartment',
          isTextField: true,
          maxLength: 70
        },
        {
          name: 'streetName',
          isTextField: true,
          maxLength: 70
        },
        {
          name: 'buildingNumber',
          isTextField: true,
          maxLength: 16,
          regex: 'alphaNum'
        },
        {
          name: 'buildingName',
          isTextField: true,
          maxLength: 35
        },
        {
          name: 'floor',
          isTextField: true,
          maxLength: 70,
          regex: 'alphaNum'
        },
        {
          name: 'postBox',
          isTextField: true,
          maxLength: 16
        },
        {
          name: 'room',
          isTextField: true,
          maxLength: 70,
          regex: 'alphaNum'
        },
        {
          name: 'postCode',
          isTextField: true,
          isMandatory: true,
          maxLength: 16,
          regex: 'alphaNum'
        },
        {
          name: 'country',
          isTextField: false,
          isMandatory: true
        },
        {
          name: 'town',
          isTextField: true,
          maxLength: 100
        },
        {
          name: 'townLocation',
          isTextField: true,
          maxLength: 500
        },
        {
          name: 'districtName',
          isTextField: true,
          maxLength: 35
        },
        {
          name: 'subDivision',
          isTextField: false,
          isMandatory: true
        },
        {
          name: 'nuts3',
          isTextField: false
        }
      ],
      isMandatorySubmit: true,
      showParsedAddressPopup: false,
      parsedAddressDetails: null,
      parseAddressType: null,
      isLoadingRegNuts3Field: false,
      isLoadingPhyNuts3Field: false,
      addressType: {
        register: 'registeredAddress',
        physical: 'physicalAddress'
      },
      // Confirmation popup flag
      isEmitCountryChange: true
    }
  },
  computed: {
    ...mapState('dmp', [
      'registeredAddress',
      'physicalAddress',
      'businessAddress',
      'country',
      'countryObj',
      'countrySubDivision',
      'nuts3',
      'addressTypeDetails',
      'registeredAddressBVDField',
      'physicalAddressBVDField',
      'shouldOverride',
      'postcodeRegex'
    ]),
    ...mapState('evidence', ['evidenceIdList']),
    ...mapGetters('evidence', ['getEvidenceFormStatus', 'getBtnLoadingStatus']),
    ...mapGetters('dmp', ['getEntityDetails'])
  },
  watch: {
    registeredAddress: {
      handler() {
        const { registeredAddress, register, country } = this

        this.register = {
          ...register,
          ...registeredAddress,
          country:
            (registeredAddress?.country &&
              country.find(
                (c) => c.value === registeredAddress.country.value
              )) ||
            null,
          nuts3: {
            value: registeredAddress?.nuts3?.value || null,
            text: registeredAddress?.nuts3?.value
              ? registeredAddress?.nuts3?.value +
                '-' +
                registeredAddress?.nuts3?.text
              : ''
          },

          subDivision: registeredAddress.subDivision
            ? { value: registeredAddress.subDivision.value }
            : null
        }
        this.loadEvidence(
          this.registeredAddrEvd.type,
          registeredAddress?.evidence
        )
      },
      deep: true
    },
    physicalAddress: {
      handler() {
        const { physicalAddress, physical, country } = this

        this.physical = {
          ...physical,
          ...physicalAddress,
          country: physicalAddress?.country
            ? country.find((c) => c.value === physicalAddress.country.value) ||
              null
            : null,
          nuts3: {
            value: physicalAddress?.nuts3?.value || null,
            text: physicalAddress?.nuts3?.value
              ? physicalAddress?.nuts3?.value +
                '-' +
                physicalAddress?.nuts3?.text
              : ''
          },
          subDivision: { value: physicalAddress?.subDivision?.value || null }
        }
        this.loadEvidence(this.domicilAddrEvd.type, physicalAddress?.evidence)
      },
      deep: true
    },

    physical: {
      handler() {
        // If form state back to unchanged then remove evidence message
        this.domicilAddrEvd.errorMessage = this.domicilAddrEvd.errorMessage
          ? !this.isAddressfieldsEqual(this.physical, this.physicalAddress)
          : false

        // evidence not needed in this comparison
        if (
          this.isAddressfieldsEqual(this.physical, this.physicalAddress, true)
        ) {
          // handles for new form when evidence gets added but go back to initial state
          // in that case remove first then add initial evidence
          this.removeEvidence(this.domicilAddrEvd.evdKey)
          this.loadEvidence(
            this.domicilAddrEvd.type,
            this.physicalAddress?.evidence
          )
        }
      },
      deep: true
    },
    register: {
      handler() {
        // If form state back to unchanged then remove evidence message
        this.registeredAddrEvd.errorMessage = this.registeredAddrEvd
          .errorMessage
          ? !this.isAddressfieldsEqual(this.register, this.registeredAddress)
          : false

        // evidence not needed in this comparison
        if (
          this.isAddressfieldsEqual(this.register, this.registeredAddress, true)
        ) {
          // handles for new form when evidence gets added but go back to initial state
          // in that case remove first then add initial evidence
          this.removeEvidence(this.registeredAddrEvd.evdKey)
          this.loadEvidence(
            this.registeredAddrEvd.type,
            this.registeredAddress?.evidence
          )
        }
      },
      deep: true
    },
    evidenceIdList: {
      handler() {
        // When evidence is uploaded then remove evidence message
        this.domicilAddrEvd.errorMessage = this.domicilAddrEvd.errorMessage
          ? !this.evidenceIdList.includes(this.domicilAddrEvd.evdKey)
          : false
        this.registeredAddrEvd.errorMessage = this.registeredAddrEvd
          .errorMessage
          ? !this.evidenceIdList.includes(this.registeredAddrEvd.evdKey)
          : false
        if (!this.evidenceIdList.includes(this.registeredAddrEvd.evdKey)) {
          this.register.evidence = null
        }
        if (!this.evidenceIdList.includes(this.domicilAddrEvd.evdKey)) {
          this.physical.evidence = null
        }
      },
      deep: true
    },
    registeredAddressBVDField: {
      handler() {
        this.syncRegisterAddress()
      },
      deep: true
    },
    physicalAddressBVDField: {
      handler() {
        this.syncPhysicalAddress()
      },

      deep: true
    }
  },

  async mounted() {
    this.getFormDetails()
  },
  methods: {
    ...mapActions('dmp', [
      'getAddressDetails',
      'getCountryList',
      'getStateList',
      'getNuts3List',
      'updateRegisteredAddress',
      'updatePhysicalAddress',
      'updateAddressDetails',
      'getAddressType',
      'parseAddressDetails',
      'getPostcodeRegexList'
    ]),
    ...mapActions('evidence', [
      'uploadEvidence',
      'updateEvidenceIdList',
      'resetEvidenceStore',
      'removeEvidence'
    ]),
    ...mapActions('assignedEntities', ['checkAndUpdateTodoStatus']),
    submitForm() {},

    async getFormDetails() {
      try {
        this.resetEvidenceStore()
        this.loading = true
        const { sgaId } = this.getEntityDetails
        this.sgaId = sgaId
        await this.getCountryList()
        await this.getAddressType()
        await this.getAddressDetails(this.sgaId)
        await this.getPostcodeRegexList()

        // Dynamically update validations for postCode based on country regex
        this.updatePostCodeValidation()
      } finally {
        this.loading = false
      }
    },

    resetCountryRelatedFields(addressType, addressBackup) {
      if (this.hasUnsavedChanges()) {
        this.dialog.title = 'Change Country Confirmation'
        this.dialog.text = `Are you sure you want to change the country?<br/> 
          All address details associated with this country will be cleared.`
        this.dialog.visible = true
        this.isEmitCountryChange = false

        // Flag to prevent closeDialog after confirmation
        let isConfirmed = false

        this.dialog.closeDialog = () => {
          this.dialog.visible = false
          if (isConfirmed) return

          // Persist the address fields
          switch (addressType) {
            case this.addressType.physical:
              this.physical = {
                ...addressBackup
              }
              break
            case this.addressType.register:
              this.register = {
                ...addressBackup
              }
              break
          }

          this.$nextTick(() => {
            // Reset flag for country change confirmation
            this.dialog.visible = false
            this.isEmitCountryChange = true
          })
        }

        this.dialog.triggerFunction = () => {
          isConfirmed = true
          switch (addressType) {
            case this.addressType.physical:
              this.physical = {
                ...this.physical,
                postCode: '',
                townLocation: '',
                town: '',
                districtName: ''
              }
              break
            case this.addressType.register:
              this.register = {
                ...this.register,
                postCode: '',
                townLocation: '',
                town: '',
                districtName: ''
              }
              break
          }
          this.$nextTick(() => {
            this.isEmitCountryChange = true
          })
        }
      }
    },

    syncRegisterAddress() {
      this.isEmitCountryChange = false
      const updateRegisteredKey = (key, value) => {
        if (isObject(value) && !isEmpty(value)) {
          this.register = {
            ...this.register,
            [key]: JSON.parse(JSON.stringify(value)) // Deep copy the object
          }
        } else {
          this.register = { ...this.register, [key]: value }
        }
      }
      if (!this.shouldOverride) {
        // Update this.register based on this.registeredAddressBVDField
        Object.keys(this.registeredAddressBVDField).forEach((key) => {
          const regObj = this.registeredAddressBVDField[key]
          if (key in this.register) {
            updateRegisteredKey(key, regObj !== undefined ? regObj : null)
          }
        })

        // Set to null any keys in this.register not present in this.registeredAddressBVDField (except 'addrLine1')
        Object.keys(this.register).forEach((key) => {
          if (!(key in this.registeredAddressBVDField) && key !== 'addrLine1') {
            this.register[key] = null
          }
        })
      } else {
        Object.keys(this.registeredAddressBVDField).forEach((key) => {
          if (key in this.register) {
            updateRegisteredKey(key, this.registeredAddressBVDField[key])
          }
        })
      }
      this.$nextTick(() => {
        this.isEmitCountryChange = true
      })
    },

    syncPhysicalAddress() {
      this.isEmitCountryChange = false
      const updatePhysicalKey = (key, value) => {
        if (isObject(value) && !isEmpty(value)) {
          this.physical = {
            ...this.physical,
            [key]: JSON.parse(JSON.stringify(value)) // Deep copy the object
          }
        } else {
          this.physical = {
            ...this.physical,
            [key]: value
          }
        }
      }

      if (!this.shouldOverride) {
        // Update this.physical based on this.physicalAddressBVDField
        Object.keys(this.physicalAddressBVDField).forEach((key) => {
          const phyObj = this.physicalAddressBVDField[key]
          if (key in this.physical) {
            updatePhysicalKey(key, phyObj !== undefined ? phyObj : null)
          }
        })

        // Set to null any keys in this.physical not present in this.physicalAddressBVDField (except 'addrLine1')
        Object.keys(this.physical).forEach((key) => {
          if (!(key in this.physicalAddressBVDField) && key !== 'addrLine1') {
            this.physical[key] = null
          }
        })
      } else {
        // Handle the case when shouldOverride is true
        Object.keys(this.physicalAddressBVDField).forEach((key) => {
          if (key in this.physical) {
            updatePhysicalKey(key, this.physicalAddressBVDField[key])
          }
        })
      }
      this.$nextTick(() => {
        this.isEmitCountryChange = true
      })
    },

    copyAddressField() {
      this.isEmitCountryChange = false
      const tmpEvd = this.register.evidence
      if (this.copyFields === true) {
        const clonePhysicalAddress = {}
        for (const key in this.physical) {
          if (key !== 'addrLine1') {
            clonePhysicalAddress[key] = this.physical[key]
          }
        }
        this.register = {
          ...clonePhysicalAddress,
          addrLine1: this.register.addrLine1,
          evidence: tmpEvd
        }
        this.setCopyField = true
      } else {
        this.register = { ...this.registeredAddress, evidence: tmpEvd }
      }
      this.$nextTick(() => {
        this.isEmitCountryChange = true
      })
    },

    updateRegisterAddress(address) {
      this.register = address
      if (
        !this.isAddressfieldsEqual(this.register, this.registeredAddress, true)
      ) {
        this.mandateReupload(this.registeredAddrEvd.evdKey)
      }
    },
    updatePhysicalAddress(address) {
      this.physical = address
      if (
        !this.isAddressfieldsEqual(this.physical, this.physicalAddress, true)
      ) {
        this.mandateReupload(this.domicilAddrEvd.evdKey)
      }
    },

    // filter the nuts3 list by mapping postcode and country
    async getNuts3ByListForPhyAddr(label) {
      if (label === 'physicalAddress') {
        this.phyNuts3Code = []
        const obj = {}
        this.isLoadingPhyNuts3Field = true
        obj.postcode = this.physical?.postCode || null
        obj.countryId = this.physical?.country?.value || null
        const response = await this.getNuts3List(obj)
        if (response.data?.data && response.data?.data?.length > 0) {
          this.phyNuts3Code = response.data.data
        } else {
          this.phyNuts3Code = []
        }
        this.isLoadingPhyNuts3Field = false
      }
    },

    // filter the nuts3 list by mapping postcode
    async getNuts3ByListForRegAddr(label) {
      if (label === 'registeredAddress') {
        this.regNuts3Code = []
        const obj = {}

        obj.postcode = this.register?.postCode || null
        obj.countryId = this.register?.country?.value || null
        this.isLoadingRegNuts3Field = true
        const response = await this.getNuts3List(obj)
        if (response.data?.data && response.data?.data?.length > 0) {
          this.regNuts3Code = response.data.data
        } else {
          this.regNuts3Code = []
        }
        this.isLoadingRegNuts3Field = false
      }
    },

    async getPhySubDivision(label, countryObj) {
      if (label === 'physicalAddress' && countryObj) {
        const response = await this.getStateList(countryObj.value)
        if (response.data.data && response.data.data.length > 0) {
          this.phySubDivision = response.data.data
          const subDivision = this.phySubDivision.find((country) => {
            if (this.physical && this.physical.subDivision) {
              return country.value === this.physical.subDivision.value
            } else {
              return null
            }
          })

          this.physical.subDivision = subDivision
        } else {
          this.physical.subDivision = null
          this.phySubDivision = []
          this.physical.subDivision = null
        }
      } else if (label === 'physicalAddress' && !countryObj) {
        this.phySubDivision = []
      }
    },
    async getRegsubDivision(label, countryObj) {
      if (label === 'registeredAddress' && countryObj) {
        const response = await this.getStateList(countryObj.value)
        if (response.data.data && response.data.data.length > 0) {
          this.regSubDivision = response.data.data
          const subDivision = this.countrySubDivision.find((countryObj) => {
            if (this.register && this.register.subDivision) {
              return countryObj.value === this.register.subDivision.value
            } else {
              return null
            }
          })
          this.register.subDivision = subDivision
        } else {
          this.register.subDivision = null
          this.regSubDivision = []
        }
      } else if (label === 'registeredAddress' && !countryObj) {
        this.regSubDivision = []
      }
    },
    toggleBusinessAddress() {
      this.isOpenBusinessAddress = !this.isOpenBusinessAddress
      if (this.isOpenBusinessAddress === true) {
        this.isOpenRegistredAddress = true
      }
    },
    closeDialog() {
      this.dialog.visible = false
    },
    toggleRegisteredAddress() {
      this.isOpenRegistredAddress = !this.isOpenRegistredAddress
      if (this.isOpenRegistredAddress === true) {
        this.isOpenBusinessAddress = true
      }
    },
    async saveAndNext() {
      const saveDataStatus = await this.saveData(true)
      if (saveDataStatus) {
        // If data updated successfully navigate to next form
        this.updateStepper(activeStepIndexName.industryDetails)
      }
    },

    isRequiredFieldPresent() {
      if (
        !this.physical.postCode ||
        !(this.physical.country && this.physical.country.value) ||
        !(this.physical.subDivision && this.physical.subDivision.value) ||
        !this.register.postCode ||
        !(this.register.country && this.register.country.value) ||
        !(this.register.subDivision && this.register.subDivision.value)
      ) {
        return false
      } else {
        return true
      }
    },

    isValidNuts3ForRegisteredAddr() {
      // Check if the value exists in regNuts3Code
      if (this.register?.nuts3?.value) {
        const isValid = this.regNuts3Code.some(
          (nuts3Obj) => nuts3Obj.value === this.register.nuts3.value
        )
        return isValid
      } else {
        // returning true if nuts3 is null
        return true
      }
    },

    isValidNuts3ForPhysicalAddr() {
      // Check if the value exists in phyNuts3Code
      if (this.physical?.nuts3?.value) {
        const isValid = this.phyNuts3Code.some(
          (nuts3Obj) => nuts3Obj.value === this.physical.nuts3.value
        )
        return isValid
      } else {
        // returning true if nuts3 is null
        return true
      }
    },

    async saveData(isFinalSubmit = false) {
      // Update `postCode` validation rules before any validation
      this.updatePostCodeValidation()

      let submissionStatus = false
      this.submitted = isFinalSubmit
      this.isMandatorySubmit = isFinalSubmit

      const isValidAddressDetails =
        this.isValidAddressDetails(this.physical) &&
        this.isValidAddressDetails(this.register)
      const isValidEvidence = this.isValidEvidence()

      if (!isValidEvidence || !isValidAddressDetails) {
        return
      }
      this.loading = true
      await this.getNuts3ByListForPhyAddr('physicalAddress')
      await this.getNuts3ByListForRegAddr('registeredAddress')

      if (!this.isValidNuts3ForPhysicalAddr()) {
        Snackbar({
          message: 'Please select valid Nuts3 code.',
          type: 'is-warning',
          indefinite: true
        })
        this.loading = false

        return
      }

      if (!this.isValidNuts3ForRegisteredAddr()) {
        Snackbar({
          message: 'Please select valid Nuts3 code.',
          type: 'is-warning',
          indefinite: true
        })
        this.loading = false

        return
      }
      const obj = {}
      const addressDetailsArr = []
      const registeredAddressObj = {}
      const physicalAddressObj = {}
      registeredAddressObj.type = this.addressTypeDetails.find(
        (obj) => obj.code === registeredAddressType.ADDRESS_CODE
      )?.addressTypeId
      registeredAddressObj.code = registeredAddressType.ADDRESS_CODE
      registeredAddressObj.details = {}
      addressDetailsArr.push(registeredAddressObj)
      physicalAddressObj.type = this.addressTypeDetails.find(
        (obj) => obj.code === physicalAddressType.ADDRESS_CODE
      )?.addressTypeId
      physicalAddressObj.code = physicalAddressType.ADDRESS_CODE
      physicalAddressObj.details = {}
      addressDetailsArr.push(physicalAddressObj)

      try {
        const evdIdMappingRes = await this.uploadEvidence()
        const mappedEvdIds = this.updateEvidenceIdMapping(evdIdMappingRes)

        addressDetailsArr.forEach((address) => {
          if (address.code === registeredAddressType.ADDRESS_CODE) {
            registeredAddressObj.details = this.addressDetailsObj(this.register)
            registeredAddressObj.details.addressId = parseInt(
              this.registeredAddress.addressId
            )
            registeredAddressObj.details.evidence =
              this.isEvidencePresentInInputMapping(
                this.registeredAddrEvd.evdKey
              )
                ? mappedEvdIds[this.registeredAddrEvd.evdKey] ||
                  this.registeredAddress[this.registeredAddrEvd.evdFieldDB]
                : null
            this.register.evidence = registeredAddressObj.details.evidence
          } else if (address.code === physicalAddressType.ADDRESS_CODE) {
            physicalAddressObj.details = this.addressDetailsObj(this.physical)
            physicalAddressObj.details.addressId = parseInt(
              this.physicalAddress.addressId
            )
            physicalAddressObj.details.evidence =
              this.isEvidencePresentInInputMapping(this.domicilAddrEvd.evdKey)
                ? mappedEvdIds[this.domicilAddrEvd.evdKey] ||
                  this.physicalAddress[this.domicilAddrEvd.evdFieldDB]
                : null
            this.physical.evidence = physicalAddressObj.details.evidence
          }
        })
        obj.addressDetails = addressDetailsArr
        const updateAddresses = {}
        updateAddresses.sgaId = this.sgaId
        updateAddresses.addressDetails = obj
        await this.checkAndUpdateTodoStatus()
        this.isEmitCountryChange = false
        if (this.hasUnsavedChanges()) {
          // Check entity status and update it to In Progress, if it's Todo
          const updateAddrRes = await this.updateAddressDetails(updateAddresses)
          submissionStatus = updateAddrRes === 200
          await this.getAddressDetails(this.sgaId)
        } else {
          submissionStatus = true
        }
        this.snackbarPayload.message = 'Address records updated successfully'
      } catch (error) {
        this.snackbarPayload.message = 'Address records not updated'
      } finally {
        this.isMandatorySubmit = true
        this.loading = false
        this.$nextTick(() => {
          this.isEmitCountryChange = true
        })
      }
      // Snackbar for submission status failure/success
      Snackbar(this.snackbarPayload)
      // Status of update, Navigate to next form
      return submissionStatus
    },
    hasUnsavedChanges() {
      if (
        this.isAddressfieldsEqual(this.physical, this.physicalAddress) &&
        this.isAddressfieldsEqual(this.register, this.registeredAddress)
      ) {
        return false
      } else {
        return true
      }
    },
    addressDetailsObj(address) {
      const addressObj = {}
      addressObj.addressLine1 = address.addrLine1 || null
      addressObj.fullAddress = address.fullAddress || null
      addressObj.department = address.department || null
      addressObj.subDepartment = address.subDepartment || null
      addressObj.streetName = address.streetName || null
      addressObj.buildingNumber = address.buildingNumber || null
      addressObj.buildingName = address.buildingName || null
      addressObj.floor = address.floor || null
      addressObj.room = address.room || null
      addressObj.postBox = address.postBox || null
      addressObj.postCode = address.postCode || null
      addressObj.townLocationName = address.townLocation || null
      addressObj.district = address.districtName || null
      addressObj.town = address.town || null
      addressObj.evidence = address.evidence || null
      addressObj.companyName = address.companyName || null

      addressObj.nuts3Code = address.nuts3
        ? address.nuts3.value
          ? address.nuts3.value
          : null
        : null

      addressObj.csId = address.subDivision
        ? address.subDivision.value
          ? address.subDivision.value
          : null
        : null

      addressObj.countryId = address.country
        ? address.country.value
          ? address.country.value
          : null
        : null
      return addressObj
    },

    goBack() {
      // Check if there are unsaved changes
      if (this.hasUnsavedChanges()) {
        this.dialog.visible = true
        this.dialog.closeDialog = this.closeDialog
        this.dialog.triggerFunction = () => {
          this.updateStepper(activeStepIndexName.basicDetails)
        }
      } else {
        this.updateStepper(activeStepIndexName.basicDetails)
      }
    },
    isAddressfieldsEqual(newAddress, oldAddress, evidenceNotNeeded = false) {
      const propertiesToCompare = [...this.addressFields]

      if (!evidenceNotNeeded) {
        propertiesToCompare.push({
          name: 'evidence',
          isTextField: true
        })
      }

      const isPropertySame = propertiesToCompare.every((field) => {
        // If old address is empty then check null value of new address
        return field.isTextField
          ? isEmpty(oldAddress)
            ? this.isNullValue(newAddress[field.name])
            : (isEmpty(newAddress[field.name]) &&
                isEmpty(oldAddress[field.name])) ||
              newAddress[field.name] === oldAddress[field.name]
          : isEmpty(oldAddress)
          ? this.isNullValue(newAddress[field.name]?.value)
          : this.isNullValue(newAddress[field.name]?.value)
          ? this.isNullValue(oldAddress[field.name]?.value)
          : newAddress[field.name]?.value === oldAddress[field.name]?.value
      })

      return isPropertySame
    },
    updateEvidenceIdMapping(evidenceIdMappingResponse) {
      const evidenceIdMapping = {}
      if (
        evidenceIdMappingResponse &&
        Array.isArray(evidenceIdMappingResponse)
      ) {
        evidenceIdMappingResponse.forEach((item) => {
          if (item.evidenceIds && item.evidenceIds.length > 0) {
            const evidenceId = item ? Number(item.evidenceIds[0].id) : null
            evidenceIdMapping[item.attributeName] = evidenceId
          }
        })
      }
      return evidenceIdMapping
    },
    loadEvidence(addressType, evidenceId) {
      if (!evidenceId) return

      const evidenceKey =
        addressType === this.registeredAddrEvd.type
          ? this.registeredAddrEvd.evdKey
          : this.domicilAddrEvd.evdKey
      this.updateEvidenceIdList(evidenceKey)
      this.updateLocalEvidenceIdList(evidenceKey)
      this.updateInputNameMapping(evidenceKey, evidenceId)
    },
    isEvidencePresentInInputMapping(inputName) {
      return this.evidenceIdList.includes(inputName)
    },
    updateLocalEvidenceIdList(inputName) {
      const evidenceIdSet = new Set([...this.localEvidenceIdList, inputName])
      this.localEvidenceIdList = [...evidenceIdSet]
    },
    async toggleEvidenceForm(inputName) {
      const isViewEvidence = this.getEvidenceFormStatus(inputName)
      const evidenceIds = this.getEvidenceIds(inputName)

      this.$emit('toggleEvidenceForm', {
        inputName,
        isViewEvidence,
        evidenceIds: evidenceIds || []
      })
    },
    getEvidenceIds(evidenceId) {
      return this.inputNameMapping[`${evidenceId}`]
    },
    updateInputNameMapping(inputName, evidenceId) {
      this.$set(this.inputNameMapping, inputName, evidenceId)
    },
    isEvidenceBtnDisabled(inputName) {
      const evdMetaData =
        inputName === this.domicilAddrEvd.formLabel
          ? this.domicilAddrEvd
          : this.registeredAddrEvd
      if (this.localEvidenceIdList.includes(evdMetaData.evdKey)) {
        return false
      }

      if (inputName === this.domicilAddrEvd.formLabel) {
        return this.isAddressfieldsEqual(this.physical, this.physicalAddress)
      } else {
        return this.isAddressfieldsEqual(this.register, this.registeredAddress)
      }
    },
    isValidEvidence() {
      const registeredEvdKey = this.registeredAddrEvd.evdKey
      const domicilEvdKey = this.domicilAddrEvd.evdKey
      let isRegisteredAddrEvd = true
      let isDomicilAddrEvd = true
      this.registeredAddrEvd.errorMessage = false
      this.domicilAddrEvd.errorMessage = false
      if (!this.isAddressfieldsEqual(this.register, this.registeredAddress)) {
        isRegisteredAddrEvd =
          this.getEvidenceFormStatus(registeredEvdKey) ||
          (this.localEvidenceIdList.includes(registeredEvdKey) &&
            this.evidenceIdList.includes(registeredEvdKey))
        this.registeredAddrEvd.errorMessage = !isRegisteredAddrEvd
      }

      if (!this.isAddressfieldsEqual(this.physical, this.physicalAddress)) {
        isDomicilAddrEvd =
          this.getEvidenceFormStatus(domicilEvdKey) ||
          (this.localEvidenceIdList.includes(domicilEvdKey) &&
            this.evidenceIdList.includes(domicilEvdKey))
        this.domicilAddrEvd.errorMessage = !isDomicilAddrEvd
      }
      return isRegisteredAddrEvd && isDomicilAddrEvd
    },

    mandateReupload(evdKey) {
      this.removeEvidence(evdKey)
    },

    isValidAddressDetails(addressObj) {
      const pattern = {
        pipeAndHash: /^(?!.*##)[^|]*$/,
        alphaNum: /^[ A-Za-z0-9 -]*$/
      }
      const excludedNotAsciiFields = [
        'nuts3',
        'subDivision',
        'country',
        'buildingNumber',
        'floor',
        'room'
      ]

      let isValidField = true
      for (let field of this.addressFields) {
        const fieldValue = addressObj[field.name]

        // Length Validation Check
        if (field?.maxLength && fieldValue?.length > field.maxLength) {
          isValidField = false
        }

        // Pattern Validation Check
        if (
          isValidField &&
          field?.regex &&
          !pattern[field.regex].test(fieldValue)
        ) {
          isValidField = false
        }

        // Postcode validation check
        // If country is selected check for coresponding postcode validation
        const countryId = addressObj['country']?.value
        const countryCode =
          addressObj['country']?.countryCode || addressObj['country']?.code

        if (
          isValidField &&
          field.name === 'postCode' &&
          !this.isNullValue(fieldValue) &&
          countryId
        ) {
          const countryRegex = this.getRegexForCountry(countryId)
          isValidField = new RegExp(countryRegex?.pattern).test(fieldValue)
        }

        // Ascii Validation Check
        if (isValidField && !excludedNotAsciiFields.includes(field.name)) {
          isValidField = !this.isNullValue(fieldValue)
            ? isValidEnglishASCII(fieldValue)
            : true
        }

        // Mandatory Field Check
        if (isValidField && this.isMandatorySubmit && field?.isMandatory) {
          isValidField = !this.isNullValue(
            field.isTextField ? fieldValue : fieldValue?.value
          )
        }

        if (!isValidField) {
          break
        }
      }
      return isValidField
    },
    isNullValue(value) {
      return [undefined, null, ''].includes(value)
    },
    async handleParseAddress(addressType) {
      try {
        this.loading = true
        const address =
          addressType === 'registeredAddress' ? this.register : this.physical

        const concatenatedAddress = address.fullAddress
        const addressDetails = await this.parseAddressDetails(
          concatenatedAddress
        )

        if (!isEmpty(addressDetails)) {
          this.showParsedAddressPopup = true
          this.parsedAddressDetails = addressDetails
          this.parseAddressType = addressType
        }
      } catch (error) {
        Snackbar({
          message: 'Failed to parse the address, please try again!',
          type: 'is-danger',
          duration: 3000
        })
      } finally {
        this.loading = false
      }
    },
    handleParsedAddressConfirmation(parsedAddress) {
      this.isEmitCountryChange = false
      if (parsedAddress) {
        if (this.parseAddressType === 'registeredAddress') {
          this.register = { ...this.register, ...parsedAddress }
        } else if (this.parseAddressType === 'physicalAddress') {
          this.physical = { ...this.physical, ...parsedAddress }
        }
        // Reset the popup visibility
        this.showParsedAddressPopup = false
      }
    },
    closeParsedAddressPopup() {
      this.showParsedAddressPopup = false
      this.$nextTick(() => {
        this.isEmitCountryChange = true
      })
    },
    getRegexForCountry(countryId) {
      if (!countryId) return

      const countryRegex = this.postcodeRegex.find(
        (item) => item.countryId === countryId
      )
      if (!countryRegex) return
      const regexPattern = `^(${countryRegex?.regexPattern})$`
      return { pattern: regexPattern, examples: countryRegex?.examples }
    },
    updatePostCodeValidation() {
      const postCodeField = this.addressFields.find(
        (field) => field.name === 'postCode'
      )

      if (postCodeField) {
        // Dynamically compute or retrieve countryRegexMap
        const countryRegexMap = this.postcodeRegex.reduce((map, entry) => {
          map[entry.countryId] = !!entry.regexPattern // true if regexPattern exists
          return map
        }, {})

        const hasRegexForPhysical = this.physical?.country?.value
          ? countryRegexMap[this.physical.country.value]
          : false

        const hasRegexForRegistered = this.register?.country?.value
          ? countryRegexMap[this.register.country.value]
          : false

        // Set validation rules
        if (hasRegexForPhysical || hasRegexForRegistered) {
          postCodeField.regex = null
          postCodeField.maxLength = null
        } else {
          postCodeField.regex = 'alphaNum'
          postCodeField.maxLength = 16
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.required-error {
  color: red;
  position: relative;
  font-size: 12px;
  margin-top: 0.5rem;
}
.address-details-section {
  margin-top: 1rem;
  position: relative;

  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    background: rgba(0, 0, 0, 0.1);
    z-index: 9;
    .inava-loader {
      position: relative;
      display: flex;
      margin: auto;
      border-radius: 50%;
      border: none;
      z-index: 900;
      width: 70px;
      height: 70px;
      background-image: '@/assets/Inava-logo-GIF.gif';
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  section {
    padding: 0 0 0 0;
  }

  .required {
    color: red;
    font-size: 12px;
  }
  .card {
    background-color: white;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .card-header-icon {
    span {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.16px;
      color: #1e1e1e;
      opacity: 1;
    }
  }
  .card-content {
    padding: 0;
  }
  .section-title {
    font-size: 21px;
    font-weight: 600;
    color: #00218a;
  }

  .collpase-window {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;

    .add-evidence {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .button-component {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .collapse-button {
        span {
          display: flex;
          flex-direction: row;
          p {
            font: normal normal 13px/20px Quicksand;
            font-weight: 600;
            letter-spacing: 0.16px;
            color: #1e1e1e;
            opacity: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
        background: none;
        border: none;
        color: #000;
        cursor: pointer;
        .icon-size {
          font-size: 24px;
        }
      }
    }
  }
  .address-type {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    width: max-content;
    justify-content: space-between;
    width: 100%;
    .address-title {
      display: flex;
      align-items: center;
      p {
        text-align: left;
        font: normal normal 15px/19px Quicksand;
        line-height: 15px;
        text-align: center;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: #535eeb;
        opacity: 1;
      }
    }
  }

  .mandatory-note {
    p {
      font: normal normal normal 12px/15px Quicksand;
      letter-spacing: 0.14px;
      padding-top: 4px;
    }
  }
  .mandatory {
    color: red;
    font-size: 17px;
  }
  .checkbox-container {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: max-content;
    margin-top: 0.5rem;
    .checkAddress {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      vertical-align: middle;
      word-wrap: break-word;
      margin-left: 0.25rem;
      p {
        text-align: left;
        font: normal normal 12px/15px Quicksand;
        font-weight: 500;
        letter-spacing: 0px;
        color: #7d7d7d;
        opacity: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding: 4px 4px 4px 4px;
      }
    }
  }

  .radio-button-container {
    display: flex;
    flex-direction: row;
    .select-address {
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 5px;
      width: max-content;
      margin-top: 0.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      vertical-align: middle;
      word-wrap: break-word;
      input {
        margin-left: 0.25rem;
      }
      p {
        text-align: left;
        font: normal normal 12px/15px Quicksand;
        font-weight: 500;
        letter-spacing: 0px;
        color: #7d7d7d;
        opacity: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding: 4px 4px 4px 4px;
      }
    }
    .select-registered-address {
      margin-left: 3rem;
    }
  }
}

.address-form-component-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .address-form-layout {
    width: 100%;
  }

  // Default margin for all screen sizes
  .parse-address-button,
  .parse-address-button-registered {
    align-self: flex-start;

    .button {
      height: 35px;
      background: #535eeb;
      border-radius: 4px;
      text-align: center;
      font: normal normal 600 14px/17px Quicksand;
      letter-spacing: 0.18px;
      color: #fff;
      opacity: 1;

      &:hover {
        background-color: #00218a;
        color: #fff;
        opacity: 0.9;
      }

      &:disabled {
        background-color: #cccccc;
        color: #000000;
      }

      &:disabled:hover {
        opacity: 0.8;
      }
    }
  }

  .parse-address-button {
    margin-top: 9.2rem;
  }

  .parse-address-button-registered {
    margin-top: 11.3rem;
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .address-details-section {
    border-radius: 6px;

    .button-component {
      .collapse-button {
        span {
          p {
            font: normal normal 14px/17px Quicksand;
            font-weight: 600;
          }
        }
      }
    }

    .card-header-icon {
      span {
        font: 14px Quicksand;
      }
      .mdi-24px.mdi:before {
        font-size: 24px;
        color: blue;
      }
    }
    .section-title {
      font-size: 24px;
    }
    .mandatory-note {
      p {
        font: normal normal normal 13px/16px Quicksand;
        letter-spacing: 0.14px;
      }
    }
    .address-type {
      .address-title {
        p {
          font: normal normal 17px/21px Quicksand;
        }
      }
      .add-evidence {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    .mandatory {
      color: red;
      font-size: 12px;
    }
    .checkbox-container {
      border-radius: 8px;
      width: max-content;
      .checkAddress {
        p {
          font: normal normal 14px/17px Quicksand;
          font-weight: 500;
        }
      }
    }
    .add-evidence {
      p {
        font: normal normal 18px/22px Quicksand;
      }
    }
  }
}
.address-form-component-container {
  .parse-address-button {
    margin-top: 9.6rem;
  }

  .parse-address-button-registered {
    margin-top: 11.7rem;
  }
}
@media (min-width: 1700px) {
  .address-details-section {
    .mandatory {
      color: red;
      font-size: 12px;
    }
    .section-title {
      font-size: 26px;
    }
    .card-header-icon {
      span {
        font: 16px;
      }
    }
    .collapse-button {
      span {
        p {
          font: normal normal 16px/20px Quicksand;
          font-weight: 600;
        }
      }
    }
    .mandatory-note {
      p {
        font: normal normal normal 14px/18px Quicksand;
        letter-spacing: 0.14px;
      }
    }
    .address-type {
      .address-title {
        p {
          font: normal normal medium 20px/25px Quicksand;
        }
      }
    }

    .checkbox-container {
      .checkAddress {
        p {
          font: normal normal 16px/20px Quicksand;
          font-weight: 500;
        }
      }
    }
    .add-evidence {
      p {
        font: normal normal 20px/25px Quicksand;
      }
    }
  }
  .address-form-component-container {
    .parse-address-button {
      margin-top: 10.1rem;
    }

    .parse-address-button-registered {
      margin-top: 12.4rem;
    }
  }
}
</style>
