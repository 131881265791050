<template>
  <div>
    <GridColumnMenuSort
      v-if="false"
      :column="column"
      :sortable="sortable"
      :sort="sort"
      @closemenu="closeMenu"
      @sortchange="sortChange"
    />
    <GridColumnMenuFilter
      :column="column"
      :filterable="filterable"
      :filter="filter"
      :filterOperators="filterOperators"
      @filterfocus="handleFocus"
      @closemenu="closeMenu"
      @expandchange="expandChange"
      @filterchange="filterChange"
    />
    <GridColumnMenuItemGroup>
      <GridColumnMenuItem
        :title="'Columns'"
        :icon-class="'k-i-columns'"
        @menuitemclick="onMenuItemClick"
      />
      <GridColumnMenuItemContent :show="columnsExpanded">
        <div class="k-column-list-wrapper">
          <!-- <form  @reset="onReset"> -->
          <div class="k-column-list">
            <div
              :key="idx"
              class="k-column-list-item"
              v-for="(column, idx) in currentColumns"
            >
              <span>
                <input
                  :id="'column-visiblity-show-' + idx"
                  :class="'k-checkbox k-checkbox-md k-rounded-md'"
                  :type="'checkbox'"
                  @click="onToggleColumn(idx)"
                  :disabled="column.disabled"
                  :checked="!column.hidden"
                />
                <label
                  :for="'column-visiblity-show-' + idx"
                  :class="'k-checkbox-label'"
                  :style="{ userSelect: 'none' }"
                >
                  {{ column.title }}
                </label>
              </span>
            </div>
          </div>
          <div class="k-columnmenu-actions">
            <kbutton @click="onReset">Reset</kbutton>
            <kbutton :theme-color="'primary'" @click="closeMenu">close</kbutton>
          </div>
          <!-- </form> -->
        </div>
      </GridColumnMenuItemContent>
    </GridColumnMenuItemGroup>
  </div>
</template>
<script>
import {
  GridColumnMenuFilter,
  GridColumnMenuSort,
  GridColumnMenuItemGroup,
  GridColumnMenuItemContent,
  GridColumnMenuItem
} from '@progress/kendo-vue-grid'
import { Button } from '@progress/kendo-vue-buttons'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    GridColumnMenuSort: GridColumnMenuSort,
    GridColumnMenuFilter: GridColumnMenuFilter,
    GridColumnMenuItemGroup: GridColumnMenuItemGroup,
    GridColumnMenuItemContent: GridColumnMenuItemContent,
    GridColumnMenuItem: GridColumnMenuItem,
    kbutton: Button
  },
  props: {
    column: Object,
    sortable: [Boolean, Object],
    sort: {
      type: Array
    },
    filter: Object,
    filterable: Boolean,
    columns: Array,
    defaultColumn: Array
  },
  data() {
    return {
      currentColumns: [],
      columnsExpanded: false,
      filterExpanded: false,
      filterOperators: {
        text: [
          { text: 'grid.filterContainsOperator', operator: 'contains' },
          {
            text: 'grid.filterNotContainsOperator',
            operator: 'doesnotcontain'
          },
          { text: 'grid.filterEqOperator', operator: 'eq' },
          { text: 'grid.filterNotEqOperator', operator: 'neq' },
          { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
          { text: 'grid.filterEndsWithOperator', operator: 'endswith' }
        ],
        numeric: [
          { text: 'grid.filterEqOperator', operator: 'eq' },
          { text: 'grid.filterNotEqOperator', operator: 'neq' },
          { text: 'grid.filterGteOperator', operator: 'gte' },
          { text: 'grid.filterLteOperator', operator: 'lte' }
        ],
        date: [
          { text: 'grid.filterEqOperator', operator: 'eq' },
          { text: 'grid.filterNotEqOperator', operator: 'neq' },
          { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
          { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' }
        ],
        boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }]
      }
    }
  },
  computed: {
    ...mapState('pmtDocuments', ['filteredColumnList'])
  },
  created() {
    this.$data.currentColumns = this.$props.columns
  },
  methods: {
    ...mapActions('pmtDocuments', ['addFilteredColumnList']),
    handleFocus(e) {
      this.$emit('contentfocus', e)
    },
    onToggleColumn(id) {
      this.currentColumns = this.currentColumns.map((column, idx) => {
        return idx === id ? { ...column, hidden: !column.hidden } : column
      })

      this.onCheckColumn()
    },
    onReset(event) {
      const allColumns = this.$props.columns.map((col) => {
        if (this.defaultColumn.includes(col.field)) {
          return {
            ...col,
            hidden: false
          }
        } else {
          return {
            ...col,
            hidden: true
          }
        }
      })
      this.currentColumns = allColumns
      this.onCheckColumn()
      this.closeMenu()
    },
    onCheckColumn(event) {
      window.localStorage.setItem(
        'documentColumns',
        JSON.stringify(this.currentColumns)
      )
      this.$emit('columnssubmit', this.currentColumns)
    },
    onMenuItemClick() {
      const value = !this.columnsExpanded
      this.columnsExpanded = value
      this.filterExpanded = value ? false : this.filterExpanded
    },
    onFilterExpandChange(value) {
      this.filterExpanded = value
      this.columnsExpanded = value ? false : this.columnsExpanded
    },
    expandChange() {
      this.$emit('expandchange')
    },
    closeMenu() {
      this.$emit('closemenu')
    },
    filterChange(newDescriptor, e) {
      let isFilter = !!newDescriptor
      if (
        newDescriptor &&
        newDescriptor.filters.length !== this.filteredColumnList.length
      ) {
        const index = this.filteredColumnList.indexOf(e.field)
        isFilter = !(index > -1) // if found in the list set to false
      } else if (
        newDescriptor &&
        newDescriptor.filters.length === this.filteredColumnList.length &&
        e.event.type === 'reset'
      ) {
        const index = this.filteredColumnList.indexOf(e.field)
        isFilter = index > -1
      }
      this.addFilteredColumnList({
        columnName: e.field,
        isFilter: isFilter
      })
      this.$emit('filterchange', newDescriptor, e)
    },
    sortChange(newDescriptor, e) {
      this.$emit('sortchange', newDescriptor, e)
    }
  }
}
</script>
