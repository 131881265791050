<template>
  <div class="dropdown-gird-container">
    <DropDownList
      :name="'gridDropDown'"
      :style="{ width: '100%' }"
      :data-items="optionList"
      :value="selectedValue"
      :text-field="textField"
      :value-field="valueField"
      :disabled="disabled"
      @change="handleChange"
      size="small"
      :fill-mode="'outline'"
      :filterable="false"
    >
    </DropDownList>
  </div>
</template>

<script>
import { DropDownList } from '@progress/kendo-vue-dropdowns'

export default {
  name: 'DMPGridDropDown',
  components: {
    DropDownList
  },
  props: {
    options: {
      type: Array,
      requried: true,
      default: () => []
    },
    textField: {
      type: String,
      default: 'text'
    },
    valueField: {
      type: String,
      default: 'value'
    },
    defaultSelect: {
      type: [String, Number, Boolean, Object],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    change: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    optionList() {
      return this.options
    },
    selectedValue() {
      return this.defaultSelect
    }
  },
  methods: {
    handleChange(event) {
      this.$emit('change', event.target.value)
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-gird-container {
  ::v-deep .k-picker-outline {
    border: none;
    &:focus-within {
      box-shadow: none;
    }
    .k-input-inner {
      .k-input-value-text {
        color: #000000;
        font-weight: bold;
      }
    }
    .k-input-button {
      color: #444444;
    }
    &:hover {
      background-color: #fff;
      color: black;
    }
  }
}
</style>
