<template>
  <div>
    <Grid
      :data-items="result"
      :pageable="false"
      :skip="skip"
      :take="take"
      :total="total"
      :columns="columns"
      @pagechange="pageChangeHandler"
      :sortable="true"
      :resizable="resizable"
      :filterable="false"
      :loader="loader"
    >
      <!-- Custom slot for rendering 'isOnHold' column with custom value -->
      <template v-slot:onHoldStatusTemplate="{ props }">
        <td
          class="k-table-td"
          role="gridcell"
          colspan="1"
        >
          <div class="isOnHoldContainer">
            {{ props.dataItem.isOnHold ? 'Yes' : 'No' }}
          </div>
        </td>
      </template>
    </Grid>
  </div>
</template>

<script>
import { Grid } from '@progress/kendo-vue-grid'

export default {
  components: {
    Grid
  },
  props: {
    tableGridData: {
      type: Array,
      required: true
    },
    resizable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loader: false,
      skip: 0,
      take: 10,
      columns: [
        { field: 'sgaId', title: 'SGA ID' },
        { field: 'legalEntityName', title: 'Legal Entity Name' },
        { field: 'requestId', title: 'Request Id', filterable: true },
        {
          field: 'isOnHold',
          title: 'On Hold',
          cell: 'onHoldStatusTemplate'
        },
        { field: 'onHoldReasonName', title: 'On Hold Reason' }
      ],
      filter: {}
    }
  },
  computed: {
    result() {
      let filteredItems = this.tableGridData

      if (this.filter.requestId) {
        filteredItems = filteredItems.filter((item) =>
          item.requestId
            .toLowerCase()
            .includes(this.filter.requestId.toLowerCase())
        )
      }

      if (this.filter.LegalEntityName) {
        filteredItems = filteredItems.filter((item) =>
          item.LegalEntityName.toLowerCase().includes(
            this.filter.LegalEntityName.toLowerCase()
          )
        )
      }

      return filteredItems
    },
    total() {
      return this.tableGridData ? this.tableGridData.length : 0
    }
  },
  methods: {
    pageChangeHandler(event) {
      this.loader = true
      setTimeout(() => {
        this.loader = false
        this.skip = event.page.skip
        this.take = event.page.take
      }, 300)
    }
  }
}
</script>

<style scoped>
/* Add some padding and styling to the grid */
.k-grid {
  border: 1px solid #ddd;
  max-height: 250px;
}

.isOnHoldContainer {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 0px;
  text-align: center;
}
.k-grid-header {
  background-color: #f5f5f5;
}

.k-grid-content {
  overflow-y: auto;
}

.k-grid td {
  padding: 10px;
}
</style>
