<template>
  <div class="toggleContainer">
    <div
      class="inner-container"
      :class="[isCollapsed ? 'collapsed hidden' : '']"
    >
      <div class="filter-container">
        <div class="text">Filters</div>
        <ResetAll from="sidePanel" @onReset="onReset" />
        <button class="toggleSidebar" @click="toggleSidebarCollapse">
          <img
            src="../../../src/assets/left-chevron.svg"
            alt="collapse-sidebar"
          />
        </button>
      </div>
    </div>
    <button
      class="toggleSidebar expandButton"
      @click="toggleSidebarCollapse"
      :class="[!isCollapsed ? '' : 'visible']"
    >
      <img src="../../../src/assets/filters.svg" alt="expand-sidebar" />
    </button>
  </div>
</template>

<script>
import ResetAll from '@/components/Common/ResetAll/ResetAll.vue'
import { mapState, mapActions } from 'vuex'
import { getCustomDateRange } from '../../util/util'
import { sapCompetitors } from '@/constant/data'
import moment from 'moment'

/** This component is used to render the header of the filters available in the sidepanel. It contains the *ResetAll* component and also contains the button used to toggle the expansion/contraction of the sidepanel  */
export default {
  name: 'FilterHeader',
  components: {
    ResetAll
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('filters', [
      'productList',
      'dataSourceList',
      'industries',
      'companies',
      'companyList',
      'ActiveVocTimePeriod',
      'ActiveVendorCsatTimePeriod',
      'ActiveVoeTimePeriod',
      'subscriptionRange',
      'subscriptionGranularity',
      'regionList',
      'employeeStatusList',
      'businessFuncList',
      'selectedBusinessFunc',
      'seniorityLevelList',
      'selectedSeniorityLevel',
      'csatVendorsDataSourceList',
      'selectedEsgCategories',
      'esgCategoriesListFlat',
      'vendorCsatReferenceArray'
    ]),
    ...mapState('user', ['userDetails']),
    ...mapState('common', ['isCollapsed', 'active_page']),
    ...mapState('csatdriver', ['expandedVendors']),
    ...mapState('pmtDashboardFilters', [
      'activePmtDashboardTimePeriod',
      'projectList'
    ]),
    parentReportId() {
      if (this.active_page === 'equityReports') {
        return 2
      } else if (this.active_page === 'fixedIncome') {
        return 3
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions('common', ['toggleSidebarCollapse']),
    ...mapActions('csatdriver', ['resetExpandedVendors']),
    ...mapActions('filters', [
      'updateSelectedCompanies',
      'updateSelectedSources',
      'updateSelectedIndustries',
      'updateSelectedProducts',
      'updateActiveVocTimePeriod',
      'updateActiveVendorCsatTimePeriod',
      'updateActiveVoeTimePeriod',
      'updateSelectedCompaniesVoe',
      'updateSelectedCountries',
      'updateSelectedEmployeeStatuses',
      'updateSelectedBusinessFunctions',
      'updateSelectedSeniorityLevels',
      'updateSelectedCsatVendors',
      'updateSortedCsatVendors',
      'getCumulativeSourceByClientId',
      'updateSelectedEsgCategories',
      'updateVendorCsatReferenceArray'
    ]),
    ...mapActions('reportsRepoFilters', ['resetReportsRepoFilters']),
    ...mapActions('esgControversies', ['resetESGFilters']),
    ...mapActions('pmtDashboardFilters', [
      'updateActivePMTDashboardTimePeriod',
      'updateSelectedProjectList'
    ]),
    /** This event handler resets the global filters back to their defaults
     * @public
     */
    onReset() {
      if (this.$route.meta.module === 'voe') {
        this.updateSelectedCompaniesVoe(this.companyList)
        this.updateSelectedEmployeeStatuses(this.employeeStatusList)
        this.updateSelectedBusinessFunctions(this.businessFuncList)
        this.updateSelectedSeniorityLevels(this.seniorityLevelList)
        this.updateActiveVoeTimePeriod(this.getDefaultActiveTime())
      } else if (this.$route.meta.module === 'esg-controversies') {
        this.resetESGFilters()
        this.updateSelectedEsgCategories(
          this.esgCategoriesListFlat.map((category) => category.id)
        )
      } else if (this.$route.meta.module === 'reports-repository') {
        this.resetReportsRepoFilters({ parentReportId: this.parentReportId })
      } else if (this.$route.meta.module === 'publication-monitoring-tool') {
        this.updateSelectedProjectList(this.projectList)
        this.updateActivePMTDashboardTimePeriod({
          range: [
            moment().startOf('month').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
          ]
        })
      } else {
        this.updateSelectedCompanies(this.companies)
        this.updateSelectedIndustries(this.industries)
        this.updateActiveVocTimePeriod(this.getDefaultActiveTime())
        if (this.active_page !== 'vendor-csat') {
          this.updateSelectedProducts(this.productList)
          const countries = []
          for (const obj of this.regionList) {
            for (const child of obj.children) {
              countries.push(child)
            }
          }
          this.updateSelectedCountries(countries)
          this.updateSelectedSources({
            sources: this.dataSourceList,
            page: this.active_page
          })
        } else {
          this.updateSelectedSources({
            sources: this.csatVendorsDataSourceList,
            page: this.active_page
          })
          this.updateActiveVendorCsatTimePeriod(this.getDefaultActiveTime())
          this.updateSelectedCsatVendors(this.vendorCsatReferenceArray)
          this.updateSortedCsatVendors(this.vendorCsatReferenceArray)
          this.updateVendorCsatReferenceArray(this.vendorCsatReferenceArray)
          this.resetExpandedVendors()
        }
      }
    },
    /** This method calculates the default Active Voc Time based on whether the time granularity selected is *'month'* or *'quarter'*
     * @public
     */
    getDefaultActiveTime() {
      const timeObject = {}
      timeObject.group_by = this.subscriptionGranularity ? 'month' : 'quarter' // this.ActiveVocTimePeriod.group_by
      if (this.$route.meta.module === 'voe') {
        timeObject.name = this.ActiveVoeTimePeriod.name
      } else if (
        this.$route.meta.module === 'voc' &&
        this.active_page === 'vendor-csat'
      ) {
        timeObject.name = this.ActiveVendorCsatTimePeriod.name
      } else if (this.$route.meta.module === 'publication-monitoring-tool') {
        timeObject.name = this.activePmtDashboardTimePeriod.name
      } else {
        timeObject.name = this.ActiveVocTimePeriod.name
      }
      // const eDate = moment(this.subscriptionRange.endDate, 'YYYY-MM-DD').isBefore(moment()) ? this.subscriptionRange.endDate : moment().format('YYYY-MM-DD')
      let range = null
      if (
        moment().subtract(11, 'month').startOf('month').format('YYYY-MM-DD') >=
          moment(this.subscriptionRange.startDate)
            .startOf('month')
            .format('YYYY-MM-DD') &&
        moment().startOf('month').format('YYYY-MM-DD') <=
          moment(this.subscriptionRange.endDate)
            .endOf('month')
            .format('YYYY-MM-DD')
      ) {
        // last 12 months of data
        range = getCustomDateRange('lastNMonths', 11)
      } else {
        // All Subscription time
        const eDate = moment(
          this.subscriptionRange.endDate,
          'YYYY-MM-DD'
        ).isBefore(moment())
          ? this.subscriptionRange.endDate
          : moment().endOf('month').format('YYYY-MM-DD')
        range = [this.subscriptionRange.startDate, eDate]
      }
      timeObject.range = range // [this.subscriptionRange.startDate, eDate]
      return timeObject
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-container {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  padding: 0px 0px 5px 0px;
  margin: 0 20px 10px 20px;
  border-bottom: 1px solid #ffffff4d; // white;
  align-items: center;
  .text {
    font-size: 14px;
    line-height: 1.21;
    text-transform: uppercase;
    color: #ffffff;
    font-family: Quicksand;
  }
  // .reset-button {
  //   color: #b5c2d3;
  //   font-family: Quicksand;
  //   font-size: 12px;
  // }
  // .divider {
  //   position: absolute;
  //   display: block;
  //   width: 100%;
  //   height: 1px;
  //   border-bottom: 1px solid white;
  // }
}
.collapsed {
  opacity: 0;
  transition: opacity 1s ease-out;
}
.toggleSidebar {
  margin-left: auto;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.expandButton {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.toggleContainer {
  display: flex;
  padding-top: 20px;
  width: 100%;
  position: relative;
  .inner-container {
    width: 100%;
  }
}
.hidden {
  display: none;
}

.visible {
  left: 24%;
  transition: 0.3s ease-out;
  opacity: 1;
  pointer-events: all;
  z-index: 5;
}
</style>
