import Vue from 'vue'
import Snackbar from '@/components/Snackbar'
import store from '../../store/index'
import Axios from 'axios'

const getDefaultState = () => {
  return {
    projectList: [],
    selectedProjectList: [],
    activePmtDashboardTimePeriod: null
  }
}

// defined outside to keep the global scope
let cancelToken

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    getProjectList({ commit }, reqData) {
      commit('LOADING', true)

      // Check if there are any previous pending requests
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('Request canceled due to new request.')
      }
      // Save the cancel token for the current request
      cancelToken = Axios.CancelToken.source()

      Vue.prototype.$http
        .get(
          '/pmt/dashboard/project-list',
          reqData,
          { cancelToken: cancelToken.token } // Pass the cancel token to the current request
        )
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              let finalData = response.data.data.map((el) => {
                return {
                  id: el.project_id,
                  name: el.project_name
                }
              })
              commit('SET_PMT_DASHBOARD_PROJECT_LIST_DATA', finalData)
            }
          }
          if (response.data.status === 204) {
            commit('SET_PMT_DASHBOARD_PROJECT_LIST_DATA', [])
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          if (e.message === 'Request canceled due to new request.') {
            commit('LOADING', true)
          } else {
            commit('LOADING', false)
          }
        })
    },
    updateSelectedProjectList({ commit }, reqData) {
      commit('LOADING', true)
      commit('SET_PMT_DASHBOARD_SELECTED_PROJECT_LIST_DATA', reqData)
    },
    updateActivePMTDashboardTimePeriod({ commit }, timePeriod) {
      localStorage.setItem(
        'PMT_DASHBOARD_TIME_PERIOD',
        JSON.stringify(timePeriod)
      )
      commit('SET_ACTIVE_PMT_DASHBOARD_TIME_PERIOD', timePeriod)
    }
  },
  mutations: {
    LOADING(state, payload) {
      store.state.filters.loading = payload
    },
    SET_PMT_DASHBOARD_PROJECT_LIST_DATA(state, pmtDashboardProjectList) {
      state.projectList = pmtDashboardProjectList
    },
    SET_PMT_DASHBOARD_SELECTED_PROJECT_LIST_DATA(
      state,
      pmtDashboardSelectedProjectList
    ) {
      state.selectedProjectList = pmtDashboardSelectedProjectList
    },
    SET_ACTIVE_PMT_DASHBOARD_TIME_PERIOD(state, timePeriod) {
      state.activePmtDashboardTimePeriod = timePeriod
    }
  }
}
