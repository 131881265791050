<template>
  <div class="form-group upload-container">
    <p>{{ label }}<sup v-if="required" class="required">*</sup></p>
    <b-upload
      :disabled="disabled"
      accept=".pdf"
      v-model="localFile"
      :class="{ 'is-invalid': submitted && $v.localImage.$error }"
    >
      <b-icon v-if="!image" icon="upload" size="is-medium"> </b-icon>
      <img v-else :src="image" />
    </b-upload>
    <div v-if="!$v.localImage.required && required && submitted" class="required-error">
      {{ label }} is required.
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

/** This component contains code that allows for users to upload files and images to the iNava platform */
export default {
  name: "Upload",
  props: {
    /** This prop contains the text for the label of the upload button */
    label: String,
    /** This prop contains the link for the image source */
    image: String,
    /** This prop defines the behavior for when a change is detected in the input */
    onChangeFile: Function,
    /** This prop indicates whether or not the upload option is required or not */
    required: Boolean,
    /** This prop indicates whether a form submission has been attempted or not */
    submitted: Boolean,
    /** This prop indicates whether the upload option is available or not */
    disabled: Boolean,
  },
  data() {
    return {
      localFile: null,
      localImage: null,
    };
  },
  validations() {
    return { localImage: { required: this.required ? required : false } };
  },
  watch: {
    localFile() {
      if (this.localFile != null) {
        const reader = new FileReader();
        reader.readAsDataURL(this.localFile);
        reader.onload = (e) => {
          this.localImage = e.target.result;
        };
      }
    },
    image() {
      this.localImage = this.image;
      if (this.localImage != null) {
        this.localFile = null;
      }
    },
    localImage() {
      this.$v.$touch();
      this.$emit(
        "onChangeFile",
        {
          file: this.localImage,
          name: this.localFile ? this.localFile.name : null,
        },
        this.$v.localImage.$invalid
      );
    },
  },
  methods: {
    removeFile() {
      this.localFile = null;
      this.localImage = null;
      this.$emit("onChangeFile", { file: null, name: null }, true);
    },
  },
  mounted() {
    this.$v.$touch();
    this.$emit(
      "onChangeFile",
      {
        file: this.localImage,
        name: this.localFile ? this.localFile.name : null,
      },
      this.$v.localImage.$invalid
    );
  },
};
</script>

<style lang="scss" scoped>
.upload-container {
  .required-error {
    color: red;
    position: absolute;
    font-size: 12px;
  }
  p {
    display: flex;
    margin: 0px;
    display: flex;
    margin: 0px;
    color: #7f8fa4;
    font-size: 12px;
    font-family: Quicksand;
    padding: 0px 20px 0px 0px;
    display: flex;
    line-height: 20px;
  }
  .required {
    color: red;
  }
  .upload {
    cursor: pointer;
    width: 200px;
    height: 60px;
    align-items: center;
    justify-content: center;
    outline: none;
    border-radius: 4px;
    border: var(--tertiary-border-color);
    color: var(--secondary-text-color) !important;
    background: transparent !important;
    box-shadow: none;
    img {
      width: 200px;
      height: 60px;
      object-fit: contain;
      padding: 5px;
    }
  }
}
</style>
