<template>
  <div class="datagrid-container">
    <div class="local-loader-container" v-show="loader">
      <div class="inava-loader"></div>
    </div>
    <div>
      <div class="reports-repo-pagination">
        <pagination-vue
          :limit="limit"
          :totalCount="totalCount"
          :currentPage="currentPage"
          :row-count="gridData.length"
          @onChangePage="onPageChange"
          @onChangeLimit="changeLimit"
        />
      </div>
      <Grid
        v-show="!loader"
        ref="grid"
        :style="'height: calc(100vh);'"
        :data-items="gridData"
        :resizable="true"
        :reorderable="true"
        @columnreorder="columnReorder"
        :selectable="true"
        :selected-field="selectedField"
        @selectionchange="onSelectionChange"
        :sortable="false"
        :sort="sort"
        :filter="filter"
        :take="limit"
        :skip="offset"
        :columns="columns"
        :column-menu="columnMenu"
        :total="totalCount"
        :loader="displayInavaLoader()"
        class="grid"
        :no-records="true"
        @pagechange="pageChangeHandler"
        @datastatechange="dataStateChange"
      >
        <!-- Pagination Component -->

        <GridToolbar class="grid-toolbar">
          <span class="k-textbox k-grid-search k-display-flex">
            <k-input
              :style="{ width: '230px' }"
              :placeholder="'Search in all columns'"
              :value="searchContent"
              :inputSuffix="'suffix'"
              @input="onFilter"
            >
              <template v-slot:suffix>
                <span class="k-input-icon k-icon k-i-search"></span>
              </template>
            </k-input>
          </span>
          <span class="right-btn-parent">
            <b-button
              class="export-btn"
              :disabled="loading"
              @click="downloadCSV"
              :loading="downloading"
            >
              <b-icon icon="export" size="is-small"> </b-icon>
              &nbsp;Export
            </b-button>
            <b-button
              class="export-btn"
              :disabled="loading || !selectedS3Urls.length"
              @click="downloadZip"
              :loading="downloadingZip"
            >
              <b-icon icon="download" size="is-small"> </b-icon>
              &nbsp;Download
            </b-button>
            <b-button class="add-btn" @click="openModal">
              <div :style="{ color: 'white' }">
                <b-icon icon="plus" size="is-small"> </b-icon>&nbsp;Add Document
              </div>
            </b-button>
            <modal-vue @onChange="onChangeModal" :modal="modal">
              <div class="add-document-modal">
                <p class="modal-header">Add New Document</p>
                <div class="modal-data">
                  <AddDocuments
                    :onChangeModal="onChangeModal"
                    ref="addDocumentsRef"
                  />
                </div>
              </div>
            </modal-vue>
          </span>
        </GridToolbar>
        <template v-slot:customFilterTemplate="{ props }">
          <custom
            :column="props.column"
            :filterable="props.filterable"
            :filter="localFilterList"
            :sortable="props.sortable"
            :sort="props.sort"
            :columns="columns"
            :defaultColumn="defaultColumn"
            @sortchange="(e) => props.onSortchange(e)"
            @filterchange="(e) => props.onFilterchange(e)"
            @closemenu="(e) => props.onClosemenu(e)"
            @contentfocus="(e) => props.onContentfocus(e)"
            @columnssubmit="onColumnsSubmit"
          />
        </template>
        <template v-slot:project="{ props }">
          <div
            v-if="props.dataItem.projectId"
            class="k-table-td source-text-template default-color"
          >
            <span>{{ props.dataItem.projectId }} </span>
          </div>
          <div v-else class="k-table-td source-text-template">
            <span> NA </span>
          </div>
        </template>
        <template v-slot:project_name="{ props }">
          <div
            v-if="props.dataItem.projectName"
            class="k-table-td source-text-template default-color"
          >
            <span>{{ props.dataItem.projectName }} </span>
          </div>
          <div v-else class="k-table-td source-text-template">
            <span> NA </span>
          </div>
        </template>
        <template v-slot:company_name="{ props }">
          <div
            v-if="props.dataItem.company_name"
            class="k-table-td source-text-template default-color documents-column"
          >
            <span :title="props.dataItem.company_name"
              >{{ props.dataItem.company_name }}
            </span>
          </div>
          <div v-else class="k-table-td source-text-template default-color">
            <span> NA </span>
          </div>
        </template>
        <template v-slot:pcid="{ props }">
          <div
            v-if="props.dataItem.pcid"
            class="k-table-td source-text-template default-color"
          >
            <span>{{ props.dataItem.pcid }} </span>
          </div>
          <div v-else class="k-table-td source-text-template default-color">
            <span> NA </span>
          </div>
        </template>
        <template v-slot:title="{ props }">
          <div
            v-if="props.dataItem.title"
            class="k-table-td source-text-template default-color documents-column"
          >
            <span :title="props.dataItem.title"
              >{{ props.dataItem.title }}
            </span>
          </div>
          <div v-else class="k-table-td source-text-template default-color">
            <span> NA </span>
          </div>
        </template>
        <template v-slot:doc_url="{ props }">
          <div
            v-if="props.dataItem.doc_url"
            class="k-table-td source-text-template default-color documents-column"
          >
            <span :title="props.dataItem.doc_url"
              >{{ props.dataItem.doc_url }}
            </span>
          </div>
          <div v-else class="k-table-td source-text-template default-color">
            <span> NA </span>
          </div>
        </template>
        <template v-slot:doc_s3_url="{ props }">
          <div
            v-if="props.dataItem.doc_s3_url"
            class="k-table-td source-text-template default-color documents-column"
          >
            <span :title="props.dataItem.doc_s3_url"
              >{{ props.dataItem.doc_s3_url }}
            </span>
          </div>
          <div v-else class="k-table-td source-text-template default-color">
            <span> NA </span>
          </div>
        </template>
        <template v-slot:language="{ props }">
          <div
            v-if="props.dataItem.language"
            class="k-table-td source-text-template default-color documents-column"
          >
            <span :title="props.dataItem.language"
              >{{ props.dataItem.language }}
            </span>
          </div>
          <div v-else class="k-table-td source-text-template default-color">
            <span> NA </span>
          </div>
        </template>
        <template v-slot:pub_date="{ props }">
          <div
            v-if="props.dataItem.pub_date"
            class="k-table-td source-text-template default-color documents-column"
          >
            <span> {{ dateString(props.dataItem.pub_date) }}</span>
          </div>
          <div v-else class="k-table-td source-text-template default-color">
            <span> NA </span>
          </div>
        </template>
        <template v-slot:created_at="{ props }">
          <div
            v-if="props.dataItem.created_at"
            class="k-table-td source-text-template default-color documents-column"
          >
            <span> {{ dateString(props.dataItem.created_at) }}</span>
          </div>
          <div v-else class="k-table-td source-text-template default-color">
            <span> NA </span>
          </div>
        </template>
        <template v-slot:doc_type="{ props }">
          <div class="k-table-td source-text-template default-color">
            <DropDownList
              class="custom-dropdown dropdown-list"
              :data-items="docTypeValues"
              :value="computedDocType(props.dataItem)"
              @change="(event) => handleChangeDocType(event, props)"
            >
            </DropDownList>
          </div>
        </template>
        <template v-slot:action="{ props }">
          <div class="k-table-td default-color url-content action-column">
            <a
              :href="props.dataItem.doc_url"
              target="_blank"
              class="action-link view-column"
            >
              View
            </a>
            <a
              @click="downloadPDF(props.dataItem.doc_s3_url)"
              class="download-column"
            >
              Download
            </a>
          </div>
        </template>
        <template v-slot:loader>
          <div class="k-loader-container k-loader-container-md k-loader-top">
            <div
              class="k-loader-container-overlay"
              :class="[
                user_preference_mode === 'light'
                  ? 'k-overlay-light'
                  : 'k-overlay-dark'
              ]"
            />
            <div class="k-loader-container-inner">
              <div class="inava-loader"></div>
            </div>
          </div>
        </template>
      </Grid>
    </div>
  </div>
</template>

<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid'
import { DropDownList } from '@progress/kendo-vue-dropdowns'
import PaginationVue from '@/components/WordCloud/Pagination.vue'
import { Input } from '@progress/kendo-vue-inputs'
import ColumnMenu from './ColumnMenu'
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
import Snackbar from '@/components/Snackbar'
import debounce from 'lodash/debounce'
import ModalVue from '../../views/Admin/components/Modal.vue'
import AddDocuments from './AddDocuments.vue'

export default {
  name: 'App',
  components: {
    Grid,
    GridToolbar,
    PaginationVue,
    DropDownList,
    'k-input': Input,
    custom: ColumnMenu,
    ModalVue,
    AddDocuments
  },
  props: {
    theme: String
  },
  data() {
    return {
      modal: false,
      isCardModalActive: null,
      docTypeValues: [],
      docTypeValueWithId: [],
      downloading: false,
      downloadingZip: false,
      columnMenu: false,
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [10, 50, 100, 200],
        previousNext: true
      },
      gridData: [],
      sort: [],
      filter: null,
      loader: true,
      selectedField: 'selected',
      readMore: {},
      defaultColumn: [
        'selected',
        'project',
        'poject_name',
        'company_name',
        'pcid',
        'file_name',
        'doc_url',
        'doc_s3_url',
        'language',
        'pub_date',
        'created_at',
        'doc_type',
        'action'
      ],
      columns: [
        { field: 'selected', width: '45px', title: 'Selection' },
        {
          field: 'project',
          title: 'Project',
          className: 'default-color',
          cell: 'project',
          width: '100px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'project_name',
          title: 'Project Name',
          className: 'default-color',
          cell: 'project_name',
          width: '130px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'company_name',
          title: 'Company Name',
          className: 'default-color',
          cell: 'company_name',
          width: '170px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'pcid',
          title: 'PCID',
          className: 'default-color',
          cell: 'pcid',
          width: '100px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'title',
          title: 'Title',
          className: 'default-color',
          cell: 'title',
          width: '130px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'doc_url',
          title: 'Doc URL',
          className: 'default-color',
          cell: 'doc_url',
          width: '140px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'doc_s3_url',
          title: 'Doc S3 URL',
          className: 'default-color',
          cell: 'doc_s3_url',
          width: '130px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'language',
          title: 'Language',
          className: 'default-color',
          cell: 'language',
          width: '130px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'pub_date',
          title: 'Pub Date',
          className: 'default-color',
          cell: 'pub_date',
          width: '110px',
          columnMenu: 'customFilterTemplate',
          hidden: false,
          filter: 'date'
        },
        {
          field: 'created_at',
          title: 'Created At',
          className: 'default-color',
          cell: 'created_at',
          width: '110px',
          columnMenu: 'customFilterTemplate',
          hidden: false,
          filter: 'date'
        },
        // We will need it in future

        // {
        //   field: "country",
        //   title: "Country",
        //   className: "default-color",
        //   cell: "country",
        //   width: "100px",
        //   columnMenu: "customFilterTemplate",
        //   // disabled: true,
        //   hidden: false,
        // },
        // {
        //   field: "sector",
        //   title: "Sector",
        //   className: "default-color",
        //   cell: "sector",
        //   width: "100px",
        //   columnMenu: "customFilterTemplate",
        //   // disabled: true,
        //   hidden: false,
        // },
        // {
        //   field: "sub_sector",
        //   title: "Sub-sector",
        //   className: "default-color",
        //   cell: "sub_sector",
        //   width: "140px",
        //   columnMenu: "customFilterTemplate",
        //   disabled: true,
        //   hidden: false,
        // },
        {
          field: 'doc_type',
          title: 'Doc Type',
          className: 'default-color',
          cell: 'doc_type',
          width: '130px',
          columnMenu: 'customFilterTemplate',
          disabled: true,
          hidden: false
        },
        {
          field: 'action',
          title: 'Action',
          className: 'default-color',
          cell: 'action',
          width: '180px',
          disabled: true,
          hidden: false
        }
      ],
      selectedS3Urls: [],
      debouncedGetTableData: debounce(this.getTableData, 500),
      currentPage: 1
    }
  },
  computed: {
    ...mapState('pmtDocuments', [
      'pmtDocumentsData',
      'pmtDocumentTypeData',
      'pmtDocumentsFilter',
      'filteredColumnList',
      'isMultiSearch',
      'companyFilter',
      'searchCompanyDocuments'
    ]),
    ...mapState('filters', ['loading']),
    ...mapState('common', ['user_preference_mode']),
    totalCount() {
      return this.pmtDocumentsFilter.totalCount
    },
    offset() {
      return this.pmtDocumentsFilter.offset
    },
    limit() {
      return this.pmtDocumentsFilter.limit
    },
    searchContent() {
      return this.pmtDocumentsFilter.searchContent
    },
    filterList() {
      return this.pmtDocumentsFilter.filterOptions
    },
    localFilterList() {
      if (this.pmtDocumentsFilter.filterOptions) {
        return {
          filters: this.pmtDocumentsFilter.filterOptions
            ? this.pmtDocumentsFilter.filterOptions
            : null,
          logic: 'and'
        }
      } else {
        return null
      }
    }
  },
  watch: {
    pmtDocumentsData(newDocumentsData) {
      this.gridData = newDocumentsData
    },
    pmtDocumentTypeData(newDocumentTypes) {
      this.docTypeValueWithId = newDocumentTypes
      this.docTypeValues = newDocumentTypes.map((el) => el.type)
    },
    searchContent() {
      this.debouncedGetTableData(
        this.offset,
        this.limit,
        this.searchContent,
        this.searchCompanyDocuments
      )
    },
    filteredColumnList() {
      this.columns = this.getColumnWithFilteredApplied()
    }
  },
  async created() {
    const columnsState = JSON.parse(localStorage.getItem('documentColumns'))
    if (columnsState) {
      this.onColumnsSubmit(columnsState)
    }
    this.getDocumentTypeList()
    this.gridData = this.pmtDocumentsData
    this.loader = true
    const isLinkPresent = document.getElementsByTagName('link')
    for (let i = 0; i < isLinkPresent.length; i++) {
      const linkHref = isLinkPresent[i].getAttribute('href')
      if (linkHref === '/kendoLight.css' || linkHref === '/kendoDark.css') {
        isLinkPresent[i].remove()
      }
    }

    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    if (this.theme === 'light') {
      link.href = process.env.VUE_APP_KENDO_LIGHT_CSS
    } else if (this.theme === 'dark') {
      link.href = process.env.VUE_APP_KENDO_DARK_CSS
    }
    link.onload = () => {
      this.loader = false
      const localFilteredColumnList = JSON.parse(
        localStorage.getItem('DOCUMENTS_FILTERED_COLUMNS')
      )

      if (localFilteredColumnList && localFilteredColumnList.length > 0) {
        this.updateFilteredColumnList(localFilteredColumnList)
      }
    }
    document.head.appendChild(link)
  },
  methods: {
    ...mapActions('pmtDocuments', [
      'getPmtDocumentsList',
      'getDocumentTypeList',
      'updatePmtDocumentsFilter',
      'downloadPmtDocumentsData',
      'updateFilteredColumnList',
      'updateSearchCompanyDocuments',
      'downloadDocument',
      'handleDocTypeAPI',
      'generateZip'
    ]),
    onChangeModal(value) {
      this.modal = value
    },
    openModal() {
      this.modal = true
    },
    columnReorder: function (options) {
      this.columns = options.columns
    },
    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField]
      let s3Urls = []
      event.dataItems.map((el) => {
        if (el.selected) {
          s3Urls.push(el.doc_s3_url)
        }
      })
      this.selectedS3Urls = s3Urls
      this.gridData = event.dataItems
    },
    displayInavaLoader() {
      if (this.loading) return 'loader'
      return false
    },
    computedDocType(dataItem) {
      return dataItem.doc_type || dataItem.doc_type_names || 'NA'
    },
    handleChangeDocType(event, props) {
      this.currentPage = 1
      const docTypeObject =
        this.docTypeValueWithId.find((item) => item.type === event.value) ||
        null

      this.handleDocTypeAPI({
        docType: docTypeObject,
        tdId: props.dataItem.tdId
      })

      this.gridData[props.dataIndex - 1].doc_type = event.value
    },

    downloadPDF(url) {
      const parsedUrl = new URL(url)
      const path = parsedUrl.pathname
      const formattedPath = path.substring(1)
      this.downloadDocument({ key: formattedPath })
    },
    async onFilter(event) {
      const inputValue = event.value
      if (inputValue !== null && inputValue.trim() === '') {
        this.updatePmtDocumentsFilter({
          searchContent: null,
          limit: this.limit,
          offset: 0,
          filterOptions: this.filterList
        })
      } else {
        this.updatePmtDocumentsFilter({
          searchContent: inputValue,
          limit: this.limit,
          offset: 0,
          filterOptions: this.filterList
        })
      }
    },
    createAppState(dataState) {
      this.updatePmtDocumentsFilter({
        searchContent: this.searchContent,
        limit: dataState.take,
        offset: dataState.skip,
        filterOptions: this.filterList
      })
      this.sort = dataState.sort
      this.filter = dataState.filter
      this.gridData = this.pmtDocumentsData
    },
    dataStateChange(event) {
      if (event?.data?.filter) {
        const localFilterList = event?.data?.filter?.filters
        this.updatePmtDocumentsFilter({
          ...this.pmtDocumentsFilter,
          offset: 0,
          filterOptions: localFilterList
        })
      } else {
        this.updatePmtDocumentsFilter({
          ...this.pmtDocumentsFilter,
          offset: 0,
          filterOptions: null
        })
      }
      this.createAppState(event.data)
      this.filterCompanyData()
    },

    changeLimit(event) {
      this.currentPage = 1
      this.updatePmtDocumentsFilter({
        searchContent: this.pmtDocumentsFilter.searchContent,
        limit: event,
        offset: 0,
        filterOptions: this.filterList
      })
      this.getTableData(
        this.offset,
        this.limit,
        this.searchContent,
        this.searchCompanyDocuments
      )
    },

    filterCompanyData() {
      if (this.companyFilter && this.companyFilter.length > 0) {
        const companyFilterArr = []
        const finalComapnyArr = []
        const companyObj = {}
        companyObj.logic = 'or'
        this.companyFilter.forEach((ele) => {
          const obj = {}
          obj.operator = 'contains'
          obj.field = 'company_name'
          obj.value = ele.name
          companyFilterArr.push(obj)
        })
        companyObj.filters = companyFilterArr
        finalComapnyArr.push(companyObj)
        const filterList = this.filterList
        if (filterList && filterList.length > 0) {
          const companyNewArray = [...finalComapnyArr, ...filterList]
          this.updateSearchCompanyDocuments(companyNewArray)
          this.getTableData(
            this.offset,
            this.limit,
            this.searchContent,
            companyNewArray
          )
        } else {
          const companyNewArray = [...finalComapnyArr]
          this.updateSearchCompanyDocuments(companyNewArray)
          this.getTableData(
            this.offset,
            this.limit,
            this.searchContent,
            companyNewArray
          )
        }
      } else {
        this.updateSearchCompanyDocuments(this.filterList)
        this.getTableData(
          this.offset,
          this.limit,
          this.searchContent,
          this.filterList
        )
      }
    },
    onPageChange(pageNumber) {
      this.selectedS3Urls = []
      const offset = this.limit * pageNumber - this.limit
      this.updatePmtDocumentsFilter({
        searchContent: this.pmtDocumentsFilter.searchContent,
        limit: this.pmtDocumentsFilter.limit,
        offset: offset,
        filterOptions: this.filterList
      })
      this.currentPage = pageNumber
      this.getTableData(
        this.offset,
        this.limit,
        this.searchContent,
        this.searchCompanyDocuments
      )
    },
    async pageChangeHandler(event) {
      this.updatePmtDocumentsFilter({
        searchContent: this.pmtDocumentsFilter.searchContent,
        limit: event.page.take,
        offset: event.page.skip,
        filterOptions: this.filterList
      })
      this.pageSizeValue = event.event.value
      this.getTableData(
        this.offset,
        this.limit,
        this.searchContent,
        this.searchCompanyDocuments
      )
    },
    getParams(offset = 0, limit = 0, searchContent = null, filterList = null) {
      if (searchContent) {
        searchContent = searchContent.trim()
      }
      let localFilterList = null
      if (filterList) {
        localFilterList = filterList.map((filterList) => {
          const logic = filterList.logic
          filterList = filterList.filters.map((filter) => {
            if (
              filter.field === 'reportingPeriod' ||
              filter.field === 'pub_date' ||
              filter.field === 'created_at' ||
              filter.field === 'createdAt' ||
              filter.field === 'allSrcPubDate'
            ) {
              return {
                ...filter,
                value: moment(moment(filter.value)).format('YYYY-MM-DD')
              }
            } else if (filter.field === 'calcAmtCombined') {
              return {
                ...filter,
                field: 'calcAmt',
                value: filter.value.trim()
              }
            } else if (filter.field === 'origAmtCombined') {
              return {
                ...filter,
                field: 'origAmt',
                value: filter.value.trim()
              }
            }
            return {
              ...filter,
              value:
                typeof filter.value === 'string'
                  ? filter.value.trim()
                  : filter.value
            }
          })
          return { filters: filterList, logic: logic }
        })
      }
      const reqData = {
        searchContent: searchContent || null,
        offset: offset,
        limit: limit,
        filterOptions: localFilterList
      }
      return reqData
    },
    async getTableData(
      offset = 0,
      limit = 10,
      searchContent = null,
      filterList = null
    ) {
      const reqData = this.getParams(offset, limit, searchContent, filterList)
      await this.getPmtDocumentsList(reqData)
    },
    showMore(id) {
      this.$set(this.readMore, id, true)
    },
    showLess(id) {
      this.$set(this.readMore, id, false)
    },
    dateString(date) {
      if (date) {
        const localDate = moment(moment(date.split('T')[0])).format(
          'MMMM Do YYYY'
        )
        return localDate
      }
      return 'NA'
    },
    dateStringReportingPeriod(date) {
      if (date) {
        const localDate = moment.utc(date).local().format('MMMM Do YYYY')
        return localDate
      }
      return 'NA'
    },
    onColumnsSubmit(columnsState) {
      this.columns = columnsState
    },
    getColumnWithFilteredApplied() {
      const allColumns = this.columns.map((col) => {
        let filterIndicator = ''
        const index = this.filteredColumnList.indexOf(col.field)
        if (index > -1) {
          filterIndicator = 'filter-indicator'
        }
        return {
          ...col,
          headerClassName: filterIndicator
        }
      })
      return allColumns
    },
    downloadCSV() {
      const messageDuration = 8000
      if (this.totalCount > 1e5) {
        Snackbar({
          message:
            'You have selected more than 100k records, please select <= 100K records.',
          type: 'is-success',
          duration: messageDuration
        })
        return
      }
      if (this.totalCount === 0) {
        Snackbar({
          message: 'Please select the record(s) for the export.',
          type: 'is-success',
          duration: messageDuration
        })
        return
      }
      this.downloading = true
      const params = this.getParams(
        0,
        10,
        this.searchContent,
        this.searchCompanyDocuments
      )

      const reqData = {
        searchContent: params.searchContent,
        filterOptions: params.filterOptions,
        visibleData: this.columns
          .filter((c) => c.hidden === false)
          .map((c) => c.field)
      }
      this.downloadPmtDocumentsData(reqData)
        .then((response) => {
          if (response.data.status && response.data.status !== 200) {
            Snackbar({
              message:
                response.data.info ||
                'Your file download request is a failure. Please try again.',
              type: 'is-success',
              duration: messageDuration
            })
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              `documents-${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`
            )
            document.body.appendChild(link)
            link.click()
            Snackbar({
              message:
                'Your file download request is a success. Please check your system folder for the file.',
              type: 'is-success',
              duration: messageDuration
            })
          }
          this.downloading = false
        })
        .catch((error) => {
          if (error) {
            this.downloading = false
            Snackbar({
              message:
                'Your file download request is a failure. Please try again.',
              type: 'is-danger',
              duration: messageDuration
            })
          }
        })
    },
    downloadZip() {
      this.downloadingZip = true
      this.generateZip(this.selectedS3Urls)
        .then((response) => {
          if (response.data.status && response.data.status !== 200) {
            Snackbar({
              message:
                response.data.info ||
                'Your file download request is a failure. Please try again.',
              type: 'is-success',
              duration: messageDuration
            })
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/zip' })
            )
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              `zip-of-pdf-${moment().format('YYYY-MM-DD HH:mm:ss')}.zip`
            )
            document.body.appendChild(link)
            link.click()
            Snackbar({
              message:
                'Your file download request is a success. Please check your system folder for the file.',
              type: 'is-success',
              duration: messageDuration
            })
          }
          this.downloadingZip = false
        })
        .catch((error) => {
          if (error) {
            this.downloadingZip = false
            Snackbar({
              message:
                'Your file download request is a failure. Please try again.',
              type: 'is-danger',
              duration: messageDuration
            })
          }
        })
    },
    triggerHandleSubmit() {
      this.$refs.addDocumentsRef.handleSubmit()
    }
  }
}
</script>

<style lang="scss">
[module='publication-monitoring-tool'] {
  .custom-dropdown {
    background-color: white;
    border: 1px solid #3c90f5;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .custom-dropdown:hover,
  .custom-dropdown:focus {
    background-color: white;
    border-color: #3c90f5;
  }

  .custom-dropdown:active {
    border: 1px solid #3c90f5;
  }

  .k-grid-aria-root {
    min-height: 250px;
  }
  .k-pager-numbers .k-link,
  .k-pager-numbers .k-link:link {
    background-color: blue;
    color: blue;
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
  }

  .k-grid {
    z-index: 9;
  }
  .k-grid-header {
    background-color: #f2f2f2;
  }
  .right-btn-parent {
    margin-left: auto;
    gap: 5px;
    button {
      height: 30px;
      font-size: 14px;
      color: #151515;
    }
  }
  .grid-toolbar {
    padding: 10px;
    background-color: #dfdfdf;
  }

  .k-checkbox {
    vertical-align: middle;
  }

  .k-i-more-vertical::before {
    content: '\e129';
  }

  .k-column-title {
    color: #444444;
  }

  .k-input-suffix {
    color: #cccccc;
  }

  .default-color {
    color: #1e1e1e;
  }
  .url-content {
    word-wrap: break-word;
  }
  .add-btn {
    background-color: #535eeb;
    color: white;
  }
  .modal-data {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    padding: 0.2rem;
    p {
      font-size: 12px;
    }
  }

  .modal-header {
    position: sticky;
    padding-top: 0.5rem;
    padding-left: 1rem;
    font-size: 18px;
    font-weight: 800;
    text-align: left;
    z-index: 10;
  }

  .add-document-modal {
    padding: 5px;
    height: 510px;
    width: 700px;
    max-height: 515px;
    background: white;
    display: flex;
    flex-direction: column;

    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
}

.k-header .k-checkbox {
  display: none;
}
.k-table {
  width: 100% !important;
}
.actions {
  position: sticky;
  z-index: 10;
  margin-right: 10px;
  text-align: end;
  .button {
    height: 32px;
    border: none;
    font-size: 14px;
    width: 75px;
  }
  .button:nth-child(1) {
    margin-left: 5px;
    background-color: transparent;
    color: var(--secondary-text-color);
    border: 1px solid var(--primary-border-color);
    &:hover {
      border: 2px solid var(--primary-border-color);
      transition: border 0.3s ease-out;
    }
    &:active {
      background-color: #7f8fa466;
      transition: background-color 0.3s ease-out;
    }
  }
  .button:nth-child(2) {
    margin-left: 5px;
    background-color: var(--rr-download-file-background);
    color: white;
    &:active {
      background-color: var(--rr-download-file-background);
      transition: background-color 0.3s ease-out;
    }
  }
}

[theme='dark'][module='publication-monitoring-tool'] {
  .k-i-more-vertical::before {
    content: '\e129';
  }

  .k-column-title {
    color: #b2b2b2;
  }

  .default-color {
    color: #e7e7e7;
  }

  .grid-toolbar {
    padding: 12px;
    background-color: #151515;
  }

  .k-grid .k-grid-header {
    border-bottom-width: 1px;
    background-color: #151515;
  }

  .k-grid-header,
  .k-grid-header-wrap,
  .k-grouping-header,
  .k-grid .k-table-th,
  .k-grid td,
  .k-grid .k-table-td,
  .k-grid-footer,
  .k-grid-footer-wrap,
  .k-grid-content-locked,
  .k-grid-footer-locked,
  .k-grid-header-locked,
  .k-filter-row > .k-table-th,
  .k-filter-row > td,
  .k-filter-row > .k-table-td {
    border-color: rgb(255 255 255 / 8%);
  }
}
.reports-repo-pagination {
  z-index: 10;
  display: flex;
  top: 50px;
  padding: 10px 20px;
  background-color: var(--rr-pagination-background);
  align-items: center;
  font-size: 13px;
  min-height: 51px;
  .pagination-main {
    width: 100%;
    background-color: transparent;
    & > * {
      background: transparent;
      border: none;
    }
    .pagination-block {
      padding: 0;
      border: none;
      background: transparent;
    }
    .pagination-filter-inside {
      top: 100%;
      z-index: 100;
      height: fit-content;
    }
  }
  .filters {
    color: var(--inava-gray);
    color: var(--rr-applied-filters-color);
    margin-right: 25px;
    cursor: pointer;
  }
  .pagination-status {
    margin-right: 25px;
    margin-left: 15px;
    color: var(--rr-pagination-status-color);
    font-weight: 600;
  }
  .next-page {
    margin-right: 25px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: var(--rr-next-page-color);
    font-weight: 600;
  }
  .sort {
    display: flex;
    margin-left: auto;
    align-items: center;
    span {
      margin-right: 10px;
    }
    & > div {
      display: flex;
      .relevance,
      .date {
        border: 1px solid var(--inava-gray);
        &:hover {
          border: 1px solid var(--inava-primary);
          background-color: var(--inava-primary);
          color: white;
          cursor: pointer;
        }
      }
      .relevance {
        padding: 7px;
      }
      .date {
        padding: 7px;
      }
      .active {
        border: 1px solid var(--inava-primary);
        background-color: var(--inava-primary);
        color: white;
        cursor: pointer;
      }
    }
  }
  .view-menu {
    display: flex;
    align-items: center;
    margin-left: 50px;
    background-color: var(--background-color);
    .detailed-list-view,
    .list-view {
      padding: 10px;
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        min-width: 20px;
        height: 20px;
      }
    }
    .active {
      background-color: var(--inava-primary);
      svg > * {
        fill: white;
      }
    }
  }
}
.k-table-tbody {
  .k-grid-norecords {
    text-align: unset;
  }
}

.article-title {
  color: #535eeb;
}

.goto-source-link {
  background: none;
  border: none;
  padding: 0;
  color: var(--inava-primary);

  a {
    display: inline-flex;
    align-items: center;
    gap: 2px;
    color: var(--inava-pink);
  }

  svg {
    width: 15px;
    height: 15px;
  }
  svg > *,
  path {
    stroke: var(--inava-pink);
  }

  &:hover {
    cursor: pointer;
  }
}

.source-text-template {
  .read-more {
    color: var(--inava-pink);
  }
}

.filter-indicator {
  .k-grid-column-menu {
    background-color: #ff6358;
  }
}

.documents-column {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  max-width: 200px !important;
}

.dropdown-list {
  color: 'black' !important;
  margin-left: '3px' !important;
  width: '110px' !important;
  height: '30px' !important;
}
.view-column {
  color: blue !important;
  text-decoration: underline !important;
  margin-right: 10px !important;
}

.download-column {
  color: blue !important;
  text-decoration: underline !important;
}
.action-column {
  text-align: center !important;
}
</style>
<style lang="scss" scoped>
.datagrid-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  position: relative;

  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    background: var(--primary);
    border-radius: 4px;
    z-index: 5;
  }
}
</style>
