<template>
  <div ref="chart" class="chart-container"></div>
</template>

<script>
import * as d3 from 'd3'
import tooltip from '../../util/tooltip'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      default: 200
    }
  },
  mounted() {
    window.addEventListener('resize', this.drawChart) // Add resize listener
    this.drawChart() // Initial chart draw
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.drawChart) // Clean up listener
  },
  methods: {
    drawChart() {
      const margin = { top: 20, right: 30, bottom: 60, left: 50 }
      const width = this.$refs.chart.clientWidth - margin.left - margin.right
      const height = this.height - margin.top - margin.bottom
      d3.select(this.$refs.chart).select('svg').remove()

      const svg = d3
        .select(this.$refs.chart)
        .append('svg')
        .attr('width', '100%') // Make it responsive
        .attr('height', this.height)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      const x = d3
        .scaleBand()
        .domain(this.data.map((d) => d.date))
        .range([0, width])
        .padding(0.3)

      const y = d3
        .scaleLinear()
        .domain([
          0,
          d3.max(this.data, (d) =>
            d3.sum(this.categories, (category) => d[category])
          )
        ])
        .nice()
        .range([height, 0])

      const color = d3
        .scaleOrdinal()
        .domain(this.categories)
        .range(['#00218a', '#c4c8f8', '#A2A8D0', '#535eeb'])

      // Add horizontal grid lines
      svg
        .append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y).tickSize(-width).tickFormat('').tickSizeOuter(0))

      // Add vertical grid lines
      svg
        .append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(0,${height})`)
        .call(
          d3.axisBottom(x).tickSize(-height).tickFormat('').tickSizeOuter(0)
        )

      // Add X axis
      svg
        .append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x).tickSize(0))
        .selectAll('text')
        .attr('fill', '#888888')

      // Add Y axis
      svg
        .append('g')
        .call(
          d3
            .axisLeft(y)
            .tickSize(0)
            .tickFormat((d) => `${d}%`)
        )
        .selectAll('text')
        .attr('fill', '#888888')

      // Stack data by category
      const stack = d3.stack().keys(this.categories)
      const stackedData = stack(this.data)

      // Add areas for each category (stacked)
      stackedData.forEach((categoryData, i) => {
        const area = d3
          .area()
          .x((d) => x(d.data.date) + x.bandwidth() / 2)
          .y0((d) => y(d[0])) // y0 is the bottom of the stacked area
          .y1((d) => y(d[1])) // y1 is the top of the stacked area

        function tooltipFun(event, d, type) {
          let tooltipData = d
            .map((el) => el.data)
            .map((data) => {
              return {
                [data.date]: data[d.key]
              }
            })
          
          const result = tooltipData.reduce((acc, obj) => {
            const key = Object.keys(obj)[0]
            acc[key] = obj[key]
            return acc
          }, {})
          let data = {}
         
          switch (type) {
            case 'in':
              // eslint-disable-next-line no-case-declarations

              data = result
              break
          }
          tooltip(event, data, type)
        }
        svg
          .append('path')
          .datum(categoryData)
          .attr('fill', color(this.categories[i]))
          .attr('d', area)
          .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
          .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
          .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))
      })

      // Add Axis names
      svg
        .append('text')
        .attr('x', width / 2)
        .attr('y', height + margin.bottom / 2)
        .attr('text-anchor', 'middle')
        .attr('fill', '#dbdbdb')
        .style('font-size', '14px')
        .text('Time Period')

      svg
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('x', -height / 2)
        .attr('y', -margin.left + 27)
        .attr('dy', '-1em')
        .attr('text-anchor', 'middle')
        .attr('fill', '#dbdbdb')
        .style('font-size', '14px')
        .text('Number Of Documents')

      // Add legend
      const legendSpacing = 100
      const legendWidth = (this.categories.length - 1) * legendSpacing
      const legendXOffset = (width - legendWidth - 90) / 2

      const legend = svg
        .append('g')
        .attr(
          'transform',
          `translate(${legendXOffset}, ${height + margin.bottom - 15})`
        )

      this.categories.forEach((category, i) => {
        const legendRow = legend
          .append('g')
          .attr('transform', `translate(${i * legendSpacing}, 0)`)

        legendRow
          .append('circle')
          .attr('r', 5)
          .attr('fill', color(category))
          .attr('cx', 0)
          .attr('cy', 2)

        // Create legend text
        legendRow
          .append('text')
          .attr('x', 12)
          .attr('y', 5)
          .attr('fill', 'black')
          .style('font-size', '12px')
          .text(category)
      })
    }
  }
}
</script>

<style scoped>
.chart-container {
  width: 100%;
}

.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
}

path {
  transition: opacity 0.3s ease;
}

path:hover {
  opacity: 0.9;
}

.tooltip {
  position: absolute;
  background-color: black;
  color: white;
  padding: 5px;
  font-size: 12px;
  border-radius: 4px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}
</style>
