<template>
  <div ref="chart"></div>
</template>

<script>
import * as d3 from 'd3'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 200
    }
  },
  mounted() {
    this.drawChart()
  },
  methods: {
    drawChart() {
      const margin = { top: 20, right: 30, bottom: 60, left: 50 }
      const width = this.width - margin.left - margin.right
      const height = this.height - margin.top - margin.bottom

      const svg = d3
        .select(this.$refs.chart)
        .append('svg')
        .attr('width', this.width)
        .attr('height', this.height)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      const x = d3
        .scaleBand()
        .domain(this.data.map((d) => d.date))
        .range([0, width])
        .padding(0.3)

      const y = d3
        .scaleLinear()
        .domain([
          0,
          d3.max(this.data, (d) => d3.max(this.categories, (key) => d[key]))
        ])
        .nice()
        .range([height, 0])

      const color = d3
        .scaleOrdinal()
        .domain(this.categories)
        .range(['#A2A8D0', '#535eeb', '#00218a']) 

      // Add horizontal grid lines
      svg.append('g').attr('class', 'grid').call(
        d3.axisLeft(y).tickSize(-width).tickFormat('').tickSizeOuter(0)
      )

      // Add vertical grid lines
      svg
        .append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(0,${height})`)
        .call(
          d3.axisBottom(x).tickSize(-height).tickFormat('').tickSizeOuter(0)
        )

      // Add X axis
      svg
        .append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x).tickSize(0))
        .selectAll('text')
        .attr('fill', '#b9bdc3')

      // Add Y axis
      svg
        .append('g')
        .call(d3.axisLeft(y).tickSize(0))
        .selectAll('text')
        .attr('fill', '#b9bdc3')

      // Add lines for each category
      this.categories.forEach((category) => {
        const line = d3
          .line()
          .x((d) => x(d.date) + x.bandwidth() / 2)
          .y((d) => y(d[category]))

        svg
          .append('path')
          .datum(this.data)
          .attr('fill', 'none')
          .attr('stroke', color(category))
          .attr('stroke-width', 2)
          .attr('d', line)
      })

      // Add points on each line
      this.categories.forEach((category) => {
        svg
          .selectAll(`.dot-${category}`)
          .data(this.data)
          .enter()
          .append('circle')
          .attr('cx', (d) => x(d.date) + x.bandwidth() / 2)
          .attr('cy', (d) => y(d[category]))
          .attr('r', 4)
          .attr('fill', color(category))
      })

      // Add Axis names
      svg
        .append('text')
        .attr('x', width / 2)
        .attr('y', height + margin.bottom / 2)
        .attr('text-anchor', 'middle')
        .attr('fill', '#dbdbdb')
        .style('font-size', '14px')
        .text('Time Period')

      svg
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('x', -height / 2)
        .attr('y', -margin.left + 27)
        .attr('dy', '-1em')
        .attr('text-anchor', 'middle')
        .attr('fill', '#dbdbdb')
        .style('font-size', '14px')
        .text('Number Of Documents')


      // Add legend
      const legendSpacing = 100 
      const legendWidth = (this.categories.length - 1) * legendSpacing
      const legendXOffset = (width - legendWidth- 90) / 2 

      const legend = svg
        .append('g')
        .attr(
          'transform',
          `translate(${legendXOffset}, ${height + margin.bottom - 15})`
        )

      this.categories.forEach((category, i) => {
        const legendRow = legend
          .append('g')
          .attr('transform', `translate(${i * legendSpacing}, 0)`)

        legendRow
          .append('circle')
          .attr('r', 5)
          .attr('fill', color(category))
          .attr('cx', 0)
          .attr('cy', 2)

        // Create legend text
        legendRow
          .append('text')
          .attr('x', 12)
          .attr('y', 5)
          .attr('fill', 'black')
          .style('font-size', '12px')
          .text(category)
      })
    }
  }
}
</script>

<style>
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
}

circle:hover {
  opacity: 0.8;
}
</style>
