import Snackbar from '@/components/Snackbar'
import moment from 'moment'
import Vue from 'vue'
import { entityPoolPriority, workTypePriority } from '../../util/util'

function formattedResObj(list) {
  const formattedObjList = list.map((item) => {
    // Assign new key
    item.value = item.id
    item.text = `${item.name.trim()}`

    // Delete old key
    delete item.id
    delete item.name

    return item
  })

  return formattedObjList
}

const getDefaultState = () => {
  return {
    asignedEntityDetails: [],
    workType: [],
    isLoading: false,
    onHoldReasons: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    getWorkType({ commit }) {
      Vue.prototype.$http
        .get('dmp/work-type/')
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('SET_WORK_TYPE', response.data.data)
            }
          }
          if (response.data.status === 204) {
            //  todo: action to be decided
          }
        })
        .catch((e) => {
          // commit('LOADING', false)
        })
    },
    getAssignedEntityDetails({ commit }, userId) {
      commit('LOADING', true)
      Vue.prototype.$http
        .get(`dmp/assigned-entities?userId=${userId}`)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              response.data.data.forEach((entity) => {
                if (entity.assignedDate) {
                  const initialDateString = entity.assignedDate
                  const initialDate = moment.utc(initialDateString).local()
                  switch (entity.priority) {
                    case entityPoolPriority.HIGH:
                      initialDate.add(4, 'hours')
                      break
                    case entityPoolPriority.MEDIUM:
                      initialDate.add(8, 'hours')
                      break
                    case entityPoolPriority.LOW:
                      initialDate.add(24, 'hours')
                      break
                    // Handle other cases if needed
                  }
                  entity.TAT = initialDate
                }
              })
              commit('SET_ENTITY_DETAILS', response.data.data)
              commit('LOADING', false)
            }
          }
        })
    },
    async updateOnHoldStatus({ commit }, statusPayload) {
      commit('LOADING', true)
      try {
        const response = await Vue.prototype.$http2.post(
          `/dmp/assigned-entities/update-on-hold`,
          {
            sgaId: statusPayload.sgaId,
            onHoldStatus: statusPayload.isOnHold,
            onHoldReasonId: statusPayload.onHoldReasonId
          }
        )

        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            Snackbar({
              message: 'On hold status updated successfully',
              type: 'is-success'
            })
          }
        } else {
          throw new Error('Failed to update on hold status')
        }
      } catch (error) {
        Snackbar({
          message: 'Failed to update on hold status',
          type: 'is-danger'
        })
      } finally {
        commit('LOADING', false)
      }
    },

    async getOnHoldReasons({ commit }) {
      commit('LOADING', true)
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/assigned-entities/on-hold-reasons'
        )

        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_ON_HOLD_REASONS', response.data.data)
          }
        } else {
          throw new Error('Failed to fetch on hold reasons')
        }
      } catch (error) {
        Snackbar({
          message: 'Failed to fetch on hold reasons',
          type: 'is-danger'
        })
      } finally {
        commit('LOADING', false)
      }
    }
  },
  mutations: {
    SET_WORK_TYPE(state, workType) {
      state.entityTypeList = workType.map((workType) => {
        workType.id = workType.name
        return workType
      })

      state.workType = workType
    },
    SET_ENTITY_DETAILS(state, entityDetils) {
      state.asignedEntityDetails = []
      entityDetils.forEach((entity) => {
        const entityObj = {}
        entityObj.sgaId = entity.sgaId
        entityObj.partyId = entity.partyId
        entityObj.requestId = entity.requestId
        entityObj.legalEntityName = entity.legalEntityName
        entityObj.countryName = entity.countryName
        entityObj.TAT = entity.priority ? entity.TAT : null
        entityObj.tatValue = entity.calculatedDate
        entityObj.ultimateParentRequestId = entity.ultimateParentRequestId
        entityObj.ultimateParentName = entity.ultimateParentName
        entityObj.assignedTo = entity.firstName + ' ' + entity.lastName
        entityObj.status = entity.status
        entityObj.workType = entity.workType
        entityObj.assignmentId = entity.assignmentId
        entityObj.priority = entity.priority
        entityObj.isUpdated = entity.isUpdated
        entityObj.isOnHold = entity.isOnHold
        entityObj.isOnHoldValue = entity.isOnHoldValue
        entityObj.onHoldReasonId = entity.onHoldReasonId
        entityObj.onHoldReasonName = entity.onHoldReasonName
        entityObj.immediateParentName = entity.immediateParentName

        state.asignedEntityDetails.push(entityObj)
      })

      state.asignedEntityDetails.sort((a, b) => {
        const priorityA = a.workType.map((type) => workTypePriority[type])
        const priorityB = b.workType.map((type) => workTypePriority[type])

        // Find the minimum priority for each entry
        const minPriorityA = Math.min(...priorityA)
        const minPriorityB = Math.min(...priorityB)

        // Sort based on the minimum priority
        if (minPriorityA < minPriorityB) {
          return -1
        } else if (minPriorityA > minPriorityB) {
          return 1
        } else {
          // If minimum priorities are the same, sort by assigned date or other criteria
          return 0
        }
      })
    },

    LOADING(state, isLoading) {
      state.isLoading = isLoading
    },

    SET_ON_HOLD_REASONS(state, onHoldReasons) {
      state.onHoldReasons = formattedResObj(onHoldReasons)
    }
  }
}
