<template>
    <div class="form-group">
      <p v-if="showLable">
        {{ label }}<sup v-if="required" class="display-flex required">*</sup>
        <b-tooltip type="is-info" v-if="isTooltip" :label="tooltipMsg" multilined>
          <b-icon icon="information-variant-circle" size="is-small"> </b-icon>
        </b-tooltip>
      </p>
      <b-input
        autocomplete="new-password"
        :type="type"
        v-model="name"
        class="form-control"
        :class="customBorder ? 'darkBorder' : ''"
        :disabled="disabled"
        :readonly="readonly"
        @keydown.enter="onEnterFun"
        :password-reveal="passwordReveal"
        @input="handleInput"
        @focus="handleFocus"
        icon-pack="none"
        :placeholder="placeholder"
      ></b-input>
      <div v-if="errorToDisplay" class="required-error">
        {{ errorToDisplay }}
      </div>
  
      <span
        v-if="min && !$v.name.minLength && $v.name.numeric"
        class="required-error"
        >{{ label }} must be greater than {{ min }}
        {{ isNumeric ? 'digits' : 'characters' }}.</span
      >
      <span
        v-if="max && !$v.name.maxLength && $v.name.numeric"
        class="required-error"
        >{{ label }} must be less than {{ max }}
        {{ isNumeric ? 'digits' : 'characters' }}.</span
      >
      <span
        v-if="!$v.name.email && $v.name.minLength && $v.name.maxLength"
        class="required-error"
        >Email is invalid.</span
      >
      <span
        v-if="
          !$v.name.alphaNum && $v.name.minLength && $v.name.maxLength && alphaNum
        "
        class="required-error"
        >Only alphanumeric characters allowed.</span
      >
      <span
        v-if="
          !$v.name.alphaNumAndSpecialChar &&
          $v.name.minLength &&
          $v.name.maxLength &&
          alphaNumAndSpecialChar &&
          $v.name.required
        "
        class="required-error"
        >Only alphanumeric ,space ( ) - and & characters are allowed.</span
      >
      <span
        v-if="
          !$v.name.alphaNumSpaceAndDash &&
          $v.name.asciiEnglishCharactersOnly &&
          $v.name.minLength &&
          $v.name.maxLength &&
          alphaNumSpaceAndDash &&
          $v.name.required
        "
        class="required-error"
        >Only alphanumeric ,space and - characters are allowed.</span
      >
      <span
        v-if="
          !$v.name.alphaNumericAndHyphen &&
          $v.name.minLength &&
          $v.name.maxLength &&
          alphaNumericAndHyphen &&
          $v.name.required
        "
        class="required-error"
        >Only alphanumeric and - are allowed.</span
      >
      <span
        v-if="!$v.name.url && $v.name.minLength && $v.name.maxLength"
        class="required-error"
        >Enter correct url.</span
      >
      <span
        v-if="
          !$v.name.internationalPhone && $v.name.minLength && $v.name.maxLength
        "
        class="required-error"
        >Invalid phone number.</span
      >
      <span
        v-if="!$v.name.ISIN && $v.name.minLength && $v.name.maxLength"
        class="required-error"
        >Invalid ISIN number.</span
      >
      <span
        v-if="!$v.name.duns && $v.name.minLength && $v.name.maxLength"
        class="required-error"
        >Invalid DUNS number.</span
      >
      <span v-if="!$v.name.numeric" class="required-error"
        >Enter valid zip code</span
      >
      <span v-if="isPositive && name < 0" class="required-error"
        >Enter a positive number.</span
      >
      <span v-if="isNonZero && parseInt(name) === 0" class="required-error"
        >Enter non zero value.</span
      >
      <span
        v-if="
          !$v.name.alphaNumSpace &&
          $v.name.minLength &&
          $v.name.maxLength &&
          alphaNumSpace &&
          $v.name.required
        "
        class="required-error"
        >Only alphanumeric and space are allowed.</span
      >
      <span
        v-if="
          !$v.name.asciiEnglishCharactersOnly &&
          $v.name.minLength &&
          $v.name.maxLength
        "
        class="required-error"
        >Non English characters are not allowed.</span
      >
      <span
        v-if="
          !$v.name.alphaNumBracsAndHyphen &&
          $v.name.minLength &&
          $v.name.maxLength &&
          alphaNumBracsAndHyphen &&
          $v.name.required
        "
        class="required-error"
        >Only alphanumeric, space ( ) - and & characters are allowed.</span
      >
      <span
        v-if="
          !$v.name.newLineValidation && $v.name.minLength && $v.name.maxLength
        "
        class="required-error"
        >New line character is not allowed</span
      >
      <span
        v-if="
          !$v.name.hashSpecialCharValidation &&
          $v.name.pipeSpecialCharValidation &&
          $v.name.minLength &&
          $v.name.maxLength &&
          $v.name.newLineValidation
        "
        class="required-error"
      >
        ## character is not allowed</span
      >
      <span
        v-if="
          !$v.name.pipeSpecialCharValidation &&
          $v.name.minLength &&
          $v.name.maxLength &&
          $v.name.newLineValidation
        "
        class="required-error"
      >
        Pipe ( | ) character is not allowed</span
      >
    </div>
  </template>
  <script>
  import {
    required,
    email,
    minLength,
    maxLength,
    url,
    numeric,
    helpers
  } from 'vuelidate/lib/validators'
  // password-reveal
  /** This component is used to take in user inputs */
  export default {
    name: 'Input',
    props: {
      /** This prop contains the text for the label of the input */
      label: {
        type: String
      },
      /** Unsure */
      onChangeName: {
        type: Function
      },
      /** This prop indicates what type of input is being created */
      type: {
        type: String,
        default: 'text'
      },
      /** This prop indicates the value of the input */
      value: {
        default: null
      },
      /** This prop indicates whether or not the password entered should be visible or not */
      passwordReveal: {
        type: Boolean,
        default: false
      },
      /** This prop indicates whether or not a form submission has been attempted */
      submitted: {
        type: Boolean,
        default: false
      },
      /** This prop indicates the min value of the input */
      min: {
        type: Number,
        default: null
      },
      /** This prop indicates the max value of the input */
      max: {
        type: Number,
        default: null
      },
      /** This prop indicates whether a value is required for this input or not */
      required: {
        type: Boolean,
        default: false
      },
      /** This prop indicates that  value accepts only alphanumeric character and space for this input or not */
      alphaNumAndSpecialChar: {
        type: Boolean,
        default: false
      },
      /** This prop indicates that  value accepts only alphanumeric character ,space and - for this input or not */
      alphaNumSpaceAndDash: {
        type: Boolean,
        default: false
      },
      alphaNumericAndHyphen: {
        type: Boolean,
        default: false
      },
      /** This prop indicates whether a value is alphanumeric for this input or not */
      alphaNum: {
        type: Boolean,
        default: false
      },
      /** This prop indicates whether a value is valid or not */
      isValid: {
        type: Function
      },
      /** This prop indicates whether a value is editable or not */
      readonly: {
        type: Boolean,
        default: false
      },
      /** This prop contains the placeholder text for the input */
      placeholder: {
        type: String,
        default: ''
      },
      /** This prop indicates whether or not this input is editable */
      disabled: {
        type: Boolean,
        default: false
      },
      /** This prop indicates whether or not a label must be shown for this input */
      showLable: {
        type: Boolean,
        default: true
      },
      /** This prop defines the behavior of this input when Enter is pressed (?) */
      onEnter: {
        type: Function
      },
      /** This prop indicates whether or not a custom border should be applied to the input */
      customBorder: {
        type: Boolean,
        default: false
      },
      /** This prop indicates the index of the current input in the form group feilds */
      fieldIndex: {
        type: Number,
        default: 0
      },
      /** This prop indicates the subindex of the current input in the form group feilds */
      subFieldIndex: {
        type: Number,
        default: 0
      },
      /** This prop indicates the subindex of the current input in the form group feilds */
      isPhone: {
        type: Boolean,
        default: false
      },
      /** This prop indicates the zipcode boolean */
      isZipCode: {
        type: Boolean,
        default: false
      },
      /** This prop indicates the input is numeric */
      isNumeric: {
        type: Boolean,
        default: false
      },
      isPositive: {
        type: Boolean,
        default: false
      },
      /** This prop is for ISIN input in company form */
      isISIN: {
        type: Boolean,
        default: false
      },
      /** This prop is for DUNS input in company form */
      isDuns: {
        type: Boolean,
        default: false
      },
      showSecondaryError: {
        type: Boolean,
        default: false
      },
      secondaryError: {
        type: String,
        default: ''
      },
      isDefaultErrorMessage: {
        type: Boolean,
        default: true
      },
      alphaNumSpace: {
        type: Boolean,
        default: false
      },
      isAsciiEnglishCharactersOnly: {
        type: Boolean,
        default: false
      },
      newLineValidation: {
        type: Boolean,
        default: false
      },
      hashSpecialCharValidation: {
        type: Boolean,
        default: false
      },
      pipeSpecialCharValidation: {
        type: Boolean,
        default: false
      },
      isNonZero: {
        type: Boolean,
        default: false
      },
      isTooltip: {
        type: Boolean,
        default: false
      },
      tooltipMsg: {
        type: String,
        default: ''
      },
      alphaNumBracsAndHyphen: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        name: this.value
      }
    },
    computed: {
      errorToDisplay() {
        if (
          !this.$v.name.required &&
          this.submitted &&
          this.required &&
          this.isDefaultErrorMessage
        ) {
          return `${this.label} is required`
        } else if (this.showSecondaryError && this.secondaryError) {
          return this.secondaryError
        } else {
          return ''
        }
      }
    },
    watch: {
      name() {
        this.$v.$touch()
        this.$emit(
          'onChangeName',
          this.name,
          this.$v.name.$invalid,
          this.fieldIndex,
          this.subFieldIndex
        )
      },
      value() {
        this.name = this.value
      }
    },
    mounted() {
      this.$v.$touch()
  
      this.$emit(
        'onChangeName',
        this.name,
        this.$v.name.$invalid,
        this.fieldIndex,
        this.subFieldIndex
      )
    },
    validations() {
      return {
        name: {
          required: this.required ? required : false,
          email: this.type === 'email' ? email : false,
          url: this.type === 'url' ? url : false,
          alphaNum:
            this.alphaNum === true
              ? helpers.regex('alphaNum', /^[a-zA-Z\d]*$/i)
              : false,
          minLength: minLength(this.min),
          maxLength: maxLength(this.max),
          numeric: this.isZipCode ? numeric : false,
          internationalPhone:
            this.isPhone === true
              ? helpers.regex(
                  'internationalPhone',
                  /^(?=.{1,20}$)((\+|00|0){0,1}(?!.*--)[1-9][0-9\-]{4,18}[0-9])$/
                )
              : false,
          alphaNumAndSpecialChar:
            this.alphaNumAndSpecialChar === true
              ? helpers.regex('alphaNumAndSpecialChar', /^[ A-Za-z0-9_()&-]*$/)
              : false,
          alphaNumSpaceAndDash:
            this.alphaNumSpaceAndDash === true
              ? helpers.regex('alphaNumSpaceAndDash', /^[ A-Za-z0-9 -]*$/)
              : false,
          ISIN:
            this.isISIN === true
              ? helpers.regex('ISIN', /^[A-Za-z]+[A-Za-z0-9]*[0-9]+$/)
              : false,
          duns: this.isDuns === true ? helpers.regex('duns', /^\d{9}$/) : false,
          alphaNumericAndHyphen:
            this.alphaNumericAndHyphen === true
              ? helpers.regex('alphaNumericAndHyphen', /^[a-zA-Z0-9\s&-]*$/)
              : false,
          alphaNumSpace:
            this.alphaNumSpace === true
              ? helpers.regex('alphaNumSpace', /^[ A-Za-z0-9]*$/)
              : false,
          // eslint-disable-next-line no-control-regex
          asciiEnglishCharactersOnly:
            this.isAsciiEnglishCharactersOnly === true
              ? helpers.regex(
                  'asciiEnglishCharactersOnly',
                  /^[\s\x00-\x84\u201A\u201E\u20AC\u0192]+$/
                )
              : false,
          alphaNumBracsAndHyphen:
            this.alphaNumBracsAndHyphen === true
              ? helpers.regex('alphaNumBracsAndHyphen', /^[ A-Za-z0-9()&-]*$/)
              : false,
          newLineValidation: this.newLineValidation
            ? (value) => !/[\r\n]/.test(value)
            : false,
          hashSpecialCharValidation:
            this.hashSpecialCharValidation === true
              ? helpers.regex('hashSpecialCharValidation', /^(?!.*##).*$/)
              : false,
          pipeSpecialCharValidation:
            this.pipeSpecialCharValidation === true
              ? helpers.regex('pipeSpecialCharValidation', /^[^|]*$/)
              : false
        }
      }
    },
    methods: {
      onEnterFun() {
        this.$emit('onEnter')
      },
      handleInput(data) {
        this.$emit('input', data)
      },
      handleFocus(event) {
        this.$emit('focus', event.target)
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .form-group {
    p {
      display: flex;
      margin: 0px;
      color: #7f8fa4;
      font-size: 12px;
      font-family: Quicksand;
      padding: 0px 20px 0px 0px;
      display: flex;
      line-height: 20px;
      sup {
        font-family: Roboto;
        font-size: 12px;
      }
    }
    .darkBorder {
      border: 1px solid var(--primary-border-color);
      border-radius: 4px;
    }
  
    ::-webkit-input-placeholder {
      text-align: left;
      font: normal normal 13px/16px Quicksand;
      font-weight: 400;
      letter-spacing: 0.18px;
      color: #cccccc;
      opacity: 1;
    }
  }
  
  .required-error {
    color: red;
    position: relative;
    font-size: 12px;
  }
  .required {
    color: red;
  }
  
  ::v-deep .b-tooltip.is-info .tooltip-content {
    background: #c4c8f8;
    color: #181818;
    font-weight: 500;
  }
  </style>
  