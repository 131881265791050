<template>
  <div ref="chart" class="chart-container"></div>
</template>

<script>
import * as d3 from 'd3'
import tooltip from '../../util/tooltip'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      default: 220
    }
  },
  mounted() {
    this.drawChart()
    window.addEventListener('resize', this.drawChart) // Redraw on resize
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.drawChart)
  },
  methods: {
    drawChart() {
      const margin = { top: 12, right: 30, bottom: 50, left: 60 }

      const width = this.$refs.chart.clientWidth - margin.left - margin.right
      const height = this.height - margin.top - margin.bottom
      d3.select(this.$refs.chart).selectAll('svg').remove()

      const svg = d3
        .select(this.$refs.chart)
        .append('svg')
        .attr('width', '100%')
        .attr('height', this.height)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      const x0 = d3
        .scaleBand()
        .domain(this.data.map((d) => d.date))
        .range([0, width])
        .padding(0.3)

      const x1 = d3
        .scaleBand()
        .domain(this.categories)
        .range([0, x0.bandwidth()])
        .padding(0.01)

      const y = d3
        .scaleLinear()
        .domain([
          0,
          d3.max(this.data, (d) => d3.max(this.categories, (key) => d[key]))
        ])
        .nice()
        .range([height, 0])

      const color = d3
        .scaleOrdinal()
        .domain(this.categories)
        .range(['#00218a', '#535eeb', '#A2A8D0', '#c4c8f8']) // Custom colors

      // function for tooltip
      function tooltipFun(event, d, type) {
        let data = {}
        switch (type) {
          case 'in':
            data = {
              [d.key]:[d.value]
            }
            break
        }
        tooltip(event, data, type)
      }

      // Add horizontal grid lines
      svg
        .append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y).tickSize(-width).tickSizeOuter(0))

      // Add vertical grid lines
      svg
        .append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x0).tickSize(-height).tickSizeOuter(0))

      // Add X axis
      svg
        .append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x0).tickSize(0))
        .selectAll('text')
        .attr('fill', '#888888')

      // Add Y axis
      svg
        .append('g')
        .call(d3.axisLeft(y).tickSize(0))
        .selectAll('text')
        .attr('fill', '#888888')

      svg
        .selectAll('g.layer')
        .data(this.data)
        .enter()
        .append('g')
        .attr('transform', (d) => `translate(${x0(d.date)},0)`)
        .selectAll('rect')
        .data((d) => this.categories.map((key) => ({ key, value: d[key] })))
        .enter()
        .append('rect')
        .attr('x', (d) => x1(d.key))
        .attr('y', (d) => y(d.value))
        .attr('width', x1.bandwidth())
        .attr('height', (d) => height - y(d.value))
        .attr('fill', (d) => color(d.key))
        .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
        .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
        .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))

      svg
        .append('text')
        .attr('x', width / 2)
        .attr('y', height + margin.bottom / 2)
        .attr('text-anchor', 'middle')
        .attr('fill', '#dbdbdb')
        .style('font-size', '14px')
        .text('Time Period')

      svg
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('x', -height / 2)
        .attr('y', -margin.left + 27)
        .attr('dy', '-1em')
        .attr('text-anchor', 'middle')
        .attr('fill', '#dbdbdb')
        .style('font-size', '14px')
        .text('Number Of Documents')

      const legendSpacing = 100
      const legendWidth = (this.categories.length - 1) * legendSpacing
      const legendXOffset = (width - legendWidth - 90) / 2

      const legend = svg
        .append('g')
        .attr(
          'transform',
          `translate(${legendXOffset}, ${height + margin.bottom - 10})`
        )

      this.categories.forEach((category, i) => {
        const legendRow = legend
          .append('g')
          .attr('transform', `translate(${i * legendSpacing}, 0)`)

        legendRow
          .append('circle')
          .attr('r', 5)
          .attr('fill', color(category))
          .attr('cx', 0)
          .attr('cy', 1)

        // Create legend text
        legendRow
          .append('text')
          .attr('x', 12)
          .attr('y', 5)
          .attr('fill', 'black')
          .style('font-size', '12px')
          .text(category)
      })
    }
  }
}
</script>

<style scoped>
.chart-container {
  width: 100%;
}

.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
}

rect:hover {
  opacity: 1;
}

.tooltip {
  position: absolute;
  background-color: black;
  color: white;
  padding: 5px;
  font-size: 12px;
  border-radius: 4px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}
</style>
