<template>
  <div class="identifiers-details-section">
    <h2 class="section-title">Identifiers</h2>
    <div class="mandatory-note">
      <p>
        Text boxes marked as
        <span class="mandatory">*</span> are mandatory.
      </p>
    </div>
    <div>
      <div class="identifiers-form">
        <div class="local-loader-container" v-if="loading">
          <div class="inava-loader"></div>
        </div>

        <div class="column-view">
          <InputVue
            inputId="companyRegistrationNumber"
            label="Company Registration Number"
            :isExtraLabelInfo="showCrnRegexExample"
            :extraLabelInfo="`(Example : ${crnRegexList[0]?.example})`"
            :value="newIdentifiersDetails.crn"
            @onChangeName="updateCompanyRegistrationNumber"
            :min="isMinValidation"
            :max="isMaxValidation"
            :alphaNumSpace="showCrnRegexExample ? false : true"
            placeholder="Enter Company Registration Number"
            class="form-input-field"
            :submitted="submitted"
            :showSecondaryError="
              !isNullValue(evdErrorMsg.crn) || isCrnRegexValid
            "
            :secondaryError="
              [
                evdErrorMsg.crn,
                isCrnRegexValid &&
                  'Please provide valid Company Registration Number'
              ]
                .filter(Boolean)
                .join('<br>')
            "
          />
          <div :class="crnErrorClass">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(identifiersDetailsEvdIds.CRN)
              "
              @click.native="toggleEvidenceForm(identifiersDetailsEvdIds.CRN)"
              :btnLoading="getBtnLoadingStatus(identifiersDetailsEvdIds.CRN)"
              :isDisabled="isEvidenceBtnDisabled('crn')"
            />
          </div>
        </div>
        <div class="column-view">
          <InputVue
            inputId="incorporationAuthority"
            label="Incorporation Authority"
            :min="minCommonLength"
            :max="maxCommonLength"
            :value="newIdentifiersDetails.inai"
            :required="isINAIMandatory"
            :isAsciiEnglishCharactersOnly="true"
            @onChangeName="updateIncorporationAuthority"
            :isDefaultErrorMessage="isMandatory"
            placeholder="Enter Incorporation Authority"
            class="form-input-field"
            :submitted="submitted"
            :showSecondaryError="!isNullValue(evdErrorMsg.inai)"
            :secondaryError="evdErrorMsg.inai"
          />
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(identifiersDetailsEvdIds.INAI)
              "
              @click.native="toggleEvidenceForm(identifiersDetailsEvdIds.INAI)"
              :btnLoading="getBtnLoadingStatus(identifiersDetailsEvdIds.INAI)"
              :isDisabled="isEvidenceBtnDisabled('inai')"
            />
          </div>
        </div>
      </div>
      <div class="tax-input-container">
        <template v-for="(pair, index) in taxIDTypeInputData">
          <div class="tax-input-view">
            <div class="column-view">
              <SearchableSelect
                :value="taxIdTypes[index]"
                :label="'Tax ID Type '"
                :placeholder="'Select Tax ID Type '"
                :data="pair.taxIdTypeOption"
                value_field="value"
                text_field="text"
                @onChangeName="(value) => updateTaxIDType(value, index)"
                class="form-input-field"
                :mandatorySubmit="submitted"
                :submitted="submitted"
                :isMandatory="pair.isMandatory"
              />
            </div>
            <div class="column-view">
              <InputVue
                :inputId="'taxId_' + index"
                :label="'Tax ID '"
                :min="minCommonLength"
                :max="maxExceptionalLength"
                :alphaNumSpaceAndDash="true"
                :value="taxIdValues[index]"
                @onChangeName="(value) => updateTaxID(value, index)"
                :placeholder="'Enter Tax ID '"
                class="form-input-field"
                :submitted="submitted"
                :showSecondaryError="!isNullValue(pair.taxIdError)"
                :secondaryError="pair.taxIdError"
                :required="pair.isMandatory"
              />
              <div class="add-evidence">
                <AddEvidence
                  :hasEvidenceButton="true"
                  :evidenceFormStatus="
                    evdMethodsForSubField(index).evidenceFormStatus()
                  "
                  @click.native="evdMethodsForSubField(index).toggleEvidence()"
                  :btnLoading="evdMethodsForSubField(index).btnLoadingStatus()"
                  :isDisabled="isEvidenceBtnDisabled('taxIdValues', { index })"
                />
              </div>
            </div>
          </div>
          <div class="tax-button-view">
            <button @click="addInputPair()" class="add-tax-button">
              <span class="button-icon">
                <SvgIcon :icon="plusIcon" :size="'large'" class="icon" />
              </span>
            </button>
            <button @click="removeInputPair(index)" class="add-tax-button">
              <span class="button-icon">
                <SvgIcon :icon="xIcon" :size="'large'" class="icon" />
              </span>
            </button>
          </div>
        </template>
      </div>
      <div class="identifiers-form">
        <div class="column-view">
          <InputVue
            inputId="legalEntityIdentifier"
            label="Legal Entity Identifier"
            :mandatory="false"
            :min="minExceptionalLength"
            :max="maxExceptionalLength"
            :alphaNumSpace="true"
            :value="newIdentifiersDetails.lei"
            @onChangeName="updateLegalEntityIdentifier"
            placeholder="Enter Legal Entity Identifier"
            class="form-input-field"
            :submitted="submitted"
            :showSecondaryError="!isNullValue(evdErrorMsg.lei)"
            :secondaryError="evdErrorMsg.lei"
          />
          <button
            @click="openSearchDialog(identifiersFields.LEI)"
            class="search-button"
          >
            <span class="button-icon">
              <SvgIcon :icon="searchIcon" :size="'medium'" class="icon" />
            </span>
          </button>
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(identifiersDetailsEvdIds.LEI)
              "
              @click.native="toggleEvidenceForm(identifiersDetailsEvdIds.LEI)"
              :btnLoading="getBtnLoadingStatus(identifiersDetailsEvdIds.LEI)"
              :isDisabled="isEvidenceBtnDisabled('lei')"
            />
          </div>
        </div>
        <div class="column-view">
          <InputVue
            inputId="cik"
            label="CIK"
            :mandatory="false"
            :min="minCommonLength"
            :max="maxCommonLength"
            :alphaNumSpace="true"
            :value="newIdentifiersDetails.cik"
            @onChangeName="updateCik"
            placeholder="Enter CIK"
            class="form-input-field"
            :submitted="submitted"
            :showSecondaryError="!isNullValue(evdErrorMsg.cik)"
            :secondaryError="evdErrorMsg.cik"
          />
          <button
            @click="openSearchDialog(identifiersFields.CIK)"
            class="search-button"
          >
            <span class="button-icon">
              <SvgIcon :icon="searchIcon" :size="'medium'" class="icon" />
            </span>
          </button>
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(identifiersDetailsEvdIds.CIK)
              "
              @click.native="toggleEvidenceForm(identifiersDetailsEvdIds.CIK)"
              :btnLoading="getBtnLoadingStatus(identifiersDetailsEvdIds.CIK)"
              :isDisabled="isEvidenceBtnDisabled('cik')"
            />
          </div>
        </div>
        <div class="column-view">
          <InputVue
            inputId="rssd"
            label="RSSD"
            :mandatory="false"
            :min="minCommonLength"
            :max="maxCommonLength"
            :alphaNumSpace="true"
            :value="newIdentifiersDetails.rssd"
            @onChangeName="updateRssd"
            placeholder="Enter RSSD"
            class="form-input-field"
            :submitted="submitted"
            :showSecondaryError="
              !isNullValue(evdErrorMsg.rssd) ||
              checkIfNA(newIdentifiersDetails.rssd)
            "
            :secondaryError="
              [
                evdErrorMsg.rssd,
                checkIfNA(newIdentifiersDetails.rssd) && 'Entered text is not valid'
              ]
                .filter(Boolean)
                .join('<br>')
            "
          />
          <button
            @click="openSearchDialog(identifiersFields.RSSD)"
            class="search-button"
          >
            <span class="button-icon">
              <SvgIcon :icon="searchIcon" :size="'medium'" class="icon" />
            </span>
          </button>
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(identifiersDetailsEvdIds.RSSD)
              "
              @click.native="toggleEvidenceForm(identifiersDetailsEvdIds.RSSD)"
              :btnLoading="getBtnLoadingStatus(identifiersDetailsEvdIds.RSSD)"
              :isDisabled="isEvidenceBtnDisabled('rssd')"
            />
          </div>
        </div>

        <div class="column-view">
          <InputVue
            inputId="crd"
            label="CRD"
            :mandatory="false"
            :min="minCommonLength"
            :max="maxCommonLength"
            :alphaNumSpace="true"
            :value="newIdentifiersDetails.crd"
            @onChangeName="updateCRD"
            placeholder="Enter CRD"
            class="form-input-field"
            :submitted="submitted"
            :showSecondaryError="
              !isNullValue(evdErrorMsg.crd) ||
              checkIfNA(newIdentifiersDetails.crd)
            "
            :secondaryError="
              [
                evdErrorMsg.crd,
                checkIfNA(newIdentifiersDetails.crd) && 'Entered text is not valid'
              ]
                .filter(Boolean)
                .join('<br>')
            "
          />
          <button
            @click="openSearchDialog(identifiersFields.CRD)"
            class="search-button"
          >
            <span class="button-icon">
              <SvgIcon :icon="searchIcon" :size="'medium'" class="icon" />
            </span>
          </button>
          <div class="add-evidence">
            <AddEvidence
              :hasEvidenceButton="true"
              :evidenceFormStatus="
                getEvidenceFormStatus(identifiersDetailsEvdIds.CRD)
              "
              @click.native="toggleEvidenceForm(identifiersDetailsEvdIds.CRD)"
              :btnLoading="getBtnLoadingStatus(identifiersDetailsEvdIds.CRD)"
              :isDisabled="isEvidenceBtnDisabled('crd')"
            />
          </div>
        </div>
      </div>
      <DMPSelectableGridDialog
        v-if="selectableGridData?.length > 0"
        :title="selectableGridOptions.title"
        :dataItems="selectableGridData"
        :visible="isSearchDialogVisible"
        :closeDialog="closeSearchDialog"
        :isAllColFilterable="selectableGridOptions.isAllColFilterable"
        :pagination="true"
        :selectable="true"
        :pageSize="selectableGridOptions?.pageSize || 10"
        :selectValue="selectableGridOptions.selectValue"
        :formFieldName="selectableGridOptions.formFieldName"
        :confirm="searchDialogData"
        :searchableCols="selectableGridOptions.searchableCols"
        :hyperlinksCol="selectableGridOptions.hyperlinksCol"
      ></DMPSelectableGridDialog>
    </div>
    <template>
      <ActionButton
        @SaveData="saveData"
        @saveAndNext="saveAndNext"
        @goBack="goBack"
        :isFormValid="isValidForm"
      ></ActionButton>
    </template>
    <template>
      <ConfirmationDialog
        :visible="confirmationDialogData.visible"
        :title="confirmationDialogData.title"
        :bodyText="confirmationDialogData.text"
        :cancelButtonText="confirmationDialogData.cancelButtonText"
        :confirmButtonText="confirmationDialogData.confirmButtonText"
        :closeDialog="closeDialog"
        :triggerFunction="confirmationDialogData.triggerFunction"
      />
    </template>
  </div>
</template>

<script>
import InputVue from '@/views/Admin/components/Input.vue'
import AddEvidence from '@/components/DMP/AddEvidence.vue'
import ActionButton from '@/components/DMP/ActionButton.vue'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import SearchableSelect from '@/components/DMP/SearchableSelect'
import DMPSelectableGridDialog from '@/components/DMP/DMPSelectableGridDialog.vue'
import {
  activeStepIndexName,
  identifiersDetailsEvdIds,
  identifiersFields
} from '@/constant/data.js'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import Snackbar from '@/components/Snackbar'
import { isEqual } from 'lodash'
import { xIcon, plusIcon, searchIcon } from '@progress/kendo-svg-icons'
import { SvgIcon } from '@progress/kendo-vue-common'
import { isValidEnglishASCII } from '@/util/util'
import { uniqBy } from 'lodash'

export default {
  components: {
    InputVue,
    AddEvidence,
    ActionButton,
    ConfirmationDialog,
    SvgIcon,
    SearchableSelect,
    DMPSelectableGridDialog
  },
  props: {
    updateStepper: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      crnMaxError: false,
      naData: [
        'NA',
        'N.A',
        'N/A',
        'na',
        'n.a.',
        'n/a',
        'Not Available',
        'Not Applicable'
      ],
      crnAlphaNumError: false,
      validRegexCountry: true,
      validRegex: false,
      sgaId: '',
      loading: false,
      activeStepIndexName,
      submitted: false,
      identifiersDetailsEvdIds,
      inputNameMapping: {},
      minCommonLength: 1,
      maxCommonLength: 1000,
      minExceptionalLength: 20,
      maxExceptionalLength: 20,
      isMandatory: null,
      localEvidenceIdList: [],
      newIdentifiersDetails: {
        crn: null,
        inai: null,
        taxIdType: null,
        lei: null,
        cik: null,
        rssd: null,
        crd: null
      },
      taxIdValues: {
        0: null
      },
      taxIdTypes: {
        0: null
      },
      evdErrorMsg: {
        crn: null,
        inai: null,
        taxIdType: null,
        taxId: null,
        lei: null,
        cik: null,
        rssd: null,
        crd: null
      },
      fieldMetaData: [
        {
          key: 'crn',
          evdField: 'crnEvidence',
          evdKey: identifiersDetailsEvdIds.CRN
        },
        {
          key: 'inai',
          evdField: 'inaiEvidence',
          evdKey: identifiersDetailsEvdIds.INAI
        },
        {
          key: 'lei',
          evdField: 'leiEvidence',
          evdKey: identifiersDetailsEvdIds.LEI
        },
        {
          key: 'cik',
          evdField: 'cikEvidence',
          evdKey: identifiersDetailsEvdIds.CIK
        },
        {
          key: 'rssd',
          evdField: 'rssdEvidence',
          evdKey: identifiersDetailsEvdIds.RSSD
        },
        {
          key: 'crd',
          evdField: 'crdEvidence',
          evdKey: identifiersDetailsEvdIds.CRD
        }
      ],
      confirmationDialogData: {
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      },
      snackbarPayload: {
        // Set default payload
        message: null,
        type: 'is-warning',
        duration: 3000
      },
      xIcon,
      plusIcon,
      searchIcon,
      countryIdList: [],
      isTaxIdWatcher: true,
      initEvidenceListWatcher: false,
      taxIDTypeInputData: [
        {
          taxIdTypeValue: null,
          taxIdValue: null,
          taxIdError: null,
          taxIdTypeOption: [],
          isMandatory: false
        }
      ],
      isSearchDialogVisible: false,
      selectableGridOptions: {
        title: null,
        searchableCols: [],
        selectValue: null,
        pageSize: 10,
        hyperlinksCol: []
      },
      identifiersFields,
      entityName: ''
    }
  },
  computed: {
    ...mapState('identifiers', [
      'identifiersDetails',
      'identifiersTaxTypes',
      'bvdIdentifiersDetails',
      'selectableGridData',
      'crnRegexList'
    ]),
    ...mapState('evidence', ['evidenceIdList', 'storeEvidences']),
    ...mapState('assignedEntities', ['entityDetails']),
    ...mapGetters('evidence', ['getEvidenceFormStatus', 'getBtnLoadingStatus']),
    ...mapGetters('dmp', ['getEntityDetails']),
    isMinValidation() {
      if (this.showCrnRegexExample) {
        return null
      }
      return this.minCommonLength
    },
    isMaxValidation() {
      if (this.showCrnRegexExample) {
        return null
      }
      return this.maxCommonLength
    },

    crnErrorClass() {
      switch (true) {
        case this.evdErrorMsg.crn && this.isCrnRegexValid:
          return 'combined-error-crn'
        case this.evdErrorMsg.crn && this.crnAlphaNumError:
          return 'combineAlphaNumEvidError'
        case this.evdErrorMsg.crn && this.crnMaxError:
          return 'combineMaxEvidError'
        case this.evdErrorMsg.crn:
          return 'evidence-error'
        case this.evdErrorMsg.crn || this.isCrnRegexValid:
          return 'evidence-error'
        case this.crnAlphaNumError:
          return 'crnAlphaNumError'
        case this.crnMaxError:
          return 'crnMaxError'
        default:
          return 'add-evidence-crn'
      }
    },
    isCrnRegexValid() {
      return (
        !this.isNullValue(this.newIdentifiersDetails?.crn) &&
        this.validRegexCountry &&
        !this.validRegex
      )
    },
    showCrnRegexExample() {
      return (
        !this.isNullValue(this.crnRegexList[0]?.example) &&
        this.validRegexCountry
      )
    },
    isINAIMandatory() {
      return !!this.newIdentifiersDetails.crn
    },
    isValidForm() {
      let isValidField = true
      const alphaNumSpace = /^[ A-Za-z0-9]*$/
      const alphaNumDash = /^[ A-Za-z0-9 -]*$/
      for (const formField of this.fieldMetaData) {
        const fieldKey = formField.key
        // Field level validations
        if (!this.isNullValue(this.newIdentifiersDetails[fieldKey])) {
          const fieldValue = this.newIdentifiersDetails[fieldKey]
          switch (fieldKey) {
            case 'crn':
              (isValidField =
                fieldValue.length <= this.maxCommonLength &&
                fieldValue.length &&
                this.validRegexCountry
                  ? this.validRegex
                  : alphaNumSpace.test(fieldValue) &&
                    fieldValue.length <= this.maxCommonLength),
                (this.crnAlphaNumError = !alphaNumSpace.test(fieldValue))
              this.crnMaxError =
                fieldValue.length > this.maxCommonLength ? true : false

              break
            case 'inai': // Free field, AlphaNum Validation not checked
              isValidField =
                fieldValue.length <= this.maxCommonLength &&
                isValidEnglishASCII(fieldValue)
              break
            case 'lei':
              isValidField =
                fieldValue.length >= this.minExceptionalLength &&
                fieldValue.length <= this.maxExceptionalLength &&
                alphaNumSpace.test(fieldValue)
              break
            case 'crd':
              isValidField =
                fieldValue.length <= this.maxCommonLength &&
                !this.naData.some(
                  (item) =>
                    item?.toLowerCase() === fieldValue?.trim().toLowerCase()
                )
              break
            case 'rssd':
              isValidField =
                fieldValue.length <= this.maxCommonLength &&
                !this.naData.some(
                  (item) =>
                    item?.toLowerCase() === fieldValue?.trim().toLowerCase()
                )
              break
            case 'taxId': // Length Exceptional field
              isValidField =
                fieldValue.length <= this.maxExceptionalLength &&
                alphaNumSpace.test(fieldValue)
              break
            default: // Length & AlphaNum validation check
              isValidField =
                fieldValue.length <= this.maxCommonLength &&
                alphaNumSpace.test(fieldValue)
          }
        }
        if (!isValidField) break
      }
      if (isValidField) {
        this.taxIDTypeInputData.every(
          ({ taxIdTypeValue, taxIdValue, isMandatory }) => {
            isValidField = isMandatory
              ? !this.isNullValue(taxIdTypeValue?.value) &&
                !this.isNullValue(taxIdValue) &&
                taxIdValue.length <= this.maxExceptionalLength &&
                alphaNumDash.test(taxIdValue)
              : true
            return isValidField
          }
        )
      }

      this.hasUnsavedChanges(true)
      if (!isValidField) return isValidField // Any Field is not valid then form is inValid
      return true
    },
    isMandatoryValid() {
      return (
        this.isINAIMandatory &&
        !this.isNullValue(this.newIdentifiersDetails.inai)
      )
    }
  },
  watch: {
    newIdentifiersDetails: {
      handler() {
        for (const formField of this.fieldMetaData) {
          if (
            formField?.evdField &&
            !this.isNullValue(
              this.identifiersDetails[`${formField.evdField}`]
            ) &&
            isEqual(
              this.newIdentifiersDetails[`${formField.key}`],
              this.identifiersDetails[`${formField.key}`]
            )
          ) {
            this.updateEvidenceIdList(formField.evdKey)
            this.updateLocalEvidenceIdList(formField.evdKey)
            this.updateInputNameMapping(
              formField.evdKey,
              this.identifiersDetails[`${formField.evdField}`]
            )
          }
          crnErrorClass()
        }
      },
      deep: true
    },
    taxIdValues: {
      handler() {
        this.taxIDTypeInputData.forEach(
          (
            { taxIdValue, taxIdTypeValue, identificationId, evidenceId },
            index
          ) => {
            // Mandatory Check
            this.taxIDTypeInputData[index].isMandatory = !(
              this.isNullValue(taxIdTypeValue?.value) &&
              this.isNullValue(taxIdValue)
            )

            // Reset the evidence
            const { type, value } =
              this.identifiersDetails?.taxIdPairs?.find(
                (pair) => pair.identification_id === identificationId
              ) || {}
            const evdKey = `${this.identifiersDetailsEvdIds.TAX_ID} ${
              index + 1
            }`
            const fieldValueNotChanged =
              (identificationId &&
                value === taxIdValue &&
                type === taxIdTypeValue?.value) ||
              (this.isNullValue(taxIdValue) &&
                this.isNullValue(taxIdTypeValue?.value))
            if (fieldValueNotChanged && evidenceId) {
              this.taxIDTypeInputData[index].taxIdError = ''
              this.updateEvidenceIdList(evdKey)
              this.updateLocalEvidenceIdList(evdKey)
              this.updateInputNameMapping(evdKey, evidenceId)
            }
          }
        )
      },
      deep: true
    },
    taxIdTypes: {
      deep: true,
      handler() {
        this.taxIDTypeInputData.forEach(
          ({ taxIdValue, taxIdTypeValue }, index) => {
            this.taxIDTypeInputData[index].isMandatory = !(
              this.isNullValue(taxIdTypeValue?.value) &&
              this.isNullValue(taxIdValue)
            )
          }
        )
      }
    },
    localEvidenceIdList() {
      if (this.initEvidenceListWatcher) {
        this.localEvidenceInitList = this.localEvidenceIdList
        this.initEvidenceListWatcher = false
      }
    },
    bvdIdentifiersDetails: {
      handler() {
        this.syncBVDData()
      },
      deep: true
    },
    entityDetails: {
      handler(newVal) {
        if (newVal.length === 0) return
        this.entityName = this.entityDetails[0]?.entityName
      },
      immediate: true
    }
  },
  async mounted() {
    this.getFormDetails()
  },
  methods: {
    ...mapActions('identifiers', [
      'getIdentifiersDetails',
      'updateIdentifiersDetails',
      'getIdentifiersTaxTypes',
      'getExternalLEIList',
      'getExternalRSSDList',
      'getCIKCodeList',
      'getExternalCRDList',
      'getCrnRegexList'
    ]),
    ...mapActions('evidence', [
      'uploadEvidence',
      'updateEvidenceIdList',
      'resetEvidenceStore',
      'removeEvidence'
    ]),
    ...mapMutations('evidence', {
      updateStoreEvidencesMap: 'SET_STORE_EVIDENCES'
    }),

    ...mapActions('assignedEntities', ['checkAndUpdateTodoStatus']),
    async getFormDetails() {
      try {
        this.loading = true
        this.initEvidenceListWatcher = true
        this.resetEvidenceStore()
        const { sgaId } = this.getEntityDetails

        this.sgaId = sgaId
        await this.getIdentifiersDetails(this.sgaId)
        this.countryIdList = this.identifiersDetails?.countryIds
        await this.getIdentifiersTaxTypes(
          this.countryIdList?.filter((country) => !this.isNullValue(country))
        )

        const countryCode = [234, 41, 131, 275, 32] // US,CA,LU,GB,BR   -----country

        if (
          Array.isArray(countryCode) &&
          countryCode.includes(this.countryIdList[1])
        ) {
          await this.getCrnRegexList(this.countryIdList[1])
          this.validRegexCountry = true
        } else {
          this.validRegexCountry = false
        }
        this.syncIdentifiersDetails()
      } finally {
        this.loading = false
      }
    },

    syncIdentifiersDetails() {
      if (
        this.identifiersDetails &&
        Object.keys(this.identifiersDetails).length > 0
      ) {
        for (const formField of this.fieldMetaData) {
          this.newIdentifiersDetails[formField.key] =
            this.identifiersDetails[formField.key]
          this.newIdentifiersDetails[formField.evdField] =
            this.identifiersDetails[formField.evdField]

          if (
            formField?.evdField &&
            !this.isNullValue(this.identifiersDetails[`${formField.evdField}`])
          ) {
            this.updateEvidenceIdList(formField.evdKey)
            this.updateLocalEvidenceIdList(formField.evdKey)
            this.updateInputNameMapping(
              formField.evdKey,
              this.identifiersDetails[`${formField.evdField}`]
            )
          }
        }

        // Tax ID Pairs Sync
        this.isTaxIdWatcher = true
        this.taxIDTypeInputData = []
        if (
          !this.identifiersDetails?.taxIdPairs ||
          this.identifiersTaxTypes.length == 0
        ) {
          this.addInputPair(true)
          this.taxIDTypeInputData[0].taxIdTypeOption = this.identifiersTaxTypes
          return
        }

        this.identifiersDetails?.taxIdPairs.forEach(
          (
            {
              type,
              value,
              identification_id,
              evidence_id,
              typeCode,
              typeLabel
            },
            index
          ) => {
            let taxIdTypeValue = this.identifiersTaxTypes.find(
              (taxType) => taxType.value == type
            )

            if (!taxIdTypeValue) {
              taxIdTypeValue = {
                value: type,
                text: `${typeCode} - ${typeLabel}`
              }
            }
            this.taxIDTypeInputData.push({
              identificationId: identification_id,
              taxIdTypeValue,
              taxIdValue: value,
              taxIdError: '',
              evidenceId: evidence_id,
              taxIdTypeOption: this.identifiersTaxTypes
            })
          }
        )
        this.syncTaxData()
        this.taxEvidenceInit()
        setTimeout(() => (this.isTaxIdWatcher = false), 1000)
      }
    },
    goBack() {
      // Check if there are unsaved changes
      if (this.hasUnsavedChanges()) {
        this.confirmationDialogData.visible = true
        this.confirmationDialogData.title = 'Discard changes?'
        this.confirmationDialogData.text =
          'Would you like to discard the changes & go back <br>to Industry Details screen?'
        this.confirmationDialogData.triggerFunction = this.goToBackStep
      } else {
        this.goToBackStep()
      }
    },
    goToBackStep() {
      this.updateStepper(this.activeStepIndexName.industryDetails)
    },
    hasUnsavedChanges(isSubmission = false) {
      let fieldValueNotChanged = true
      const isInitData = Object.keys(this.identifiersDetails).length != 0
      for (const formField of this.fieldMetaData) {
        const fieldKey = formField.key
        if (!isInitData) {
          // Check if init data is null and field value is changed
          fieldValueNotChanged = this.isNullValue(
            this.newIdentifiersDetails[fieldKey]
          )
        } else {
          fieldValueNotChanged =
            (this.isNullValue(this.identifiersDetails[fieldKey]) &&
              this.isNullValue(this.newIdentifiersDetails[fieldKey])) ||
            this.identifiersDetails[fieldKey] ===
              this.newIdentifiersDetails[fieldKey]
        }
        if (
          this.submitted &&
          ((formField?.evdKey &&
            !fieldValueNotChanged &&
            !this.getEvidenceFormStatus(formField.evdKey)) ||
            (this.localEvidenceIdList.find(
              (inputName) => inputName === formField.evdKey
            ) &&
              !this.evidenceIdList.includes(formField.evdKey)))
        ) {
          this.evdErrorMsg[
            fieldKey
          ] = `Please upload evidence for ${formField.evdKey}`
        } else {
          this.evdErrorMsg[fieldKey] = null
        }
        if (fieldValueNotChanged) {
          fieldValueNotChanged =
            this.localEvidenceIdList.includes(formField.evdKey) ===
            this.evidenceIdList.includes(formField.evdKey)
        }
        if (isSubmission) continue
        if (!fieldValueNotChanged) break
      }

      // Tax ID - Type pair changes
      if (fieldValueNotChanged || isSubmission) {
        for (let index = 0; index < this.taxIDTypeInputData.length; index++) {
          const { taxIdValue, taxIdTypeValue, identificationId } =
            this.taxIDTypeInputData[index]
          const { type, value } =
            this.identifiersDetails?.taxIdPairs?.find(
              (pair) => pair.identification_id === identificationId
            ) || {}
          fieldValueNotChanged = identificationId
            ? value === taxIdValue && type === taxIdTypeValue?.value
            : this.isNullValue(taxIdValue) &&
              this.isNullValue(taxIdTypeValue?.value)
          const evdKey = `${this.identifiersDetailsEvdIds.TAX_ID} ${index + 1}`
          if (
            this.submitted &&
            !fieldValueNotChanged &&
            !this.getEvidenceFormStatus(evdKey)
          ) {
            this.taxIDTypeInputData[
              index
            ].taxIdError = `Please upload evidence for ${evdKey}`
          } else {
            this.taxIDTypeInputData[index].taxIdError = null
          }

          if (isSubmission) continue
          if (!fieldValueNotChanged) break
        }
      }
      return !fieldValueNotChanged
    },
    async saveData(isFinalSubmit = false) {
      let isIdentifiersUpdated = false
      this.submitted = true
      let haveNotEvdError = false
      this.isMandatory = isFinalSubmit
      const isValidFormData =
        this.isValidForm &&
        (isFinalSubmit && this.isINAIMandatory ? this.isMandatoryValid : true)
      haveNotEvdError =
        Object.values(this.evdErrorMsg).every((msg) => msg === null) &&
        this.taxIDTypeInputData.every((taxPair) => taxPair.taxIdError === null)
      const isFormValidated = isValidFormData && haveNotEvdError
      const processForm =
        isFormValidated && this.taxIdPairValidation(isFormValidated)
      if (processForm) {
        const identifiersFormFields = {
          taxIdPairs: []
        }
        for (const formField of this.fieldMetaData) {
          identifiersFormFields[formField.key] = this.isNullValue(
            this.newIdentifiersDetails[formField.key]
          )
            ? null
            : this.newIdentifiersDetails[formField.key]
        }
        try {
          this.loading = true
          // Final submit of evidence upload
          const evidenceIdMappingResponse = await this.uploadEvidence()
          this.updateEvidenceIdMapping(
            evidenceIdMappingResponse,
            identifiersFormFields
          )

          // Check entity status and update it to In Progress, if it's Todo
          await this.checkAndUpdateTodoStatus()

          const updateIdentifiersPayload = {
            sgaId: this.sgaId,
            identifiersFormFields
          }
          const identifiersUpdateRes = await this.updateIdentifiersDetails(
            updateIdentifiersPayload
          )
          if (identifiersUpdateRes === 200) {
            isIdentifiersUpdated = true
            this.snackbarPayload.type = 'is-success'
            this.snackbarPayload.message =
              'Identifiers records updated successfully'
          } else {
            throw new Error()
          }
          this.loading = false
          this.submitted = false
        } catch (error) {
          this.snackbarPayload.type = 'is-danger'
          this.snackbarPayload.message = 'Identifiers records not updated'
          this.loading = false
        }
        this.isMandatory = false
        // Snackbar for submission status failure/success
        Snackbar(this.snackbarPayload)
        this.syncIdentifiersDetails()
      }
      return processForm && isIdentifiersUpdated
    },
    async saveAndNext() {
      const saveDataStatus = await this.saveData(true)
      if (saveDataStatus) {
        this.updateStepper(this.activeStepIndexName.anaCreditDetails)
      }
    },
    updateEvidenceIdMapping(
      evidenceIdMappingResponse,
      payloadData,
      isForIdentifiers = false
    ) {
      const evidenceIdMapping = {}
      if (
        evidenceIdMappingResponse &&
        Array.isArray(evidenceIdMappingResponse)
      ) {
        evidenceIdMappingResponse.forEach((item) => {
          if (item.evidenceIds && item.evidenceIds.length > 0) {
            const evidenceId = item ? Number(item.evidenceIds[0].id) : null
            evidenceIdMapping[item.attributeName] = evidenceId
          }
        })
      }

      if (!isForIdentifiers) {
        for (const formField of this.fieldMetaData) {
          if (!formField?.evdKey) continue
          payloadData[`${formField.evdField}`] =
            this.isEvidencePresentInInputMapping(formField.evdKey)
              ? evidenceIdMapping[formField.evdKey] ||
                this.newIdentifiersDetails[formField.evdField]
              : null
        }
      }
      this.taxIDTypeInputData.forEach(
        ({ taxIdTypeValue, taxIdValue, evidenceId }, index) => {
          const evidenceKey = `${this.identifiersDetailsEvdIds.TAX_ID} ${
            index + 1
          }`

          if (isForIdentifiers) {
            this.taxIDTypeInputData[index].evidenceId =
              this.isEvidencePresentInInputMapping(evidenceKey)
                ? evidenceIdMapping[evidenceKey] || evidenceId
                : null
          }

          if (
            !isForIdentifiers &&
            ![taxIdTypeValue, taxIdValue, evidenceId].every((item) =>
              this.isNullValue(item)
            )
          ) {
            payloadData.taxIdPairs.push({
              taxType: taxIdTypeValue?.value || null,
              taxValue: taxIdValue,
              evidenceId: this.isEvidencePresentInInputMapping(evidenceKey)
                ? evidenceIdMapping[evidenceKey] || evidenceId
                : null
            })
          }
        }
      )
      return payloadData
    },
    updateIncorporationAuthority(inai) {
      this.newIdentifiersDetails.inai = inai
      if (
        !isEqual(this.newIdentifiersDetails.inai, this.identifiersDetails?.inai)
      ) {
        this.removeEvidence(this.identifiersDetailsEvdIds.INAI)
      }
    },
    updateCompanyRegistrationNumber(crn) {
      this.newIdentifiersDetails.crn = crn

      this.crnRegexList.some((item) => {
        this.validRegex = item.regex.some((regexString) => {
          const pattern = new RegExp(`^(${regexString})$`)
          return pattern.test(crn)
        })
      })

      if (
        !isEqual(this.newIdentifiersDetails.crn, this.identifiersDetails?.crn)
      ) {
        this.removeEvidence(this.identifiersDetailsEvdIds.CRN)
      }
    },

    updateLegalEntityIdentifier(lei) {
      this.newIdentifiersDetails.lei = lei
      if (
        !isEqual(this.newIdentifiersDetails.lei, this.identifiersDetails?.lei)
      ) {
        this.removeEvidence(this.identifiersDetailsEvdIds.LEI)
      }
    },
    checkIfNA(value) {
      return this.naData.some(
        (item) => item?.toLowerCase() === value?.trim().toLowerCase()
      )
    },
    updateRssd(rssd) {
      if (rssd) {
        this.checkIfNA(rssd)
      }
      this.newIdentifiersDetails.rssd = rssd
      if (
        !isEqual(this.newIdentifiersDetails.rssd, this.identifiersDetails?.rssd)
      ) {
        this.removeEvidence(this.identifiersDetailsEvdIds.RSSD)
      }
    },
    updateCik(cik) {
      this.newIdentifiersDetails.cik = cik
      if (
        !isEqual(this.newIdentifiersDetails.cik, this.identifiersDetails?.cik)
      ) {
        this.removeEvidence(this.identifiersDetailsEvdIds.CIK)
      }
    },
    updateCRD(crd) {
      if (crd) {
        this.checkIfNA(crd)
      }
      this.newIdentifiersDetails.crd = crd
      if (
        !isEqual(this.newIdentifiersDetails.crd, this.identifiersDetails?.crd)
      ) {
        this.removeEvidence(this.identifiersDetailsEvdIds.CRD)
      }
    },
    updateTaxIDType(taxIdTypeValue, taxIndex) {
      this.$set(
        this.taxIDTypeInputData[taxIndex],
        'taxIdTypeValue',
        taxIdTypeValue
      )
      this.$set(this.taxIdTypes, taxIndex, taxIdTypeValue)
      if (!this.isTaxIdWatcher) this.updateTaxID(null, taxIndex)
      this.updateDropdownOptions()
    },
    updateTaxID(taxIdValue, taxIndex) {
      this.$set(this.taxIDTypeInputData[taxIndex], 'taxIdValue', taxIdValue)
      this.$set(this.taxIdValues, taxIndex, taxIdValue)
      const originValue =
        this.identifiersDetails?.taxIdPairs?.find(
          (pair) =>
            pair.identification_id ===
            this.taxIDTypeInputData[taxIndex].identificationId
        ) || null
      if (
        !isEqual(
          originValue?.value,
          this.taxIDTypeInputData[taxIndex].taxIdValue
        )
      ) {
        this.removeEvidence(
          `${this.identifiersDetailsEvdIds.TAX_ID} ${taxIndex + 1}`
        )
      }
    },
    closeDialog() {
      this.confirmationDialogData = {
        ...this.confirmationDialogData,
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      }
    },
    async toggleEvidenceForm(inputName) {
      const isViewEvidence = this.getEvidenceFormStatus(inputName)
      const evidenceIds = this.getEvidenceIds(inputName)

      this.$emit('toggleEvidenceForm', {
        inputName,
        isViewEvidence,
        evidenceIds: evidenceIds || []
      })
    },
    getEvidenceIds(evidenceId) {
      return this.inputNameMapping[`${evidenceId}`]
    },
    updateInputNameMapping(inputName, evidenceId) {
      this.$set(this.inputNameMapping, inputName, evidenceId)
    },
    isEvidenceBtnDisabled(inputName, subField = null) {
      if (subField) {
        const currentIndex = subField.index
        const currentSubFieldEvdKey = `${
          this.identifiersDetailsEvdIds.TAX_ID
        } ${currentIndex + 1}`
        const currentSubField = this.taxIDTypeInputData[currentIndex]
        const fetchedValue = this.identifiersDetails?.taxIdPairs?.find(
          (pair) => pair.identification_id === currentSubField.identificationId
        )
        if (this.getEvidenceFormStatus(currentSubFieldEvdKey)) {
          // Return false for View Evidence
          return false
        } else if (
          fetchedValue &&
          !this.isNullValue(currentSubField?.identificationId)
        ) {
          return (
            (this.isNullValue(fetchedValue?.type)
              ? this.isNullValue(currentSubField.taxIdTypeValue?.value)
              : fetchedValue?.type === currentSubField.taxIdTypeValue?.value) &&
            (this.isNullValue(fetchedValue?.value)
              ? this.isNullValue(currentSubField?.taxIdValue)
              : fetchedValue?.value === currentSubField?.taxIdValue)
          )
        } else {
          return (
            this.isNullValue(currentSubField?.taxIdValue) &&
            this.isNullValue(currentSubField.taxIdTypeValue?.value)
          )
        }
      }
      const currentField = this.fieldMetaData.find(
        (field) => field.key === inputName && field?.evdKey
      )
      if (this.getEvidenceFormStatus(currentField.evdKey)) {
        // view evidence always enabled
        return false
      }
      if (
        currentField &&
        this.isNullValue(this.identifiersDetails[`${currentField.evdField}`])
      ) {
        return this.isNullValue(this.identifiersDetails[currentField.key])
          ? this.isNullValue(this.newIdentifiersDetails[currentField.key])
          : this.identifiersDetails[currentField.key] ===
              this.newIdentifiersDetails[currentField.key]
      } else {
        return false
      }
    },
    isEvidencePresentInInputMapping(inputName) {
      return this.evidenceIdList.includes(inputName)
    },
    isNullValue(value) {
      return [null, '', undefined].includes(value)
    },
    updateLocalEvidenceIdList(inputName) {
      const evidenceIdSet = new Set([...this.localEvidenceIdList, inputName])
      this.localEvidenceIdList = [...evidenceIdSet]
    },
    evdMethodsForSubField(index) {
      return {
        toggleEvidence: () => {
          this.toggleEvidenceForm(
            `${this.identifiersDetailsEvdIds.TAX_ID} ${index + 1}`
          )
        },
        evidenceFormStatus: () => {
          return this.getEvidenceFormStatus(
            `${this.identifiersDetailsEvdIds.TAX_ID} ${index + 1}`
          )
        },
        btnLoadingStatus: () => {
          return this.getBtnLoadingStatus(
            `${this.identifiersDetailsEvdIds.TAX_ID} ${index + 1}`
          )
        },
        isDisabled: () => {
          return this.isEvidenceBtnDisabled('taxId' + index, { index })
        }
      }
    },
    addInputPair(isSync = false) {
      // Tax ID pair can be added only as Tax ID Types
      let selectedValues = this.taxIDTypeInputData.filter((item) => {
        return this.identifiersTaxTypes.find(
          (option) => option.value === item.taxIdTypeValue?.value
        )
      })
      let availableOptions = this.identifiersTaxTypes.filter(
        (option) =>
          !selectedValues.includes(option.value) ||
          option.value === item.taxIdTypeValue?.value
      )
      let addedPairs = this.taxIDTypeInputData.filter((pair) =>
        this.isNullValue(pair.identificationId)
      )
      if (
        !isSync &&
        (this.identifiersTaxTypes.length > 0
          ? selectedValues.length === availableOptions.length ||
            addedPairs.length >= availableOptions.length
          : this.taxIDTypeInputData.length === 1)
      ) {
        this.snackbarPayload.message = 'Reached the maximum Tax ID Type limits'
        Snackbar(this.snackbarPayload)
        return
      }
      this.taxIDTypeInputData.push({
        taxIdTypeValue: null,
        taxIdValue: null,
        taxIdError: null,
        taxIdTypeOption: [],
        identificationId: null,
        evidenceId: null,
        isMandatory: false
      })
      this.updateDropdownOptions()
    },
    async removeInputPair(index) {
      this.isTaxIdWatcher = true
      this.loading = true

      await this.uploadIdentifierEvidence()
      const tempStoreEvidences = new Map(this.storeEvidences)
      const alreadyPresentEvidenceName = this.localEvidenceInitList
      const taxIdentifierPairLen = this.taxIDTypeInputData.length

      this.removeEvd()
      this.taxIDTypeInputData.splice(index, 1)
      if (this.taxIDTypeInputData.length === 0) {
        this.addInputPair()
      }
      this.syncTaxData()
      this.$nextTick(() => {
        this.taxEvidenceInit()
        this.reAddEvidence(
          tempStoreEvidences,
          index,
          alreadyPresentEvidenceName,
          taxIdentifierPairLen
        )
        this.hasUnsavedChanges()
        this.isTaxIdWatcher = false
        this.loading = false
      })
      this.updateDropdownOptions()
    },
    updateDropdownOptions() {
      const selectedValues = this.taxIDTypeInputData.map(
        (item) => item.taxIdTypeValue?.value
      )

      this.taxIDTypeInputData.forEach((item, index) => {
        const availableOptions = this.identifiersTaxTypes.filter(
          (option) =>
            !selectedValues.includes(option.value) ||
            option.value === item.taxIdTypeValue?.value
        )

        // Update options for the current item
        this.$set(
          this.taxIDTypeInputData[index],
          'taxIdTypeOption',
          availableOptions
        )
      })
    },
    taxEvidenceInit() {
      // Re-initialize the evidence as updated index
      this.taxIDTypeInputData.forEach(({ evidenceId }, index) => {
        if (!this.isNullValue(evidenceId)) {
          const currentIndex = index
          const currentSubFieldEvdKey = `${
            this.identifiersDetailsEvdIds.TAX_ID
          } ${currentIndex + 1}`
          this.updateEvidenceIdList(currentSubFieldEvdKey)
          this.updateLocalEvidenceIdList(currentSubFieldEvdKey)
          this.updateInputNameMapping(currentSubFieldEvdKey, evidenceId)
        }
      })
    },
    removeEvd() {
      // Reset All Evidences
      this.taxIDTypeInputData.forEach(({}, index) => {
        // Reset Store
        this.removeEvidence(
          `${this.identifiersDetailsEvdIds.TAX_ID} ${index + 1}`
        )

        // Reset Local Evidence List
        const tmpEvdList = this.localEvidenceIdList.filter(
          (id) => !id.includes(this.identifiersDetailsEvdIds.TAX_ID)
        )
        this.localEvidenceIdList = tmpEvdList

        // Reset Local `inputNameMapping`
        const tmpEvdInputMapping = {}
        tmpEvdList.map((item) => {
          tmpEvdInputMapping[item] = this.inputNameMapping[item]
        })
        this.inputNameMapping = tmpEvdInputMapping
      })
    },
    syncTaxData() {
      this.taxIdTypes = {}
      this.taxIdValues = {}
      this.taxIDTypeInputData.forEach(
        ({ taxIdTypeValue, taxIdValue }, index) => {
          this.$set(this.taxIdValues, index, taxIdValue)
          this.$set(this.taxIdTypes, index, taxIdTypeValue)
        }
      )
    },
    reAddEvidence(
      tempStoreEvidences,
      deletedIndex,
      alreadyPresentEvidenceName,
      taxIdentifierPairLen
    ) {
      // return if there are no evidence stored in local
      if (tempStoreEvidences.size === 0) {
        return
      }

      for (let index = 0; index < taxIdentifierPairLen; index++) {
        const evidence = tempStoreEvidences.get(
          `${this.identifiersDetailsEvdIds.TAX_ID} ${index + 1}`
        )
        // skip if the evidence is from backend present
        if (
          alreadyPresentEvidenceName?.includes(
            `${this.identifiersDetailsEvdIds.TAX_ID} ${index + 1}`
          ) &&
          !tempStoreEvidences.get(
            `${this.identifiersDetailsEvdIds.TAX_ID} ${index + 1}`
          )
        ) {
          continue
        }

        if (index < deletedIndex) {
          const newEvidence = {
            ...evidence,
            evidenceId: `${this.identifiersDetailsEvdIds.TAX_ID} ${index + 1}`
          }
          this.updateStoreEvidencesMap(newEvidence)
        } else if (index > deletedIndex && evidence) {
          const newEvidence = {
            ...evidence,
            evidenceId: `${this.identifiersDetailsEvdIds.TAX_ID} ${index}`
          }
          this.updateStoreEvidencesMap(newEvidence)
        }
      }
    },
    async uploadIdentifierEvidence() {
      const evidenceIdMappingResponse = await this.uploadEvidence()
      this.updateEvidenceIdMapping(evidenceIdMappingResponse, null, true)
    },

    taxIdPairValidation(isFormValid) {
      // Unique Tax ID Type
      const uniqueTaxIdType = uniqBy(
        this.taxIDTypeInputData.map((item) => item.taxIdTypeValue),
        'value'
      )
      const limitValid =
        this.taxIDTypeInputData.length <= this.identifiersTaxTypes?.length
      const uniqValid =
        uniqueTaxIdType.length === this.taxIDTypeInputData.length
      const isValidPairs = limitValid && uniqValid
      if (!isValidPairs && isFormValid) {
        this.snackbarPayload.message = !uniqValid
          ? 'Duplicate Tax ID Types Are Not Allowed'
          : 'Reached the maximum Tax ID Type limits'
        this.snackbarPayload.type = 'is-danger'
        Snackbar(this.snackbarPayload)
      }

      // ==== Country Validation ====
      // Check the selected types belongs to current country options
      let countryNotBelongCount = 0
      const availableOptions = this.identifiersTaxTypes?.map((opt) => opt.value)
      uniqueTaxIdType.forEach((opt) => {
        if (opt && !availableOptions.includes(opt?.value)) {
          countryNotBelongCount++
        }
      })

      const countryValidation = countryNotBelongCount === 0
      if (!countryValidation) {
        Snackbar({
          message: `Please select valid tax identifier type as per country`,
          type: 'is-warning',
          indefinite: true
        })
      }
      // ==XX Country Validation XX==

      return isValidPairs && countryValidation
    },

    syncBVDData() {
      this.isTaxIdWatcher = true
      for (const key in this.bvdIdentifiersDetails) {
        switch (key) {
          case 'identifier':
            this.taxIDTypeInputData = this.taxIDTypeInputData.filter(
              ({ taxIdValue, taxIdTypeValue }) =>
                !(
                  this.isNullValue(taxIdValue) &&
                  this.isNullValue(taxIdTypeValue?.value)
                )
            )

            this.bvdIdentifiersDetails[key].forEach(
              ({ value, type }, index) => {
                let identifierType = this.identifiersTaxTypes.find((taxType) =>
                  taxType.text.includes(type)
                )
                const isAlreadyExists = this.taxIDTypeInputData.find((pair) => {
                  return (
                    pair.taxIdValue == value &&
                    pair.taxIdTypeValue?.value == identifierType?.value
                  )
                })

                if (isAlreadyExists?.taxIdValue) return

                this.taxIDTypeInputData.push({
                  identificationId: null,
                  taxIdTypeValue: identifierType,
                  taxIdValue: value,
                  taxIdError: '',
                  taxIdTypeOption: this.identifiersTaxTypes,
                  evidenceId: null
                })
                const currentPairIndex = this.taxIDTypeInputData.length - 1

                this.taxIdTypes = {
                  ...this.taxIdTypes,
                  [currentPairIndex]: identifierType
                }
                this.taxIdValues = {
                  ...this.taxIdValues,
                  [currentPairIndex]: value
                }
              }
            )

            if (this.taxIDTypeInputData.length === 0) {
              this.addInputPair()
            }
            break
          default:
            if (!this.isNullValue(this.bvdIdentifiersDetails[key]?.value)) {
              this.$set(
                this.newIdentifiersDetails,
                key,
                this.bvdIdentifiersDetails[key]?.value
              )
            }
        }
      }
      this.$nextTick(() => {
        this.isTaxIdWatcher = false
      })
    },

    async openSearchDialog(field) {
      this.loading = true
      try {
        switch (field) {
          case this.identifiersFields.LEI:
            this.selectableGridOptions = {
              ...this.selectableGridOptions,
              title: 'Select LEI',
              selectValue: 'LEI',
              formFieldName: 'lei',
              isAllColFilterable: true,
              hyperlinksCol: [
                {
                  column: 'LEI',
                  generator: (value) =>
                    `https://search.gleif.org/#/record/${value}`
                }
              ]
            }
            await this.getExternalLEIList(this.entityName)
            break
          case this.identifiersFields.RSSD:
            this.selectableGridOptions = {
              ...this.selectableGridOptions,
              title: 'Select RSSD',
              selectValue: 'RSSD',
              formFieldName: 'rssd',
              isAllColFilterable: true,
              pageSize: 5, // Overiding default page size to 5
              hyperlinksCol: [
                {
                  column: 'RSSD',
                  generator: (value) =>
                    `https://www.ffiec.gov/npw/Institution/Profile/${value}`
                }
              ]
            }
            await this.getExternalRSSDList(this.entityName)
            break
          case this.identifiersFields.CRD:
            this.selectableGridOptions = {
              ...this.selectableGridOptions,
              title: 'Select CRD',
              selectValue: 'CRD',
              formFieldName: 'crd',
              isAllColFilterable: true,
              hyperlinksCol: [
                {
                  column: 'CRD',
                  generator: (value) =>
                    `https://adviserinfo.sec.gov/firm/summary/${value}`
                }
              ]
            }
            await this.getExternalCRDList(this.entityName)
            break
          case this.identifiersFields.CIK:
            this.selectableGridOptions = {
              ...this.selectableGridOptions,
              title: 'Select CIK',
              selectValue: 'CIK',
              formFieldName: 'cik',
              isAllColFilterable: true,
              hyperlinksCol: [
                {
                  column: 'CIK',
                  generator: (value) =>
                    `https://www.sec.gov/edgar/browse/?CIK=${value}`
                }
              ]
            }
            await this.getCIKCodeList(this.entityName)
        }

        this.$nextTick(() => {
          this.loading = false
          // If any records not found
          if (this.selectableGridData?.length == 0) {
            Snackbar({
              message: 'No data available',
              type: 'is-warning'
            })
          }
          this.isSearchDialogVisible = true
        })
      } catch (error) {
        Snackbar({
          message: 'Failed to fetch data.',
          type: 'is-danger'
        })
      } finally {
        this.loading = false
      }
    },

    closeSearchDialog() {
      this.isSearchDialogVisible = false
    },

    searchDialogData(formField, fieldValue) {
      this.newIdentifiersDetails[formField] = fieldValue
    }
  }
}
</script>

<style lang="scss" scoped>
.identifiers-details-section::v-deep {
  font-family: Quicksand;
  margin-top: 1rem;
  position: relative;
  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    background: rgba(0, 0, 0, 0.1);
    z-index: 9;
    .inava-loader {
      position: relative;
      display: flex;
      margin: auto;
      border-radius: 50%;
      border: none;
      z-index: 900;
      width: 70px;
      height: 70px;
      background-image: '@/assets/Inava-logo-GIF.gif';
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .section-title {
    font-size: 21px;
    font-weight: 600;
    color: #00218a;
  }
  .mandatory-note {
    & p {
      color: #7d7d7d;
      font-size: 14px;
      letter-spacing: 0.14px;
      font-weight: 400;
    }
    .mandatory {
      color: red;
      font-size: 17px;
    }
  }

  .identifiers-form,
  .tax-input-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 92%;

    .column-view {
      width: 45%;
      margin-top: 2rem;
      display: flex;
      flex-direction: row;

      .required-error {
        position: relative;
      }
      .form-group {
        p {
          display: flex;
          color: #7d7d7d;
          font-size: 16px;
          font-family: Quicksand;
          padding: 0 20px 0 0;
          line-height: 20px;
          margin: 0 0 0.25rem;
        }
        .input {
          text-align: left;
          font-size: 18px;
          font-weight: 600;
          color: #1e1e1e;
          opacity: 1;
          border-color: #cccccc;
        }
      }
      .input-field {
        width: 75%;
      }
      .form-input-field {
        width: 75%;
      }
      .Searchable-select {
        width: 75%;
      }
      .add-evidence {
        display: flex;
        width: 25%;
        margin-top: 1.65rem;
      }
      .add-evidence-crn {
        width: 25%;
        display: flex;
        align-items: flex-end;
      }
      .evidence-error {
        width: 25%;
        padding-bottom: 1rem;
        display: flex;
        align-items: flex-end;
      }
      .crnAlphaNumError {
        width: 25%;
        padding-bottom: 1.4rem;
        display: flex;
        align-items: flex-end;
      }
      .crnMaxError {
        width: 25%;
        padding-bottom: 1.4rem;
        display: flex;
        align-items: flex-end;
      }
      .combined-error-crn {
        width: 25%;
        padding-bottom: 2rem;
        display: flex;
        align-items: flex-end;
      }
      .combineAlphaNumEvidError {
        width: 25%;
        padding-bottom: 2.5rem;
        display: flex;
        align-items: flex-end;
      }
      .combineMaxEvidError {
        width: 25%;
        padding-bottom: 2.5rem;
        display: flex;
        align-items: flex-end;
      }
      .search-button {
        margin-left: 0.5rem;
        margin-top: 1.6rem;
        background: var(--inava-primary);
        color: white;
        cursor: pointer;
        border: 0px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }

    .tax-input-view {
      width: 92%;
      display: flex;
      justify-content: space-between;
    }

    .tax-button-view {
      min-width: 6%;
      display: flex;
      align-items: start;
      justify-content: space-around;
      margin-top: 3.8rem;
      .add-tax-button {
        background: var(--inava-primary);
        color: white;
        cursor: pointer;
        border: 0px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }
  }

  .tax-input-container {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .identifiers-details-section::v-deep {
    .tax-input-container {
      flex-direction: column;
      .tax-button-view {
        width: 20% !important;
        margin-top: 2rem;
      }

      .crnMaxError {
        width: 25%;
        padding-bottom: 2.8rem;
        display: flex;
        align-items: flex-end;
      }
    }
  }
}

@media (max-width: 1449px) {
  .identifiers-details-section::v-deep {
    .identifiers-form,
    .tax-input-container {
      .column-view {
        width: 100%;
        .form-group {
          p {
            font-size: 12px;
          }
          .crnMaxError {
            width: 25%;
            padding-bottom: 2.8rem;
            display: flex;
            align-items: flex-end;
          }
        }
      }
    }

    .tax-input-container {
      flex-wrap: wrap;
    }
  }
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .identifiers-details-section {
    border-radius: 6px;
    .section-title {
      font-size: 24px;
    }
    .mandatory-note {
      p {
        font-size: 14px;
        letter-spacing: 0.14px;
      }
    }
    .identifiers-form,
    .tax-input-container {
      .column-view {
        .form-group {
          p {
            font-size: 14px;
          }
          .input {
            font-size: 16px;
            font-weight: 600;
          }
        }
        .form-control-disabled > .form-control {
          .input {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media (min-width: 1700px) {
  .identifiers-details-section {
    .section-title {
      font-size: 26px;
    }
    .mandatory-note {
      p {
        font-size: 14px;
        letter-spacing: 0.14px;
      }
    }
    .identifiers-form,
    .tax-input-container {
      .column-view {
        width: 46%;
        .radio-toolbar {
          p {
            font-size: 16px;
            font-weight: 600;
          }
          label {
            font-size: 16px;
            font-weight: 600;
          }
        }
        .form-group {
          p {
            font-size: 16px;
          }
          .input {
            font-size: 18px;
            font-weight: 600;
          }
        }
        .form-control-disabled > .form-control {
          .input {
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
