<template>
  <div>
    <k-dialog
      v-if="visibleDialog"
      :title="'File Upload'"
      :minWidth="700"
      @close="closeFileUploadDialog"
    >
      <div class="dialog-container">
        <div class="dialog-row action-bar">
          <div class="action-message-label">Select action type:</div>
          <radiogroup
            :value="selectedActionType"
            :data-items="fileUploadOptions"
            :layout="'horizontal'"
            @change="handleChange"
          />
        </div>
        <div class="action-bar-info-message">
          <div>
            <span
              ><b-icon icon="information" size="is-small" class="toolbar-icons">
              </b-icon>
              <strong> Note:</strong>&nbsp;<span class="description-message">{{
                getActionDescription
              }}</span></span
            >
          </div>
        </div>
        <div class="dialog-row upload-section">
          <div class="template-file">
            <a href="#" @click.prevent="generateSampleExcel">
              Download Template File
            </a>
          </div>
          <template>
            <section>
              <b-field>
                <b-upload
                  v-model="selectedFile"
                  :loading="loading"
                  :accept="acceptableFiles"
                  @input="onFileChange"
                  drag-drop
                >
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"> </b-icon>
                      </p>
                      <p>Click to upload or drag & drop</p>
                      <span class="drag-message-info"
                        >(Only CSV and Excel files are allowed)</span
                      >
                    </div>
                  </section>
                </b-upload>
              </b-field>
              <div class="tags">
                <span v-if="selectedFile" class="tag is-primary">
                  {{ selectedFile.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteSelectedFile"
                  ></button>
                </span>
              </div>
            </section>
          </template>
        </div>
        <div v-if="this.isFileValid" class="valid-file-notification">
          <b-icon
            icon="check-circle"
            class="has-text-success"
            size="is-small"
          ></b-icon>
          <span class="has-text-success"
            >&nbsp; File is ready to be uploaded.</span
          >
        </div>
        <div v-if="validationMessage" class="validation-message">
          <b-icon icon="alert-circle-outline" class="has-text-danger"></b-icon>
          <span class="has-text-danger">{{ validationMessage }}</span>
        </div>
      </div>
      <dialog-actions-bar>
        <div class="action-buttons">
          <b-button
            class="action-button cancel-button"
            @click="closeFileUploadDialog"
            :disabled="isDialogInUpdateState"
            >Cancel</b-button
          >
          <b-button
            class="action-button confirm-button button-disabled"
            :class="{
              'button-disabled': !isFileValid || isDialogInUpdateState
            }"
            @click="handleUploadFile"
            :disabled="!isFileValid || isDialogInUpdateState"
            :loading="isDialogInUpdateState"
          >
            Upload
          </b-button>
        </div>
      </dialog-actions-bar>
    </k-dialog>
  </div>
</template>
<script>
import Snackbar from '@/components/Snackbar'
import { entityStatus } from '@/constant/data.js'
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'
import { RadioGroup } from '@progress/kendo-vue-inputs'
import ExcelJS from 'exceljs'
import Papa from 'papaparse'
import { mapActions, mapState } from 'vuex'

const AVAILABLE_ACTIONS = {
  ALLOCATION: 'allocation',
  REWORK: 'rework',
  STAGING: 'staging',
  STAGING_TO_SUBMIT: 'staging_to_submit',
  MOVE_TO_ONHOLD: 'move_to_onhold',
  REMOVE_FROM_ONHOLD: 'remove_from_onhold'
}

const HEADERS = {
  SGA_ID: 'SGA ID',
  EMAIL_ID: 'Email ID',
  STATUS: 'Status',
  ONHOLD_REASONS: 'On-Hold Reasons'
}

const ACTION_DESCRIPTIONS = {
  [AVAILABLE_ACTIONS.ALLOCATION]:
    'Load Entities or assign entities which are in "Todo" status.',
  [AVAILABLE_ACTIONS.REWORK]: `Move entities from "${entityStatus.SUBMIT}" to "${entityStatus.REWORK}" status.`,
  [AVAILABLE_ACTIONS.STAGING]: `Move entities from "${entityStatus.SUBMIT}" to "${entityStatus.STAGING}" status.`,
  [AVAILABLE_ACTIONS.STAGING_TO_SUBMIT]: `Move entities from "${entityStatus.STAGING}" to "${entityStatus.SUBMIT}" status.`,
  [AVAILABLE_ACTIONS.MOVE_TO_ONHOLD]: `Move entities to On-Hold.`,
  [AVAILABLE_ACTIONS.REMOVE_FROM_ONHOLD]: `Remove entities from On-Hold.`
}

export default {
  components: {
    'k-dialog': Dialog,
    'dialog-actions-bar': DialogActionsBar,
    radiogroup: RadioGroup
  },
  props: {
    showDialog: {
      type: Boolean
    },
    closeDialog: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      visibleDialog: this.showDialog,
      isDialogInUpdateState: this.isDialogInUpdate,
      selectedActionType: 'allocation',
      fileUploadOptions: [
        {
          label: 'Entities to Load',
          value: AVAILABLE_ACTIONS.ALLOCATION
        },
        {
          label: 'Submit to Rework',
          value: AVAILABLE_ACTIONS.REWORK
        },
        {
          label: 'Submit to Staging',
          value: AVAILABLE_ACTIONS.STAGING
        },
        {
          label: 'Staging to Submit',
          value: AVAILABLE_ACTIONS.STAGING_TO_SUBMIT
        },
        {
          label: 'Move to On-Hold',
          value: AVAILABLE_ACTIONS.MOVE_TO_ONHOLD
        },
        {
          label: 'Remove from On-Hold',
          value: AVAILABLE_ACTIONS.REMOVE_FROM_ONHOLD
        }
      ],
      acceptableFiles: '.csv,.xlsx,.xls',
      maxFileSize: 1 * 1024 * 1024, // 1 MB
      selectedFile: null,
      loading: false,
      validationMessage: '', // Add this property to store the validation message
      isFileValid: false,
      fileConfigs: {
        [AVAILABLE_ACTIONS.ALLOCATION]: {
          headers: [HEADERS.SGA_ID, HEADERS.EMAIL_ID, HEADERS.STATUS],
          requiredHeaders: [HEADERS.SGA_ID, HEADERS.EMAIL_ID, HEADERS.STATUS]
        },
        [AVAILABLE_ACTIONS.REWORK]: {
          headers: [HEADERS.SGA_ID, HEADERS.EMAIL_ID, HEADERS.STATUS],
          requiredHeaders: [HEADERS.SGA_ID, HEADERS.EMAIL_ID, HEADERS.STATUS]
        },
        [AVAILABLE_ACTIONS.STAGING]: {
          headers: [HEADERS.SGA_ID, HEADERS.EMAIL_ID, HEADERS.STATUS],
          requiredHeaders: [HEADERS.SGA_ID, HEADERS.EMAIL_ID, HEADERS.STATUS]
        },
        [AVAILABLE_ACTIONS.STAGING_TO_SUBMIT]: {
          headers: [HEADERS.SGA_ID, HEADERS.EMAIL_ID, HEADERS.STATUS],
          requiredHeaders: [HEADERS.SGA_ID, HEADERS.EMAIL_ID, HEADERS.STATUS]
        },
        [AVAILABLE_ACTIONS.MOVE_TO_ONHOLD]: {
          headers: [HEADERS.SGA_ID, HEADERS.ONHOLD_REASONS],
          requiredHeaders: [HEADERS.SGA_ID, HEADERS.ONHOLD_REASONS]
        },
        [AVAILABLE_ACTIONS.REMOVE_FROM_ONHOLD]: {
          headers: [HEADERS.SGA_ID],
          requiredHeaders: [HEADERS.SGA_ID]
        }
      },
      maxRowCount: 5000,
      isDialogMessageInfoOpen: true
    }
  },
  computed: {
    ...mapState('pmDashboard', ['isDialogInUpdate']),
    getActionDescription() {
      return ACTION_DESCRIPTIONS[this.selectedActionType]
    }
  },
  watch: {
    showDialog(newVal) {
      this.visibleDialog = newVal
    },
    isDialogInUpdate(newVal) {
      this.isDialogInUpdateState = newVal
    }
  },
  methods: {
    ...mapActions('pmDashboard', ['setDashboardLoader', 'uploadFile']),
    async onFileChange(file) {
      this.validationMessage = '' // Reset validation message initially
      this.isFileValid = false // Reset file validation status

      if (file) {
        if (file.size > this.maxFileSize) {
          this.validationMessage = `File size exceeds the limit of ${
            this.maxFileSize / (1024 * 1024)
          } MB.`
          Snackbar({
            message: this.validationMessage,
            type: 'is-danger'
          })
          return
        }

        const { isValid, missingHeaders, isEmpty, rowCount } =
          await this.validateFile(file)

        if (rowCount > this.maxRowCount) {
          this.validationMessage = `File has over ${this.maxRowCount} entities. Please reduce the count.`
          this.isFileValid = false
          return
        }
        if (isEmpty) {
          this.validationMessage =
            'You have uploaded an empty file. Please upload a file with valid data.'
          this.isFileValid = false
        } else if (isValid) {
          this.isFileValid = true
          this.validationMessage = '' // Clear any previous validation messages
        } else {
          this.validationMessage =
            missingHeaders.length > 0
              ? `Invalid file. Missing headers: ${missingHeaders.join(', ')}.`
              : 'Invalid file. Ensure it contains the required headers, has at least one data row, and each required column has values.'
          this.isFileValid = false
        }
      }
    },
    async validateFile(file) {
      if (file.type === 'text/csv') {
        // Handle CSV file with papaparse
        return this.validateCSV(file)
      } else if (
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      ) {
        // Handle Excel file with ExcelJS
        return this.validateExcel(file)
      } else {
        return false
      }
    },
    validateCSV(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
          const data = event.target.result
          Papa.parse(data, {
            complete: (results) => {
              const headers = results.data[0]
              const dataRows = results.data.slice(1) // Extract rows after the header
              const rowCount = dataRows.length
              resolve(this.validateHeadersAndRows(headers, dataRows, rowCount))
            },
            error: (err) => reject(err)
          })
        }
        reader.readAsText(file)
      })
    },
    async validateExcel(file) {
      const workbook = new ExcelJS.Workbook()
      const buffer = await file.arrayBuffer()
      await workbook.xlsx.load(buffer)
      const worksheet = workbook.worksheets[0]
      const headers = worksheet.getRow(1).values.slice(1) // Get the header row, skipping the first empty element

      // Safely get rows after the header, ensuring rows are non-empty
      const dataRows =
        worksheet.rowCount > 1
          ? worksheet.getRows(2, worksheet.rowCount - 1)
          : []

      const rowCount = dataRows.length

      if (rowCount > this.maxRowCount) {
        return { isValid: false, missingHeaders: [], rowCount }
      }

      // If no dataRows are retrieved, consider it as an empty file
      if (!dataRows || dataRows.length === 0) {
        return { isValid: false, missingHeaders: [], isEmpty: true }
      }

      const filteredRows = dataRows.filter((row) => {
        return row.values.some(
          (value) =>
            value !== undefined &&
            value !== null &&
            value.toString().trim() !== ''
        )
      })

      // If filtered rows are empty, consider it as an empty file
      if (filteredRows.length === 0) {
        return { isValid: false, missingHeaders: [], isEmpty: true }
      }

      return this.validateHeadersAndRows(headers, filteredRows, rowCount)
    },
    getFileConfig() {
      return this.fileConfigs[this.selectedActionType]
    },
    validateHeadersAndRows(headers, dataRows, rowCount) {
      const config = this.getFileConfig()
      const { requiredHeaders } = config
      const missingHeaders = requiredHeaders.filter(
        (header) => !headers.includes(header)
      )

      if (missingHeaders.length > 0) {
        return { isValid: false, missingHeaders }
      }

      if (rowCount > this.maxRowCount) {
        return { isValid: false, missingHeaders: [], rowCount }
      }

      if (dataRows.length === 0) {
        // Return an error if the file has no data rows
        return { isValid: false, missingHeaders: [], isEmpty: true }
      }

      const requiredHeaderIndices = requiredHeaders
        .filter((header) => header !== 'Status') // Skip "Status" from required checks
        .map((header) => headers.indexOf(header))

      const hasValidData = dataRows.some((row) => {
        const isRowEmpty = Array.isArray(row)
          ? row.every((cell) => !cell || cell.toString().trim() === '')
          : row.eachCell((cell) => {
              const value = cell.value
              return (
                !value ||
                (typeof value === 'string' && value.trim() === '') ||
                (typeof value === 'object' &&
                  (!value.text || value.text.trim() === ''))
              )
            })

        if (isRowEmpty) {
          return false
        }

        return requiredHeaderIndices.every((index) => {
          let cellValue = Array.isArray(row)
            ? row[index]
            : row.getCell(index + 1).value

          if (typeof cellValue === 'object' && cellValue !== null) {
            cellValue = cellValue.text || ''
          }

          return cellValue && cellValue.toString().trim() !== ''
        })
      })

      return { isValid: hasValidData, missingHeaders: [] }
    },

    async handleUploadFile() {
      let isUploadSuccessful = false
      try {
        if (this.isFileValid) {
          this.setDashboardLoader(true)
          const formData = new FormData()
          formData.append('file', this.selectedFile)
          formData.append('actionType', this.selectedActionType)
          this.uploadFile(formData)
          isUploadSuccessful = true
        } else {
          Snackbar({
            message: 'Please upload a valid file before proceeding.',
            type: 'is-danger'
          })
        }
      } catch (error) {
        Snackbar({
          message: 'An error occurred while uploading the file.',
          type: 'is-danger'
        })
      } finally {
        this.setDashboardLoader(false)
        this.closeFileUploadDialog()
        // Upload successful notification if the upload didn't fail, show the toast after 3 seconds
        if (isUploadSuccessful) {
          setTimeout(() => {
            Snackbar({
              message:
                'You will be notified over your email once the file is processed.',
              type: 'is-info'
            })
          }, 3000) // 2000ms delay (2 seconds)
        }
      }
    },
    handleChange(event) {
      this.selectedActionType = event.value
      // Reset selected file and validation status when action type changes
      this.selectedFile = null
      this.isFileValid = false
      this.validationMessage = ''
    },
    closeFileUploadDialog() {
      this.isFileValid = false // Reset file validation status
      this.validationMessage = '' // Reset validation message
      this.closeDialog()
    },
    deleteSelectedFile() {
      this.selectedFile = null
      this.isFileValid = false
      this.validationMessage = ''
    },
    async generateSampleExcel() {
      // Create a new workbook and add a worksheet named 'Sample Data'
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Sample Data')

      // Get the headers configuration based on the selected action type
      const { columns } = this.getWorksheetConfig()

      // Define the columns in the worksheet
      worksheet.columns = columns

      // Generate the Excel file as a buffer and trigger the download
      const buffer = await workbook.xlsx.writeBuffer()
      let fileName = 'Entities To Load'
      if (this.selectedActionType === AVAILABLE_ACTIONS.REWORK) {
        fileName = 'Submit to Rework'
      } else if (this.selectedActionType === AVAILABLE_ACTIONS.STAGING) {
        fileName = 'Submit to Staging'
      } else if (
        this.selectedActionType === AVAILABLE_ACTIONS.STAGING_TO_SUBMIT
      ) {
        fileName = 'Staging to Submit'
      } else if (this.selectedActionType === AVAILABLE_ACTIONS.MOVE_TO_ONHOLD) {
        fileName = 'Move to On-Hold'
      } else if (
        this.selectedActionType === AVAILABLE_ACTIONS.REMOVE_FROM_ONHOLD
      ) {
        fileName = 'Remove from On-Hold'
      }
      // Create a Blob from the buffer
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })

      // Create a download link and trigger the download
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${fileName}.xlsx`
      document.body.appendChild(a)
      a.click()

      // Clean up and remove the link
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    },
    // Method to determine worksheet configuration based on the selected action type
    getWorksheetConfig() {
      // Retrieve the relevant configuration based on the action type
      const config = this.getFileConfig()
      const { requiredHeaders } = config

      // Define the columns with headers and keys dynamically
      const columns = requiredHeaders.map((header) => ({
        header, // The header text in the Excel file
        key: header.replace(/\s+/g, '').toLowerCase(), // Key for the data field, e.g., 'SGA ID' -> 'sgaId'
        width: 20 // Adjust width as needed
      }))

      return { columns }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .k-window-content,
.k-prompt-container {
  overflow: visible;
}
.validation-message {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f14668; // Change color as needed
  font-weight: 600;

  .has-text-danger {
    margin-right: 0.5rem;
  }
}

.valid-file-notification {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .upload-section {
    margin-top: 1rem;
  }

  .dialog-row {
    .template-file {
      margin-bottom: 1rem;
    }
  }

  .k-list-horizontal {
    display: flex;
    justify-content: flex-start;
  }

  ::v-deep .upload {
    width: 100%;
  }

  ::v-deep .upload-draggable {
    width: 100%;
  }

  .action-bar {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .radio-options-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
    }
  }

  .dialog-content-row {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .form-input-field {
      width: 100%;
      .k-picker-outline {
        border: none;
      }
    }
  }
  .dialog-info-row {
    .dialog-content-column {
      margin-bottom: 0.5rem;
    }
  }
}

.k-radio-list.k-list-horizontal {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create 3 columns */
  gap: 0.5rem; /* Optional: Add spacing between the rows and columns */
  column-gap: 8rem;
}

.k-radio-item {
  display: flex;
  align-items: center; /* Align the radio buttons and labels vertically */
  justify-content: flex-start; /* Align the items to the left (or change to 'center' if needed) */
}

.upload {
  width: 100%;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 5rem;
  // padding-right: 3.875rem;
  // margin-bottom: 36px;
}
.action-button {
  color: #424242;
  font-size: 16px;
  font-weight: 600;
  min-width: 104px;
  // height: 46px;
}
.confirm-button {
  background-color: #535eeb; /* Color of the background */
  background-position-x: 0%; /* Horizontal position of the background image */
  background-position-y: 0%; /* Vertical position of the background image */
  background-repeat: no-repeat; /* Background image will not repeat */
  background-origin: padding-box; /* Background painting area is the padding box */
  border-radius: 8px;
  padding: 8px;
  opacity: 1;
  text-align: left;
  font-size: 16px;
  line-height: 17px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  margin-left: 1rem;
}
.confirm-button:hover {
  background-color: #00218a; /* Color of the background */
  background-position-x: 0%; /* Horizontal position of the background image */
  background-position-y: 0%; /* Vertical position of the background image */
  background-repeat: no-repeat; /* Background image will not repeat */
  background-origin: padding-box; /* Background painting area is the padding box */

  opacity: 0.9;
}

.confirm-button:disabled,
.confirm-button:disabled:hover {
  background-color: #535eeb; /* Keep the original background color */
  opacity: 0.7; /* Keep the original opacity */
  cursor: not-allowed; /* Optional: change the cursor to indicate the button is disabled */
}

.cancel-button {
  background-color: #cccccc; /* Color of the background */
  background-position-x: 0%; /* Horizontal position of the background image */
  background-position-y: 0%; /* Vertical position of the background image */
  background-repeat: no-repeat; /* Background image will not repeat */
  background-origin: padding-box; /* Background painting area is the padding box */

  border-radius: 8px;
  padding: 8px;
  opacity: 1;
  text-align: center;
  font-size: 16px;
  line-height: 17px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: #444444;
  opacity: 1;
}
.cancel-button:hover {
  background-color: #cccccc; /* Color of the background */
  background-position-x: 0%; /* Horizontal position of the background image */
  background-position-y: 0%; /* Vertical position of the background image */
  background-repeat: no-repeat; /* Background image will not repeat */
  background-origin: padding-box; /* Background painting area is the padding box */
  opacity: 0.9;
}

.drag-message-info {
  font-size: 12px;
}

.toolbar-icons {
  width: 17px;
  height: 17px;
  color: black;
}

.action-bar-info-message {
  .description-message {
    color: grey;
  }
}

@media (max-width: 750px) {
  .k-radio-list.k-list-horizontal {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Change to 2 columns on small screens */
  }
}
</style>
