var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background-main"},[(!_vm.fullscreenActive)?_c('div',[_c('header-vue')],1):_vm._e(),(
      _vm.currentModule === 'esg-daas' ||
      _vm.currentModule === 'investment-insight' ||
      _vm.currentModule === 'playbook' ||
      _vm.currentModule === 'data-management-platform' ||
      (_vm.currentModule === 'publication-monitoring-tool'  && _vm.$store.state.pmtDocuments.activeButton === 'button1') ||
      _vm.$route.meta.hideSidePanel ||
      (_vm.currentModule === 'reports-repository' &&
        !this.isReportTypePresent(_vm.active_page) &&
        _vm.$route.meta.hideSidePanel)
    )?_c('div',{staticClass:"esg-daas-panel"},[_c('router-view')],1):_c('div',{staticClass:"seperate-panel",class:[_vm.fullscreenActive && 'change-height']},[_c('div',{staticClass:"flex-three",class:[_vm.isCollapsed ? 'collapsed' : '']},[(_vm.currentModule === 'tam')?_c('side-panel-filter-tam'):(_vm.currentModule === 'voe')?_c('side-panel-filter-voe'):(_vm.currentModule === 'voc')?_c('side-panel-filter'):(_vm.currentModule === 'reports-repository')?_c('side-panel-filter-reports-repo'):(_vm.currentModule === 'publication-monitoring-tool')?_c('SidePanelFilterPMT'):(_vm.currentModule === 'esg-controversies')?_c('side-panel-filter-esg'):_vm._e()],1),_c('div',{staticClass:"flex-seven",class:[_vm.isCollapsed ? 'expanded' : '']},[_c('div',{class:['main-view']},[(_vm.$route.meta.group !== 'pageNotFound')?[_c('div',{staticClass:"sub-header"},[(!_vm.fullscreenActive)?_c('nested-top-navigation'):_vm._e(),_c('page-tracking',{attrs:{"withLegends":true}})],1)]:_vm._e(),_c('div',{staticClass:"padding-both-side"},[_c('router-view')],1)],2)])]),(!_vm.fullscreenActive)?_c('feedback-form-vue'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }