import Vue from 'vue'
import Snackbar from '@/components/Snackbar'

const getDefaultState = () => {
  return {
    identifiersDetails: {},
    identifiersTaxTypes: [],
    bvdIdentifiersDetails: {},

    selectableGridData: [],
    crnRegexList: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getIdentifiersDetails({ commit }, sgaId) {
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/identifiers?sgaId=${sgaId}`
        )

        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            commit('SET_IDENTIFIERS_DETAILS', response.data.data)
          }
        } else {
          // Reset store
          commit('SET_IDENTIFIERS_DETAILS', {})
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    async updateIdentifiersDetails({ commit }, updateIdentifiersDetails) {
      try {
        const { sgaId, identifiersFormFields } = updateIdentifiersDetails

        const response = await Vue.prototype.$http.put(
          `/dmp/identifiers?sgaId=${sgaId}`,
          identifiersFormFields
        )

        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            commit('SET_IDENTIFIERS_DETAILS', response.data.data)
          }
        }
        // Returned response for status of submission
        return response?.data?.status
      } catch (error) {
        throw new Error(error)
      }
    },
    async getIdentifiersTaxTypes({ commit }, countryIdList = []) {
      try {
        commit('SET_IDENTIFIER_TAX_TYPES', [])
        const response = await Vue.prototype.$http.get(
          `/dmp/identifiers/tax-type?countryIds=${countryIdList.join(
            '&countryIds='
          )}`
        )
        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            commit('SET_IDENTIFIER_TAX_TYPES', response.data.data)
          }
        }
      } catch (error) {
        throw new Error(error)
      }
    },

    async getExternalLEIList({ commit }, entityName) {
      try {
        commit('SET_SELECTABLE_GRID_DATA', [])

        // Filter out words ony
        let entityNameWords = entityName
          .split(/\s+/)
          .map((part) => part.replace(/[^\w\s]/g, ''))
          .filter(Boolean)

        const response = await Vue.prototype.$http.post(
          `/dmp/integration?type=lei`,
          {
            params: {
              'filter[entity.names]': entityNameWords.join(','),
              'page[size]': '10',
              'page[number]': '1'
            }
          }
        )

        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            const leiFormattedData = response.data?.data?.map((item) => {
              return {
                LEI: item.lei,
                'Legal Entity Name': item.legalEntityName,
                'Other Names': item.otherNames,
                Status: item.status,
                'Legal Structure': item.legalStructure
              }
            })
            commit('SET_SELECTABLE_GRID_DATA', leiFormattedData)
          }
        } else {
          // If status is not 200, reset grid store
          Snackbar({
            message: 'Failed to fetch data.',
            type: 'is-danger'
          })
          commit('SET_SELECTABLE_GRID_DATA', [])
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    async getExternalRSSDList({ commit }, legalEntityName) {
      try {
        commit('SET_SELECTABLE_GRID_DATA', [])
        // Step 1: Clean and process the input name to get the first two words
        const processedName = legalEntityName
          .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
          .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
          .trim() // Trim leading/trailing spaces
          .split(' ') // Split into words
          .slice(0, 2) // Take the first two words
          .join(' ') // Join them back into a string

        // Step 2: Send the processed name to the backend
        const response = await Vue.prototype.$http.get(
          '/dmp/identifiers/search-rssd',
          {
            params: {
              legalEntityName: processedName,
              useCache: true,
              maxResults: 15
            }
          }
        )

        // Step 3: Handle the backend response
        if (
          response?.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          const formattedData = response.data.data.map((item) => ({
            RSSD: item.rssdId,
            'Legal Entity Name': item.entityName,
            'Short Name': item.entityShortName,
            'Country Name': item.countryName,
            'City Name': item.city,
            'Entity Type': item.entityType // Already mapped by the backend
          }))
          commit('SET_SELECTABLE_GRID_DATA', formattedData)
        }
      } catch (error) {
        throw new Error(error)
      }
    },

    async getExternalCRDList({ commit }, entityName) {
      try {
        commit('SET_SELECTABLE_GRID_DATA', [])

        const response = await Vue.prototype.$http.post(
          `/dmp/integration?type=crd`,
          {
            params: {
              query: entityName.split(' ').join(' '),
              hl: true,
              nrows: 10,
              start: 0,
              r: 25,
              sort: 'score+desc',
              wt: 'json'
            }
          }
        )

        if (response?.data && response.data?.status === 200) {
          const formatLocation = (item) => {
            const { city, state, country } = item
            return `"${city}"${state ? `,"${state}"` : ''},"${country}"`
          }

          if (response.data?.data) {
            const modifiedData = response.data?.data?.map((item) => ({
              CRD: item.crd,
              'Entity Name': item.legalEntityName,
              'Entity Alias': item.alias.map((data) => `"${data}" `),
              Location: formatLocation({
                city: item.city,
                state: item.state,
                country: item.country
              })
            }))
            commit('SET_SELECTABLE_GRID_DATA', modifiedData)
          }
        }
      } catch (error) {
        throw new Error(error)
      }
    },

    async getCIKCodeList({ commit }, entityName) {
      commit('SET_SELECTABLE_GRID_DATA', [])
      const cikCodeParams = {}
      cikCodeParams.searchQuery = entityName

      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/identifiers/cikcode',
          { params: cikCodeParams }
        )

        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            const cikFormattedData = response.data?.data?.map((item) => {
              return {
                CIK: item.CIK,
                'Legal Entity Name': item.legalEntityName
              }
            })
            commit('SET_SELECTABLE_GRID_DATA', cikFormattedData)
          }
        }
        // Returned response for status of submission
        return response?.data?.status
      } catch (error) {
        throw new Error(error)
      }
    },
    resetIdentifiersDetails({ commit }) {
      commit('SET_IDENTIFIERS_DETAILS', {})
    },
    patchIdentifiersDetails({ commit }, bvdIdentifiersDetails) {
      commit('SET_BVD_IDENTIFIERS_DETAILS', bvdIdentifiersDetails)
    },
    async getCrnRegexList({ commit }, countryId) {
      try {
        commit('SET_CRN_REGEX_LIST', [])

        const response = await Vue.prototype.$http.get(
          `/dmp/identifiers/crn-regex?countryId=${countryId}`
        )

        if (response?.data && response?.data?.status === 200) {
          if (response.data?.data) {
            const crnRegexData = response.data?.data
            commit('SET_CRN_REGEX_LIST', crnRegexData)
          }
        } else {
          // If status is not 200, reset grid store
          Snackbar({
            message: 'Failed to fetch data.',
            type: 'is-danger'
          })
          commit('SET_CRN_REGEX_LIST', [])
        }
      } catch (error) {
        throw new Error(error)
      }
    }
  },
  mutations: {
    SET_IDENTIFIERS_DETAILS(state, identifiersDetails) {
      state.identifiersDetails = { ...identifiersDetails }
    },
    SET_IDENTIFIER_TAX_TYPES(state, taxTypes) {
      state.identifiersTaxTypes = taxTypes.map((option) => {
        const taxTypesObj = {}
        taxTypesObj.value = option.taxTypeId
        taxTypesObj.text = `${option.taxTypeCode} - ${option.taxType}`
        return taxTypesObj
      })
    },
    SET_BVD_IDENTIFIERS_DETAILS(state, bvdIdentifiersDetails) {
      state.bvdIdentifiersDetails = { ...bvdIdentifiersDetails }
    },

    SET_SELECTABLE_GRID_DATA(state, selectableGridData) {
      state.selectableGridData = selectableGridData
    },
    SET_CRN_REGEX_LIST(state, regexList) {
      state.crnRegexList = regexList
    }
  }
}
