<template>
  <div>
    <Dialog
      v-if="visible"
      @close="closeDialog"
      :class="dialogClass"
      :title-render="customTitleBar"
    >
      <template v-slot:myTemplate="{ props, listeners }">
        <div class="custom-title">
          {{ title }}
        </div>
      </template>

      <slot name="text">
        <!--  v-if to conditionally render slot or bodyText -->
        <div v-if="$slots.text">
          <slot name="text"></slot>
        </div>
        <div v-else v-html="bodyText" class="popup-msg"></div>
      </slot>

      <slot></slot>

      <DialogActionsBar class="action-bar">
        <KButton
          v-if="isConfirmDialog"
          class="action-button cancel-button"
          @click="closeDialog"
          >{{ cancelButtonText }}</KButton
        >
        <KButton
          class="action-button confirm-button"
          @click="handleConfirmation"
          >{{ confirmButtonText }}</KButton
        >
      </DialogActionsBar>
    </Dialog>
  </div>
</template>

<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'
import { Button } from '@progress/kendo-vue-buttons'

export default {
  name: 'ConfirmationDialog',
  components: {
    Dialog,
    DialogActionsBar,
    KButton: Button
  },
  props: {
    // visible : Expecting a boolean value
    visible: {
      type: Boolean,
      required: true
    },
    // closeDialog: A function that sets the value of visible to false
    closeDialog: {
      type: Function,
      required: true
    },
    // triggerFunction: A function that should be invoked when click on yes or confirm.
    triggerFunction: {
      type: Function,
      required: true
    },
    // title: The title of the dialog.
    title: {
      type: String,
      default: 'Please confirm' // Default title value
    },
    // bodyText: The content of the dialog / message that will be shown to the user.
    bodyText: {
      type: String,
      default: 'Are you sure you want to continue?' // Default body text value
    },
    // cancelbuttonText: Text for the cancel button / message that will be shown to the user.
    cancelButtonText: {
      type: String,
      default: 'No' // Default body text value
    },
    // confrimButtonText: Text for the confirm button / message that will be shown to the user.
    confirmButtonText: {
      type: String,
      default: 'Yes' // Default body text value
    },
    // type: Type of Dialog
    type: {
      type: String,
      default: 'confirm'
    }
  },
  data: function () {
    return {
      customTitleBar: 'myTemplate'
    }
  },

  computed: {
    // Compute the dialog class based on the type prop
    dialogClass() {
      return `dialog-${this.type}`
    },
    isConfirmDialog() {
      return ['confirm'].includes(this.type) // Add types for cancel button
    }
  },
  methods: {
    handleConfirmation() {
      this.triggerFunction()
      this.closeDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 600px;
}
.action-button {
  color: #424242;
  font-size: 1em;
  font-weight: 600;
}

.action-bar {
  // Common CSS for buttons
  .confirm-button {
    background-color: #00218a;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.18px;
    color: #ffffff;
    opacity: 1;
  }
  .confirm-button:hover {
    background-color: #00218a;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    opacity: 0.9;
  }

  .cancel-button {
    background-color: #cccccc;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    border-radius: 8px;
    padding: 8px;
    opacity: 1;
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.18px;
    color: #444444;
    opacity: 1;
  }
  .cancel-button:hover {
    background-color: #cccccc; /* Color of the background */
    background-position-x: 0%; /* Horizontal position of the background image */
    background-position-y: 0%; /* Vertical position of the background image */
    background-repeat: no-repeat; /* Background image will not repeat */
    background-origin: padding-box; /* Background painting area is the padding box */
    opacity: 0.9;
  }
}

/* Base styles for the dialog */
.dialog-warning {
  // Specific CSS for Buttons
  .confirm-button {
    margin: 0 25% 0 25%;
  }
}

.popup-msg {
  margin: 25px;
  max-height: 400px;
  text-align: center;
}
.custom-title {
  font-size: 18px;
  white-space: pre-line;
}
</style>
