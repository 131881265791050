import Vue from 'vue'
import store from '../../store/index'

const getDefaultState = () => {
  return {
    pmtDashboardCardData: {},
    barChartData: [],
    treeMapData: [],
    barChartCategories: [],
    accuracyChartData: [],
    companyDocumentData: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getPmtDashboardCardData({ commit }, reqData) {
      commit('LOADING', true)
      await Vue.prototype.$http
        .post('/pmt/dashboard/card-data', reqData)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('SET_PMT_DASHBOARD_CARD_DATA', response.data.data)
            }
          }
          if (response.data.status === 204) {
            commit('SET_PMT_DASHBOARD_CARD_DATA', {})
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          if (e.message === 'Request canceled due to new request.') {
            commit('LOADING', true)
          } else {
            commit('LOADING', false)
          }
        })
    },
    async getBarChartData({ commit }, reqData) {
      commit('LOADING', true)
      await Vue.prototype.$http
        .post('/pmt/dashboard/document-chart-data', reqData)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('SET_PMT_DASHBOARD_BAR_CHART_DATA', response.data.data)
            }
          }
          if (response.data.status === 204) {
            commit('SET_PMT_DASHBOARD_BAR_CHART_DATA', null)
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          if (e.message === 'Request canceled due to new request.') {
            commit('LOADING', true)
          } else {
            commit('LOADING', false)
          }
        })
    },
    async getTreeMapChartData({ commit }, reqData) {
      commit('LOADING', true)
      await Vue.prototype.$http
        .post('/pmt/dashboard/document-type-chart-data', reqData)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('SET_PMT_DASHBOARD_TREEMAP_CHART_DATA', response.data.data)
            }
          }
          if (response.data.status === 204) {
            commit('SET_PMT_DASHBOARD_TREEMAP_CHART_DATA', [])
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          if (e.message === 'Request canceled due to new request.') {
            commit('LOADING', true)
          } else {
            commit('LOADING', false)
          }
        })
    },
    async getAccuracyChartData({ commit }, reqData) {
      commit('LOADING', true)
      await Vue.prototype.$http
        .post('/pmt/dashboard/accuracy-chart-data', reqData)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit(
                'SET_PMT_DASHBOARD_ACCURACY_CHART_DATA',
                response.data.data
              )
            }
          }
          if (response.data.status === 204) {
            commit('SET_PMT_DASHBOARD_ACCURACY_CHART_DATA', null)
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          if (e.message === 'Request canceled due to new request.') {
            commit('LOADING', true)
          } else {
            commit('LOADING', false)
          }
        })
    },
    async getCompanyDocumentData({ commit }, reqData) {
      commit('LOADING', true)
      await Vue.prototype.$http
        .post('/pmt/dashboard/company-document-data', reqData)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit(
                'SET_PMT_DASHBOARD_COMPANY_DOCUMENT_DATA',
                response.data.data
              )
            }
          }
          if (response.data.status === 204) {
            commit('SET_PMT_DASHBOARD_COMPANY_DOCUMENT_DATA', null)
          }
          commit('LOADING', false)
        })
        .catch((e) => {
          if (e.message === 'Request canceled due to new request.') {
            commit('LOADING', true)
          } else {
            commit('LOADING', false)
          }
        })
    }
  },
  mutations: {
    LOADING(state, payload) {
      store.state.filters.loading = payload
    },
    SET_PMT_DASHBOARD_CARD_DATA(state, pmtDashboardCardData) {
      state.pmtDashboardCardData = pmtDashboardCardData
    },
    SET_PMT_DASHBOARD_BAR_CHART_DATA(state, pmtDashboardBarChartData) {
      state.barChartCategories = pmtDashboardBarChartData?.categories
      state.barChartData = pmtDashboardBarChartData?.chart_data
    },
    SET_PMT_DASHBOARD_TREEMAP_CHART_DATA(state, pmtDashboardTreeMapChartData) {
      state.treeMapData = pmtDashboardTreeMapChartData
    },
    SET_PMT_DASHBOARD_ACCURACY_CHART_DATA(
      state,
      pmtDashboardAccuracyChartData
    ) {
      state.accuracyChartData = pmtDashboardAccuracyChartData
    },
    SET_PMT_DASHBOARD_COMPANY_DOCUMENT_DATA(
      state,
      pmtDashboardCompanyDocumentData
    ) {
      state.companyDocumentData = pmtDashboardCompanyDocumentData
    }
  }
}
