<template>
  <div class="no-data-container">
    <div class="center-no-data">
      <div
        v-if="(categoryList.length || companyList || reportData.length) && !loading"
        class="data-block">
        <div v-if="type == 'esg-controversies' && selectedEsgCategories.length>0 && selectedSentiment.length>0 ">
          <div class="img-container">
            <img v-if="user_preference_mode==='light'" src="../../../assets/no_record_found_light.png" />
            <img v-else-if="user_preference_mode==='dark'" src="../../../assets/no_record_found_dark.png" />
          </div>
          <div class="no-record-found">
            No articles found, consider choosing a different <br />filter
            criteria
          </div>
        </div>
        <p v-else>No data for the selection.</p>
        <ul>
          <template v-if="currentModule === 'tam' || currentModule === 'voc'">
            <li v-if="!selectedProducts.length && type !== 'tam' && active_page !== 'vendor-csat'">
              <span />Select at least one Product.
            </li>
            <li v-if="!selectedCsatVendors.length && active_page === 'vendor-csat'">
              <span />Select at least one Vendor.
            </li>
            <li v-if="!selectedSourceList.length && type !== 'tam'">
              <span />Select at least one Source.
            </li>
            <li v-if="!selectedIndustries.length">
              <span />Select at least one Industry.
            </li>
            <li v-if="!selectedCompanies.length">
              <span />Select at least one Business Size.
            </li>
            <li v-if="!userType.length && type == 'trendingBuzz'">
              <span />Select at least one Reaction.
            </li>
            <li v-if="!selectedFeatures.length && type == 'trendingBuzz'">
              <span />Select at least one Feature.
            </li>
            <li v-if="!selectedRating.length && type == 'trendingBuzz'">
              <span />Select at least one Rating.
            </li>
            <li v-if="!selectedCountries.length && type == 'tam'">
              <span />Select at least one Country.
            </li>
            <li v-if="!isPreviousPeriodInSubscription && type == 'sunset-sunrise'">
              <span />No data available for previous period. Please select different time period.
            </li>
          </template>
          <template v-if="currentModule === 'voe'">
            <li v-if="!selectedCompaniesVoe.length">
              <span />Select at least one Company.
            </li>
            <li v-if="!selectedEmployeeStatuses.length">
              <span />Select at least one Employee Status.
            </li>
            <li v-if="!selectedBusinessFunc.length">
              <span />Select at least one Business Functionn.
            </li>
            <li v-if="!selectedSeniorityLevel.length">
              <span />Select at least one Seniority Level.
            </li>
            <li v-if="!selectedFeatures.length && type == 'trendingBuzz'">
              <span />Select at least one Feature.
            </li>
            <li v-if="!userType.length && type == 'trendingBuzz'">
              <span />Select at least one Reaction.
            </li>
          </template>
          <template v-if="currentModule === 'esg-controversies'">
            <li v-if="!selectedEsgCategories.length">
              <span />Select at least one Category.
            </li>
            <li v-if="!selectedSentiment.length">
              <span />Select at least one Sentiment.
            </li>
          </template>
          <template v-if="currentModule === 'reports-repository'">
            <li v-if="!selectedReportTypes.length">
              <span />Select at least one Report Type.
            </li>
          </template>
          <template v-if="currentModule === 'publication-monitoring-tool'">
            <li v-if="!selectedReportTypes.length">
              <span />Select at least one project.
            </li>
          </template>
        </ul>
      </div>
      <div v-if="loading" class="data-block">
        <p>Loading data ...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

/** This component renders the NoData container whenever the *noData* event is emitted  */
export default {
  name: 'NoDataContainer',
  props: ['type', 'info'],
  computed: {
    ...mapState('filters', [
      'selectedProducts',
      'selectedIndustries',
      'selectedSourceList',
      'selectedCompanies',
      'selectedCompaniesVoe',
      'selectedCsatVendors',
      'selectedFeatures',
      'selectedRating',
      'userType',
      'categoryList',
      'companyList',
      'ActiveVoeTimePeriod',
      'productList',
      'loading',
      'isPreviousPeriodInSubscription',
      'selectedCountries',
      'selectedEmployeeStatuses',
      'selectedBusinessFunc',
      'selectedSeniorityLevel',
      'selectedEsgCategories'
    ]),
    ...mapState('esgControversies', ['selectedSentiment', 'activeEsgTimePeriod']),
    ...mapState('reportsRepoFilters', ['selectedReportTypes', 'activeReportsRepoTimePeriod']),
    ...mapState('reportsRepository', ['reportData']),
    ...mapState('common', ['currentModule', 'active_page', 'user_preference_mode'])
  }
}
</script>

<style lang="scss" scoped>
.no-data-container {
  width: calc(100%);
  display: flex;
  flex: 1;
  min-height: 290px;
  height: 100%;
  background: var(--primary);
  justify-content: center;
  align-items: center;
  .center-no-data {
    p {
      margin: 0px;
      font-size: 16px;
      color: var(--no-data-text-color);
      padding-bottom: 10px;
    }
    ul {
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      li {
        font-size: 14px;
        color: var(--no-data-text-color);
        display: flex;
        align-items: center;
        span {
          display: block;
          width: 5px;
          height: 1px;
          background: var(--no-data-text-color);
          margin-right: 5px;
        }
      }
    }
    .no-record-found {
      margin-top: 30px;
      margin-bottom: 40px;
      text-align: center;
      font-size: 27px;
      font-family: Quicksand;
      font-weight: 600;
      letter-spacing: 0px;
      color: var(--no-article-found);
      opacity: 1;
      @media (min-width: 1450px) and (max-width: 1700px) {
        font-size: 33px;
        margin-top: 45px;
      }
      @media (min-width: 1700px) {
        font-size: 40px;
        margin-top: 60px;
      }
    }
    .img-container {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: blue;
      img {
        width: 265px;
        height: 190px;
        @media (min-width: 1450px) and (max-width: 1700px) {
          width: 287px;
          height: 240px;
          margin-top: 45px;
        }
        @media (min-width: 1700px) {
          width: 365px;
          height: 264px;
          margin-top: 60px;
        }
      }
    }
  }
}
</style>
