import newsRssFeed from '../store/admin/newsRssFeed'
import pmtDocuments from '../store/PMT/pmtDocuments'

export const statusList = [
  {
    id: 0,
    name: 'Pending'
  },
  {
    id: 1,
    name: 'In-progress'
  },
  {
    id: 2,
    name: 'Completed'
  },
  {
    id: 3,
    name: 'Failed'
  },
  {
    id: 4,
    name: 'Canceled'
  }
]

export const priorityList = [
  {
    id: 0,
    name: 'Low'
  },
  {
    id: 1,
    name: 'High'
  },
  {
    id: 2,
    name: 'Urgent'
  }
]

export const jobTypeList = [
  {
    id: 0,
    name: 'Delete Reviews',
    alias: "'deleteReviews'"
  },
  {
    id: 1,
    name: 'Detag',
    alias: "'detag'"
  },
  {
    id: 2,
    name: 'Extract Reviews',
    alias: "'extractReviews'"
  },
  {
    id: 3,
    name: 'Tag',
    alias: "'tag'"
  },
  {
    id: 4,
    name: 'Extract VoE',
    alias: "'extractVoe'"
  },
  {
    id: 5,
    name: 'Delete VoE',
    alias: "'deleteVoe'"
  },
  {
    id: 6,
    name: 'Tag VoE',
    alias: "'tagVoe'"
  },
  {
    id: 7,
    name: 'Detag VoE',
    alias: "'detagVoe'"
  }
]

export const ratingList = [
  {
    name: 0,
    id: 0
  },
  {
    name: 1,
    id: 1
  },
  {
    name: 2,
    id: 2
  },
  {
    name: 3,
    id: 3
  },
  {
    name: 4,
    id: 4
  },
  {
    name: 5,
    id: 5
  }
]

// export const ratingList = [
//   {
//     name: '1-2 Star',
//     id: 1
//   },
//   {
//     name: '3 Star',
//     id: 2
//   },
//   {
//     name: '4-5 Star',
//     id: 3
//   },
// ]

export const reactions = [
  {
    name: 'Like',
    id: 'POSITIVE'
  },
  {
    name: 'Dislike',
    id: 'NEGATIVE'
  },
  {
    name: 'Neutral',
    id: 'NEUTRAL'
  }
]

export const voeReactions = [
  {
    name: 'Like',
    id: 'POSITIVE'
  },
  {
    name: 'Dislike',
    id: 'NEGATIVE'
  },
  {
    name: 'Neutral',
    id: 'NEUTRAL'
  },
  {
    name: 'Mixed',
    id: 'MIXED'
  }
]

export const groupByOptions = [
  {
    name: 'Product',
    id: 'product'
  },
  {
    name: 'Source',
    id: 'source'
  },
  {
    name: 'Industry',
    id: 'industry'
  },
  {
    name: 'Business Size',
    id: 'company_size'
  }
]

export const tamTabOptions = [
  {
    name: 'By Geography',
    id: 0
  },
  {
    name: 'By Verticals',
    id: 1
  },
  {
    name: 'By Size Band',
    id: 2
  }
]

export const analyticsEvents = {
  Feature: 'filter_feature',
  Category: 'filter_category',
  'Time Period': 'filter_time_filter',
  Industry: 'filter_industry',
  Reaction: 'filter_reaction',
  Product: 'filter_product',
  'Data Source': 'filter_data_source',
  'Business Size': 'filter_business_size',
  Rating: 'filter_rating',
  Sort: 'filter_sort',
  changeView: 'view_toggle',
  tabChange: 'tab_change',
  market_segment_by: 'market_segment_By',
  region: 'filter_region'
}

export const metaTitle = {
  login: 'Sign in | Competitive Intelligence Platform',
  trendingBuzz: 'Comments | Competitive Intelligence Platform',
  vocSummary: 'Summary | Competitive Intelligence Platform',
  npsPage: 'NPS | Competitive Intelligence Platform',
  reports: 'Reports | Competitive Intelligence Platform',
  csatDrivers: 'CSAT Drivers | Competitive Intelligence Platform',
  vendorCsat: 'CSAT by Vendors | Competitive Intelligence Platform',
  esatDrivers:
    'Employee Satisfacton (ESAT) Measure | Competitive Intelligence Platform',
  evpMatrix:
    'Employee Value Proposition (EVP) Matrix | Competitive Intelligence Platform',
  voeReports: 'Perception Watch | Competitive Intelligence Platform',
  voeSummary:
    'Employee Satisfaction Index (ESI) | Competitive Intelligence Platform',
  voeComments:
    'Employee Experience Cognition | Competitive Intelligence Platform',
  voeTrends:
    'Emerging - Critical Priorities | Competitive Intelligence Platform',
  profile: 'Profile | Competitive Intelligence Platform',
  resetPassword: 'Reset Password | Competitive Intelligence Platform',
  recoverPassword: 'Recover Password | Competitive Intelligence Platform',
  forgotPassword: 'Forgot Password | Competitive Intelligence Platform',
  resetSuccess: 'Reset Success | Competitive Intelligence Platform',
  ssoInfo: 'SSO Information | Competitive Intelligence Platform',
  profileSetup: 'Profile Setup | Competitive Intelligence Platform',
  security: 'Security & Permissions | Competitive Intelligence Platform',
  people: 'People | Competitive Intelligence Platform',
  adminDashboard: 'Dashboard | Competitive Intelligence Platform',
  organizations: 'Organizations | Competitive Intelligence Platform',
  categories: 'Categories | Competitive Intelligence Platform',
  products: 'Products | Competitive Intelligence Platform',
  dataSources: 'DataSources | Competitive Intelligence Platform',
  topics: 'Topics | Competitive Intelligence Platform',
  companySize: 'Company Size | Competitive Intelligence Platform',
  industries: 'Industries | Competitive Intelligence Platform',
  jobs: 'Jobs | Competitive Intelligence Platform',
  trends: 'Trends | Competitive Intelligence Platform',
  role: 'Roles & Permissions | Competitive Intelligence Platform',
  feature: 'Feature | Competitive Intelligence Platform',
  winloss: 'Competitive Positioning | Competitive Intelligence Platform',
  tam: 'Customer Adoption Trends | Competitive Intelligence Platform',
  companies: 'Companies | Competitive Intelligence Platform',
  esgControversies: 'ESG Controversies | Competitive Intelligence Platform',
  newsCategories: 'News Category | Competitive Intelligence Platform',
  newsRssFeed: 'News RSS | Competitive Intelligence Platform',
  esgDaas: 'ESG DaaS | Competitive Intelligence Platform',
  pmtDocuments: 'PMT Documents | Competitive Intelligence Platform',
  equityReports: 'Equity Reports | Competitive Intelligence Platform',
  fixedIncome: 'Fixed Income Reports | Competitive Intelligence Platform',
  investmentInsight: 'Investment Insights | Competitive Intelligence Platform',
  playBook: 'Playbook | Competitive Intelligence Platform',
  reportsRepository: 'Reports Repository | Competitive Intelligence Platform',
  dataManegement:
    'Data Management Platform | Competitive Intelligence Platform',
  addMultipleReport: 'Reports Repository | Competitive Intelligence Platform'
}

export const status = [
  {
    name: 'Active',
    id: 1
  },
  {
    name: 'Inactive',
    id: 2
  },
  {
    name: 'Blocked',
    id: 3
  }
]

export const topics = [
  {
    type: 'Standard',
    id: false
  },
  {
    type: 'Standard + Custom',
    id: true
  }
]

export const granularity = [
  {
    type: 'Monthly',
    id: true
  },
  {
    type: 'Quarterly',
    id: false
  }
]

export const ssoAllowUser = [
  {
    type: 'Allow all users',
    id: true
  },
  {
    type: 'Allow only invited users.',
    id: false
  }
]

export const dataSubscriptionOptions = [
  {
    type: 'All-time',
    id: 1
  },
  {
    type: 'All-time, fixed start date',
    id: 2
  },
  {
    type: 'All-time, fixed end date',
    id: 3
  },
  {
    type: 'Custom',
    id: 4
  }
]

export const countryCode = [
  {
    name: 'Afghanistan',
    dial_code: '+93',
    code: 'AF'
  },
  {
    name: 'Aland Islands',
    dial_code: '+358',
    code: 'AX'
  },
  {
    name: 'Albania',
    dial_code: '+355',
    code: 'AL'
  },
  {
    name: 'Algeria',
    dial_code: '+213',
    code: 'DZ'
  },
  {
    name: 'AmericanSamoa',
    dial_code: '+1684',
    code: 'AS'
  },
  {
    name: 'Andorra',
    dial_code: '+376',
    code: 'AD'
  },
  {
    name: 'Angola',
    dial_code: '+244',
    code: 'AO'
  },
  {
    name: 'Anguilla',
    dial_code: '+1264',
    code: 'AI'
  },
  {
    name: 'Antarctica',
    dial_code: '+672',
    code: 'AQ'
  },
  {
    name: 'Antigua and Barbuda',
    dial_code: '+1268',
    code: 'AG'
  },
  {
    name: 'Argentina',
    dial_code: '+54',
    code: 'AR'
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    code: 'AM'
  },
  {
    name: 'Aruba',
    dial_code: '+297',
    code: 'AW'
  },
  {
    name: 'Australia',
    dial_code: '+61',
    code: 'AU'
  },
  {
    name: 'Austria',
    dial_code: '+43',
    code: 'AT'
  },
  {
    name: 'Azerbaijan',
    dial_code: '+994',
    code: 'AZ'
  },
  {
    name: 'Bahamas',
    dial_code: '+1242',
    code: 'BS'
  },
  {
    name: 'Bahrain',
    dial_code: '+973',
    code: 'BH'
  },
  {
    name: 'Bangladesh',
    dial_code: '+880',
    code: 'BD'
  },
  {
    name: 'Barbados',
    dial_code: '+1246',
    code: 'BB'
  },
  {
    name: 'Belarus',
    dial_code: '+375',
    code: 'BY'
  },
  {
    name: 'Belgium',
    dial_code: '+32',
    code: 'BE'
  },
  {
    name: 'Belize',
    dial_code: '+501',
    code: 'BZ'
  },
  {
    name: 'Benin',
    dial_code: '+229',
    code: 'BJ'
  },
  {
    name: 'Bermuda',
    dial_code: '+1441',
    code: 'BM'
  },
  {
    name: 'Bhutan',
    dial_code: '+975',
    code: 'BT'
  },
  {
    name: 'Bolivia, Plurinational State of',
    dial_code: '+591',
    code: 'BO'
  },
  {
    name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    code: 'BA'
  },
  {
    name: 'Botswana',
    dial_code: '+267',
    code: 'BW'
  },
  {
    name: 'Brazil',
    dial_code: '+55',
    code: 'BR'
  },
  {
    name: 'British Indian Ocean Territory',
    dial_code: '+246',
    code: 'IO'
  },
  {
    name: 'Brunei Darussalam',
    dial_code: '+673',
    code: 'BN'
  },
  {
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'BG'
  },
  {
    name: 'Burkina Faso',
    dial_code: '+226',
    code: 'BF'
  },
  {
    name: 'Burundi',
    dial_code: '+257',
    code: 'BI'
  },
  {
    name: 'Cambodia',
    dial_code: '+855',
    code: 'KH'
  },
  {
    name: 'Cameroon',
    dial_code: '+237',
    code: 'CM'
  },
  {
    name: 'Canada',
    dial_code: '+1',
    code: 'CA'
  },
  {
    name: 'Cape Verde',
    dial_code: '+238',
    code: 'CV'
  },
  {
    name: 'Cayman Islands',
    dial_code: '+ 345',
    code: 'KY'
  },
  {
    name: 'Central African Republic',
    dial_code: '+236',
    code: 'CF'
  },
  {
    name: 'Chad',
    dial_code: '+235',
    code: 'TD'
  },
  {
    name: 'Chile',
    dial_code: '+56',
    code: 'CL'
  },
  {
    name: 'China',
    dial_code: '+86',
    code: 'CN'
  },
  {
    name: 'Christmas Island',
    dial_code: '+61',
    code: 'CX'
  },
  {
    name: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    code: 'CC'
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO'
  },
  {
    name: 'Comoros',
    dial_code: '+269',
    code: 'KM'
  },
  {
    name: 'Congo',
    dial_code: '+242',
    code: 'CG'
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    dial_code: '+243',
    code: 'CD'
  },
  {
    name: 'Cook Islands',
    dial_code: '+682',
    code: 'CK'
  },
  {
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'CR'
  },
  {
    name: "Cote d'Ivoire",
    dial_code: '+225',
    code: 'CI'
  },
  {
    name: 'Croatia',
    dial_code: '+385',
    code: 'HR'
  },
  {
    name: 'Cuba',
    dial_code: '+53',
    code: 'CU'
  },
  {
    name: 'Cyprus',
    dial_code: '+357',
    code: 'CY'
  },
  {
    name: 'Czech Republic',
    dial_code: '+420',
    code: 'CZ'
  },
  {
    name: 'Denmark',
    dial_code: '+45',
    code: 'DK'
  },
  {
    name: 'Djibouti',
    dial_code: '+253',
    code: 'DJ'
  },
  {
    name: 'Dominica',
    dial_code: '+1767',
    code: 'DM'
  },
  {
    name: 'Dominican Republic',
    dial_code: '+1849',
    code: 'DO'
  },
  {
    name: 'Ecuador',
    dial_code: '+593',
    code: 'EC'
  },
  {
    name: 'Egypt',
    dial_code: '+20',
    code: 'EG'
  },
  {
    name: 'El Salvador',
    dial_code: '+503',
    code: 'SV'
  },
  {
    name: 'Equatorial Guinea',
    dial_code: '+240',
    code: 'GQ'
  },
  {
    name: 'Eritrea',
    dial_code: '+291',
    code: 'ER'
  },
  {
    name: 'Estonia',
    dial_code: '+372',
    code: 'EE'
  },
  {
    name: 'Ethiopia',
    dial_code: '+251',
    code: 'ET'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    code: 'FK'
  },
  {
    name: 'Faroe Islands',
    dial_code: '+298',
    code: 'FO'
  },
  {
    name: 'Fiji',
    dial_code: '+679',
    code: 'FJ'
  },
  {
    name: 'Finland',
    dial_code: '+358',
    code: 'FI'
  },
  {
    name: 'France',
    dial_code: '+33',
    code: 'FR'
  },
  {
    name: 'French Guiana',
    dial_code: '+594',
    code: 'GF'
  },
  {
    name: 'French Polynesia',
    dial_code: '+689',
    code: 'PF'
  },
  {
    name: 'Gabon',
    dial_code: '+241',
    code: 'GA'
  },
  {
    name: 'Gambia',
    dial_code: '+220',
    code: 'GM'
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    code: 'GE'
  },
  {
    name: 'Germany',
    dial_code: '+49',
    code: 'DE'
  },
  {
    name: 'Ghana',
    dial_code: '+233',
    code: 'GH'
  },
  {
    name: 'Gibraltar',
    dial_code: '+350',
    code: 'GI'
  },
  {
    name: 'Greece',
    dial_code: '+30',
    code: 'GR'
  },
  {
    name: 'Greenland',
    dial_code: '+299',
    code: 'GL'
  },
  {
    name: 'Grenada',
    dial_code: '+1473',
    code: 'GD'
  },
  {
    name: 'Guadeloupe',
    dial_code: '+590',
    code: 'GP'
  },
  {
    name: 'Guam',
    dial_code: '+1671',
    code: 'GU'
  },
  {
    name: 'Guatemala',
    dial_code: '+502',
    code: 'GT'
  },
  {
    name: 'Guernsey',
    dial_code: '+44',
    code: 'GG'
  },
  {
    name: 'Guinea',
    dial_code: '+224',
    code: 'GN'
  },
  {
    name: 'Guinea-Bissau',
    dial_code: '+245',
    code: 'GW'
  },
  {
    name: 'Guyana',
    dial_code: '+595',
    code: 'GY'
  },
  {
    name: 'Haiti',
    dial_code: '+509',
    code: 'HT'
  },
  {
    name: 'Holy See (Vatican City State)',
    dial_code: '+379',
    code: 'VA'
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    code: 'HN'
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK'
  },
  {
    name: 'Hungary',
    dial_code: '+36',
    code: 'HU'
  },
  {
    name: 'Iceland',
    dial_code: '+354',
    code: 'IS'
  },
  {
    name: 'India',
    dial_code: '+91',
    code: 'IN'
  },
  {
    name: 'Indonesia',
    dial_code: '+62',
    code: 'ID'
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    dial_code: '+98',
    code: 'IR'
  },
  {
    name: 'Iraq',
    dial_code: '+964',
    code: 'IQ'
  },
  {
    name: 'Ireland',
    dial_code: '+353',
    code: 'IE'
  },
  {
    name: 'Isle of Man',
    dial_code: '+44',
    code: 'IM'
  },
  {
    name: 'Israel',
    dial_code: '+972',
    code: 'IL'
  },
  {
    name: 'Italy',
    dial_code: '+39',
    code: 'IT'
  },
  {
    name: 'Jamaica',
    dial_code: '+1876',
    code: 'JM'
  },
  {
    name: 'Japan',
    dial_code: '+81',
    code: 'JP'
  },
  {
    name: 'Jersey',
    dial_code: '+44',
    code: 'JE'
  },
  {
    name: 'Jordan',
    dial_code: '+962',
    code: 'JO'
  },
  {
    name: 'Kazakhstan',
    dial_code: '+77',
    code: 'KZ'
  },
  {
    name: 'Kenya',
    dial_code: '+254',
    code: 'KE'
  },
  {
    name: 'Kiribati',
    dial_code: '+686',
    code: 'KI'
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: '+850',
    code: 'KP'
  },
  {
    name: 'Korea, Republic of South Korea',
    dial_code: '+82',
    code: 'KR'
  },
  {
    name: 'Kuwait',
    dial_code: '+965',
    code: 'KW'
  },
  {
    name: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'KG'
  },
  {
    name: 'Laos',
    dial_code: '+856',
    code: 'LA'
  },
  {
    name: 'Latvia',
    dial_code: '+371',
    code: 'LV'
  },
  {
    name: 'Lebanon',
    dial_code: '+961',
    code: 'LB'
  },
  {
    name: 'Lesotho',
    dial_code: '+266',
    code: 'LS'
  },
  {
    name: 'Liberia',
    dial_code: '+231',
    code: 'LR'
  },
  {
    name: 'Libyan Arab Jamahiriya',
    dial_code: '+218',
    code: 'LY'
  },
  {
    name: 'Liechtenstein',
    dial_code: '+423',
    code: 'LI'
  },
  {
    name: 'Lithuania',
    dial_code: '+370',
    code: 'LT'
  },
  {
    name: 'Luxembourg',
    dial_code: '+352',
    code: 'LU'
  },
  {
    name: 'Macao',
    dial_code: '+853',
    code: 'MO'
  },
  {
    name: 'Macedonia',
    dial_code: '+389',
    code: 'MK'
  },
  {
    name: 'Madagascar',
    dial_code: '+261',
    code: 'MG'
  },
  {
    name: 'Malawi',
    dial_code: '+265',
    code: 'MW'
  },
  {
    name: 'Malaysia',
    dial_code: '+60',
    code: 'MY'
  },
  {
    name: 'Maldives',
    dial_code: '+960',
    code: 'MV'
  },
  {
    name: 'Mali',
    dial_code: '+223',
    code: 'ML'
  },
  {
    name: 'Malta',
    dial_code: '+356',
    code: 'MT'
  },
  {
    name: 'Marshall Islands',
    dial_code: '+692',
    code: 'MH'
  },
  {
    name: 'Martinique',
    dial_code: '+596',
    code: 'MQ'
  },
  {
    name: 'Mauritania',
    dial_code: '+222',
    code: 'MR'
  },
  {
    name: 'Mauritius',
    dial_code: '+230',
    code: 'MU'
  },
  {
    name: 'Mayotte',
    dial_code: '+262',
    code: 'YT'
  },
  {
    name: 'Mexico',
    dial_code: '+52',
    code: 'MX'
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dial_code: '+691',
    code: 'FM'
  },
  {
    name: 'Moldova',
    dial_code: '+373',
    code: 'MD'
  },
  {
    name: 'Monaco',
    dial_code: '+377',
    code: 'MC'
  },
  {
    name: 'Mongolia',
    dial_code: '+976',
    code: 'MN'
  },
  {
    name: 'Montenegro',
    dial_code: '+382',
    code: 'ME'
  },
  {
    name: 'Montserrat',
    dial_code: '+1664',
    code: 'MS'
  },
  {
    name: 'Morocco',
    dial_code: '+212',
    code: 'MA'
  },
  {
    name: 'Mozambique',
    dial_code: '+258',
    code: 'MZ'
  },
  {
    name: 'Myanmar',
    dial_code: '+95',
    code: 'MM'
  },
  {
    name: 'Namibia',
    dial_code: '+264',
    code: 'NA'
  },
  {
    name: 'Nauru',
    dial_code: '+674',
    code: 'NR'
  },
  {
    name: 'Nepal',
    dial_code: '+977',
    code: 'NP'
  },
  {
    name: 'Netherlands',
    dial_code: '+31',
    code: 'NL'
  },
  {
    name: 'Netherlands Antilles',
    dial_code: '+599',
    code: 'AN'
  },
  {
    name: 'New Caledonia',
    dial_code: '+687',
    code: 'NC'
  },
  {
    name: 'New Zealand',
    dial_code: '+64',
    code: 'NZ'
  },
  {
    name: 'Nicaragua',
    dial_code: '+505',
    code: 'NI'
  },
  {
    name: 'Niger',
    dial_code: '+227',
    code: 'NE'
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    code: 'NG'
  },
  {
    name: 'Niue',
    dial_code: '+683',
    code: 'NU'
  },
  {
    name: 'Norfolk Island',
    dial_code: '+672',
    code: 'NF'
  },
  {
    name: 'Northern Mariana Islands',
    dial_code: '+1670',
    code: 'MP'
  },
  {
    name: 'Norway',
    dial_code: '+47',
    code: 'NO'
  },
  {
    name: 'Oman',
    dial_code: '+968',
    code: 'OM'
  },
  {
    name: 'Pakistan',
    dial_code: '+92',
    code: 'PK'
  },
  {
    name: 'Palau',
    dial_code: '+680',
    code: 'PW'
  },
  {
    name: 'Palestinian Territory, Occupied',
    dial_code: '+970',
    code: 'PS'
  },
  {
    name: 'Panama',
    dial_code: '+507',
    code: 'PA'
  },
  {
    name: 'Papua New Guinea',
    dial_code: '+675',
    code: 'PG'
  },
  {
    name: 'Paraguay',
    dial_code: '+595',
    code: 'PY'
  },
  {
    name: 'Peru',
    dial_code: '+51',
    code: 'PE'
  },
  {
    name: 'Philippines',
    dial_code: '+63',
    code: 'PH'
  },
  {
    name: 'Pitcairn',
    dial_code: '+872',
    code: 'PN'
  },
  {
    name: 'Poland',
    dial_code: '+48',
    code: 'PL'
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    code: 'PT'
  },
  {
    name: 'Puerto Rico',
    dial_code: '+1939',
    code: 'PR'
  },
  {
    name: 'Qatar',
    dial_code: '+974',
    code: 'QA'
  },
  {
    name: 'Romania',
    dial_code: '+40',
    code: 'RO'
  },
  {
    name: 'Russia',
    dial_code: '+7',
    code: 'RU'
  },
  {
    name: 'Rwanda',
    dial_code: '+250',
    code: 'RW'
  },
  {
    name: 'Reunion',
    dial_code: '+262',
    code: 'RE'
  },
  {
    name: 'Saint Barthelemy',
    dial_code: '+590',
    code: 'BL'
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial_code: '+290',
    code: 'SH'
  },
  {
    name: 'Saint Kitts and Nevis',
    dial_code: '+1869',
    code: 'KN'
  },
  {
    name: 'Saint Lucia',
    dial_code: '+1758',
    code: 'LC'
  },
  {
    name: 'Saint Martin',
    dial_code: '+590',
    code: 'MF'
  },
  {
    name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    code: 'PM'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dial_code: '+1784',
    code: 'VC'
  },
  {
    name: 'Samoa',
    dial_code: '+685',
    code: 'WS'
  },
  {
    name: 'San Marino',
    dial_code: '+378',
    code: 'SM'
  },
  {
    name: 'Sao Tome and Principe',
    dial_code: '+239',
    code: 'ST'
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA'
  },
  {
    name: 'Senegal',
    dial_code: '+221',
    code: 'SN'
  },
  {
    name: 'Serbia',
    dial_code: '+381',
    code: 'RS'
  },
  {
    name: 'Seychelles',
    dial_code: '+248',
    code: 'SC'
  },
  {
    name: 'Sierra Leone',
    dial_code: '+232',
    code: 'SL'
  },
  {
    name: 'Singapore',
    dial_code: '+65',
    code: 'SG'
  },
  {
    name: 'Slovakia',
    dial_code: '+421',
    code: 'SK'
  },
  {
    name: 'Slovenia',
    dial_code: '+386',
    code: 'SI'
  },
  {
    name: 'Solomon Islands',
    dial_code: '+677',
    code: 'SB'
  },
  {
    name: 'Somalia',
    dial_code: '+252',
    code: 'SO'
  },
  {
    name: 'South Africa',
    dial_code: '+27',
    code: 'ZA'
  },
  {
    name: 'South Sudan',
    dial_code: '+211',
    code: 'SS'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    code: 'GS'
  },
  {
    name: 'Spain',
    dial_code: '+34',
    code: 'ES'
  },
  {
    name: 'Sri Lanka',
    dial_code: '+94',
    code: 'LK'
  },
  {
    name: 'Sudan',
    dial_code: '+249',
    code: 'SD'
  },
  {
    name: 'Suriname',
    dial_code: '+597',
    code: 'SR'
  },
  {
    name: 'Svalbard and Jan Mayen',
    dial_code: '+47',
    code: 'SJ'
  },
  {
    name: 'Swaziland',
    dial_code: '+268',
    code: 'SZ'
  },
  {
    name: 'Sweden',
    dial_code: '+46',
    code: 'SE'
  },
  {
    name: 'Switzerland',
    dial_code: '+41',
    code: 'CH'
  },
  {
    name: 'Syrian Arab Republic',
    dial_code: '+963',
    code: 'SY'
  },
  {
    name: 'Taiwan',
    dial_code: '+886',
    code: 'TW'
  },
  {
    name: 'Tajikistan',
    dial_code: '+992',
    code: 'TJ'
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dial_code: '+255',
    code: 'TZ'
  },
  {
    name: 'Thailand',
    dial_code: '+66',
    code: 'TH'
  },
  {
    name: 'Timor-Leste',
    dial_code: '+670',
    code: 'TL'
  },
  {
    name: 'Togo',
    dial_code: '+228',
    code: 'TG'
  },
  {
    name: 'Tokelau',
    dial_code: '+690',
    code: 'TK'
  },
  {
    name: 'Tonga',
    dial_code: '+676',
    code: 'TO'
  },
  {
    name: 'Trinidad and Tobago',
    dial_code: '+1868',
    code: 'TT'
  },
  {
    name: 'Tunisia',
    dial_code: '+216',
    code: 'TN'
  },
  {
    name: 'Turkey',
    dial_code: '+90',
    code: 'TR'
  },
  {
    name: 'Turkmenistan',
    dial_code: '+993',
    code: 'TM'
  },
  {
    name: 'Turks and Caicos Islands',
    dial_code: '+1649',
    code: 'TC'
  },
  {
    name: 'Tuvalu',
    dial_code: '+688',
    code: 'TV'
  },
  {
    name: 'Uganda',
    dial_code: '+256',
    code: 'UG'
  },
  {
    name: 'Ukraine',
    dial_code: '+380',
    code: 'UA'
  },
  {
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'AE'
  },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB'
  },
  {
    name: 'United States',
    dial_code: '+1',
    code: 'US'
  },
  {
    name: 'Uruguay',
    dial_code: '+598',
    code: 'UY'
  },
  {
    name: 'Uzbekistan',
    dial_code: '+998',
    code: 'UZ'
  },
  {
    name: 'Vanuatu',
    dial_code: '+678',
    code: 'VU'
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dial_code: '+58',
    code: 'VE'
  },
  {
    name: 'Vietnam',
    dial_code: '+84',
    code: 'VN'
  },
  {
    name: 'Virgin Islands, British',
    dial_code: '+1284',
    code: 'VG'
  },
  {
    name: 'Virgin Islands, U.S.',
    dial_code: '+1340',
    code: 'VI'
  },
  {
    name: 'Wallis and Futuna',
    dial_code: '+681',
    code: 'WF'
  },
  {
    name: 'Yemen',
    dial_code: '+967',
    code: 'YE'
  },
  {
    name: 'Zambia',
    dial_code: '+260',
    code: 'ZM'
  },
  {
    name: 'Zimbabwe',
    dial_code: '+263',
    code: 'ZW'
  }
]

export const calendarOptions = [
  { key: 'Current month', value: 'currentMonth' },
  { key: 'Previous month', value: 'previousMonth' },
  { key: 'Last 12 months', value: 'last12Months' },
  { key: 'Last 24 months', value: 'last24Months' },
  { key: 'Current year', value: 'currentYear' },
  { key: 'Previous year', value: 'previousYear' },
  { key: 'All time', value: 'allTime' },
  { key: 'Today', value: 'today' },
  { key: 'Current week', value: 'currentWeek' },
  { key: 'Previous week', value: 'previousWeek' }
]

export const monthList = [
  {
    label: 'January',
    value: 1
  },
  {
    label: 'February',
    value: 2
  },
  {
    label: 'March',
    value: 3
  },
  {
    label: 'April',
    value: 4
  },
  {
    label: 'May',
    value: 5
  },
  {
    label: 'June',
    value: 6
  },
  {
    label: 'July',
    value: 7
  },
  {
    label: 'August',
    value: 8
  },
  {
    label: 'September',
    value: 9
  },
  {
    label: 'October',
    value: 10
  },
  {
    label: 'November',
    value: 11
  },
  {
    label: 'December',
    value: 12
  }
]

export const exchangeList = [
  {
    label: 'Bombay Stock Echange',
    value: 1
  },
  {
    label: 'Calcutta Stock Exchange Ltd.',
    value: 2
  },
  {
    label: 'Metropolitan Stock Exchange of India Ltd.',
    value: 3
  },
  {
    label: 'Multi Commodity Exchange of India Ltd.',
    value: 4
  },
  {
    label: 'Indian Commodity Exchange Limited',
    value: 5
  }
]

export const addressTypes = [
  {
    label: 'Sales Office',
    value: 1
  },
  {
    label: 'Operation Centre',
    value: 2
  }
]

export const employeeSizeList = [
  {
    label: '<10',
    value: '<10'
  },
  {
    label: '10 to 49',
    value: '10 to 49'
  },
  {
    label: '50 to 99',
    value: '50 to 99'
  },
  {
    label: '100 to 499',
    value: '100 to 499'
  },
  {
    label: '500 to 999',
    value: '500 to 999'
  },
  {
    label: '1000 to 4999',
    value: '1000 to 4999'
  },
  {
    label: '5000 to 9999',
    value: '5000 to 9999'
  },
  {
    label: 'Over 10,000',
    value: 'Over 10,000'
  }
]

export const revenueBandList = [
  {
    label: '<$1 M',
    value: '<$1 M'
  },
  {
    label: '$1 M to $10 M',
    value: '$1 M to $10 M'
  },
  {
    label: '$10 M to $49 M',
    value: '$10 M to $49 M'
  },
  {
    label: '$50 M to $99 M',
    value: '$50 M to $99 M'
  },
  {
    label: '$100 M to $499 M',
    value: '$100 M to $499 M'
  },
  {
    label: '$500 M to $999 M',
    value: '$500 M to $999 M'
  },
  {
    label: '$1 B to $10 B',
    value: '$1 B to $10 B'
  },
  {
    label: 'Over $10 B',
    value: 'Over $10 B'
  }
]

export const sapCompetitors = JSON.parse(process.env.VUE_APP_SAP_COMPETITORS)
export const esgSentimentList = [
  {
    id: 'positive',
    name: 'Positive'
  },
  {
    id: 'negative',
    name: 'Negative'
  },
  {
    id: 'neutral',
    name: 'Neutral'
  }
]

export const entitiesStepperList = [
  {
    name: 'Basic Details'
  },
  {
    name: 'Address Details'
  },
  {
    name: 'Industry Details'
  },
  {
    name: 'Identifiers'
  },
  {
    name: 'AnaCredit Details'
  },
  {
    name: '2052A Details'
  },
  {
    name: 'Relationships'
  }
]

export const details2052aEvidenceId = {
  GSIB: 'G-SIB',
  GSIB_NAME: 'G-SIB Name',
  SUPRANATIONAL_ENTITY: 'Supranational Entity',
  SOVEREIGN_WEALTH_FUND: 'Sovereign Wealth Fund',
  COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION:
    'Community Development Financial Institution',
  MULTILATERAL_DEVELOPMENT_BANK: 'Multilateral Development Bank',
  INDUSTRIAL_LOAN_BANK: 'Industrial Loan Bank',
  BALANCE_SHEET_CONSOLIDATION_FOR_SPECIAL_PURPOSE:
    'Balance Sheet Consolidation for Special Purpose',
  SWAP_DEALER: 'Swap Dealer'
}

export const basicDetailEvidenceId = {
  ALIAS: 'Alias',
  LEGAL_ENTITY_NAME: 'Legal Entity Name',
  PREVIOUS_ENTITY_NAME: 'Previous Entity Name',
  LEGAL_STRUCTURE: 'Legal Structure',
  BUSINESS_STATUS: 'Business Status',
  ENTITY_TYPE: 'Entity Type',
  ENTITY_SUB_TYPE: 'Entity Sub-type',
  US_GSE_FLAG: 'US GSE Flag',
  BRANCH_INDICATOR: 'Branch Indicator',
  BUSINESS_STATUS_INACTIVATION_REASON: 'Business Status Inactivation Reason'
}

export const indstryDetailEvdId = {
  NAICS: 'NAICS',
  SECONDARY_NAICS: 'Secondary NAICS',
  NACE: 'NACE',
  SECONDARY_NACE: 'Secondary NACE',
  GERMAN_EXTENDED_NACE: 'Extended NACE',
  SIC: 'SIC',
  NATURE_OF_BUSINESS: 'Nature of Business'
}

export const activeStepIndexName = {
  basicDetails: 0,
  addressDetails: 1,
  industryDetails: 2,
  identifiers: 3,
  anaCreditDetails: 4,
  aedetails: 5,
  relationship: 6
}

export const entityStatus = {
  IN_PROGRESS: 'In Progress',
  TO_DO: 'Todo',
  REVIEW: 'Review',
  SUBMIT: 'Submitted / Ready for Delivery',
  REWORK: 'Rework',
  STAGING: 'Staging'
}

export const registeredAddressType = {
  ADDRESS_CODE: 'REG'
}

export const physicalAddressType = {
  ADDRESS_CODE: 'DOM'
}

export const REVENUE = {
  TWO_MILLION: 2e6,
  TEN_MILLION: 1e7,
  FIFTY_MILLION: 5e7,
  FOURTY_THREE_MILLION: 4.3e7
}

export const ENTERPRISE_NO_EMPL = {
  MICRO: 10,
  SMALL: 50,
  MEDIUM: 250
}

export const identifiersDetailsEvdIds = {
  CRN: 'Company Registration Number',
  INAI: 'Incorporation Authority',
  TAX_ID: 'Tax ID',
  LEI: 'Legal Entity Identifier',
  CIK: 'CIK',
  RSSD: 'RSSD',
  CRD: 'CRD'
}

export const ENTERPRISE_SIZE_ENUM = {
  // Reference table `business_size_type` { NAME: ID }
  SMALL: 2,
  LARGE: 4,
  MEDIUM: 3,
  MICRO: 1,
  NOT_AVAILABLE: 6,
  NOT_APPLICABLE: 5
}

export const anaCreditEvdIds = {
  NATIONAL_IDENTIFIERS: 'National Identifiers',
  NATIONAL_IDENTIFIERS_TYPE: 'National Identifier Type',
  INST_SECTOR: 'AnaCredit Institutional Sector',
  INST_SUB_SECTOR: 'AnaCredit Institutional Sub-Sector Code',
  LEGAL_FORM: 'AnaCredit Legal Form',
  LEGAL_PROCEED_DATE: 'Date of Initiation of Legal Proceedings',
  LEGAL_PROCEED_STATUS: 'Status of Legal Proceedings',
  ENTERPRISE_SIZE_DATE: 'Date of Enterprise Size',
  NO_OF_EMPL: 'Number of Employees',
  BALANCE_SHEET: 'Balance Sheet Total',
  ANNUAL_TURNOVER: 'Annual Turnover as Per Year',
  ENTERPRISE_SIZE: 'Enterprise size'
}

export const moduleMap = {
  voc: 'Voice of Customer (VoC)',
  settings: 'Settings',
  marketing: 'Marketing',
  news: 'News',
  'market-lens': 'Market Lens',
  tam: 'Customer Adoption Trends',
  voe: 'Voice of Employees (VoE)',
  'esg-controversies': 'ESG Controversies',
  'esg-daas': 'ESG DaaS',
  'reports-repository': 'Reports',
  'investment-insight': 'Investment Insights',
  playbook: 'Playbook',
  'data-management-platform': 'Data Management Platform',
  'publication-monitoring-tool': 'Publication Monitoring Tool'
}

export const control = {
  MIN_VALUE: 50,
  MAX_VALUE: 100
}
export const significantInfluence = {
  MIN_VALUE: 25,
  MAX_VALUE: 50
}

export const relationshipTypeObj = {
  CONTROL: 'Control',
  SIGNIFICANTINFLUENCE: 'Significant Influence'
}

export const ANA_C_IDENTIFIERS_NA = {
  TEXT: 'Not Available',
  RANK: 999
}

export const ANA_C_IDENTIFIERS_NOT_APP = {
  TEXT: 'Not Applicable',
  RANK: 998
}

export const workTypeEntities = {
  DATA_ENRICHEMENT: 'Data Enrichment',
  NEW_ONBOARDING: 'New Onboarding',
  PERIODIC_REVIEW: 'Periodic Review',
  CORPORATE_ACTION: 'Corporate Action'
}

export const DECLINED_REASON_TYPES = {
  CA: 'CA',
  ER: 'ER',
  REMAP: 'REMAP'
}

export const HTTP_ERROR_MESSAGES = {
  REQUEST_CANCELLED: 'Request canceled due to new request.'
}

export const PM_ROLE = {
  ID: 5
}

export const PARSE_ADDRESS_FIELD_MAPPING = {
  COMPANY_NAME: 'C/O or Company Name',
  DEPARTMENT: 'Department',
  SUB_DEPARTMENT: 'Sub Department',
  STREET_NAME: 'Street Name',
  BUILDING_NUMBER: 'Building Number',
  BUILDING_NAME: 'Building Name',
  FLOOR: 'Floor',
  POST_BOX: 'Post Box',
  ROOM: 'Room',
  POSTCODE: 'postcode',
  COUNTRY: 'Country',
  TOWN_NAME: 'Town name',
  TOWN_LOCATION_NAME: 'Town Location Name',
  DISTRICT_NAME: 'District Name',
  COUNTRY_SUB_DIVISION: 'Country Subdivision'
}

// 2052A-related field names
export const ENTITY_2052A_FIELD_NAMES = {
  G_SIB: 'gSib',
  COMMUNITY_DEV_FIN_INST: 'comDevFinInst',
  MULTILATERAL_DEV_BANK: 'multiDevBank',
  INDUSTRIAL_LOAN_BANK: 'indLoanBank',
  BALANCE_SHEET_CONSOL: 'balSheetConsol',
  SWAP_DEALER: 'swapDealer',
  SUPRANATIONAL_ENTITY: 'supraEntity',
  SOV_WEALTH_FUND: 'sovWealthFund'
}

export const identifiersFields = {
  LEI: 'LEI',
  RSSD: 'RSSD',
  CRD: 'CRD',
  CIK: 'CIK'
}
