<template>
  <side-panel-vue>
    <FilterHeader v-if="active_page !== 'profile' && !hideFilters" />
    <div
      v-if="active_page !== 'profile' && !hideFilters"
      class="filter-container"
      :class="[isCollapsed ? 'collapsed' : '']"
    >
      <div class="each-dropdown">
        <MultiSelectorVue
          label="Projects"
          placeholder="Select Projects"
          :list="projectData"
          :selected="selectedProjectData"
          @onChange="onChangeReportTypes"
          :sidebarHeightApplied="true"
        />
      </div>
      <div class="each-dropdown month-picker">
        <TimePeriodPicker :key="monthPickerKey" />
      </div>
    </div>
  </side-panel-vue>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MultiSelectorVue from '@/components/Dropdowns/MultiSelector.vue'
import TimePeriodPicker from '@/components/Dropdowns/TimePeriodPicker.vue'
import FilterHeader from '@/components/GlobalFilters/FilterHeader.vue'
import SidePanelVue from './SidePanel.vue'

/** This component contains the filters in the side panel */
export default {
  name: 'ReportSidePanel',
  components: {
    FilterHeader,
    MultiSelectorVue,
    SidePanelVue,
    TimePeriodPicker
  },
  data() {
    return {
      monthPickerKey: 1,
      projectData: [],
      selectedProjectData: []
    }
  },
  computed: {
    ...mapState('common', ['active_page', 'isCollapsed']),
    ...mapState('pmtDashboardFilters', [
      'projectList',
      'selectedProjectList',
      'activePmtDashboardTimePeriod'
    ]),
    hideFilters() {
      return this.$route.meta.hideFilters
    }
  },
  watch: {
    projectList(newList) {
      this.projectData = newList;
      this.selectedProjectData = newList;
    },
    selectedProjectList(newSelectList) {
      this.selectedProjectData = newSelectList
    }
  },
  methods: {
    ...mapActions('pmtDashboardFilters', [
      'getProjectList',
      'updateSelectedProjectList'
    ]),
    onChangeReportTypes(selectedReportTypes) {
      this.updateSelectedProjectList(selectedReportTypes)
    }
  },
  mounted() {
    this.getProjectList()
  }
}
</script>

<style lang="scss" src="@/components/Dropdowns/common.scss" scoped></style>

<style lang="scss" scoped>
.side-panel-container {
  height: 100%;
  background: var(--sidebar-background-color);

  .product-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    img {
      width: 60px;
      object-fit: contain;
      height: 100%;
    }
  }
  .side-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .filter-container {
      overflow-y: auto;
      .each-dropdown {
        padding: 10px 20px;
        width: 225px;
        min-width: max-content; // This is to prevent displacement of filter text when the sidepanel is expanded
      }
    }
    .collapsed {
      display: none;
    }
  }
}

::v-deep .instructions-container {
  padding: 0 25px;
  .sidepanel-title {
    text-transform: uppercase;
    color: white;
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 0.75px solid var(--inava-primary-light);
  }
  .each-dropdown {
    margin-top: 15px;
    margin-bottom: 15px;
    .top-label {
      color: white;
    }
  }
  .template-file-section {
    color: white;
    font-size: 12px;
    .description {
      margin-bottom: 15px;
    }
    .template-container {
      display: flex;
      border: 1px dashed var(--inava-primary-light);
      border-radius: 4px;
      padding: 15px 20px;
      margin-bottom: 15px;
      transition: 0.1s ease-in;
      .download-meta-info-btn {
        display: flex;
      }
      &:hover {
        cursor: pointer;
        border: 1px solid var(--inava-primary);
        .csv-icon {
          opacity: 1;
        }
        .action-text {
          color: white;
        }
      }
      .csv-icon {
        width: 25px;
        height: 28px;
        margin-right: 10px;
        object-fit: contain;
        opacity: 0.4;
        transition: 0.1s ease-in;
      }
      .action-text {
        color: var(--filter-border-color);
        font-size: 12px;
        transition: 0.1s ease-in;
      }
    }
    .download-status-message {
      color: #f57777;
    }
  }
  .upload-instructions {
    font-size: 12px;
    span {
      color: white;
    }
    ol {
      margin-left: 15px;
      li {
        color: var(--filter-border-color);
        &::marker {
          color: var(--inava-primary);
        }
      }
    }
  }
}

@media screen and (min-width: 1700px) {
  ::v-deep .instructions-container {
    padding: 0 25px;
    .sidepanel-title {
      font-size: 16px;
      margin-top: 45px;
      padding-bottom: 15px;
    }
    .each-dropdown {
      margin-top: 25px;
      margin-bottom: 25px;
    }
    .template-file-section {
      font-size: 14px;
      .description {
        margin-bottom: 12px;
      }
      .template-container {
        padding: 15px 20px;
        margin-bottom: 50px;
        .csv-icon {
          width: 33px;
          height: 37px;
          margin-right: 10px;
        }
        .action-text {
          font-size: 14px;
        }
      }
    }
    .upload-instructions {
      font-size: 14px;
      ol {
        margin-left: 15px;
      }
    }
  }
}
</style>
