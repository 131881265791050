var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.currentModule === 'voc' ||
      _vm.currentModule === 'voe' ||
      _vm.currentModule === 'reports-repository' ||
      _vm.currentModule === 'data-management-platform' )&&
    !_vm.$route.meta.hideNestedTopNav
  )?_c('div',{ref:"innerNavigationContainer",staticClass:"nested-navigation-container"},[_c('div',{staticClass:"middle-layer"},[_c('div',{staticClass:"page-groups-list"},[_vm._l((_vm.pageGroup),function(item){return [(item.isDropdown)?_c('div',{key:item.routeName,staticClass:"page-group",class:[item.group === _vm.$route.meta.group && 'active'],attrs:{"to":_vm.getDestinationRouteObject(item)}},[_c('span',[_vm._v(" "+_vm._s(item.label)+" "),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"7.072","height":"7.072","viewBox":"0 0 7.072 7.072","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"fill":"#a8aab7","d":"M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"}})])]),_c('ul',{staticClass:"dropdown"},_vm._l((_vm.pageList(item.group)),function(item){return _c('li',{key:item.routeName,staticClass:"list-item",class:{ selected: _vm.active_page === item.routeName }},[_c('router-link',{attrs:{"to":{ name: item.routeName }}},[_vm._v(_vm._s(item.label))])],1)}),0)]):_vm._e(),(!item.isDropdown)?_c('router-link',{key:item.routeName,staticClass:"page-group",class:[item.group === _vm.$route.meta.group && 'active'],attrs:{"to":_vm.getDestinationRouteObject(item)}},[_vm._v(_vm._s(item.label))]):_vm._e()]})],2),(_vm.currentModule === 'voc' || _vm.currentModule === 'voe')?_c('div',{staticClass:"last-updated"},[_c('p',{staticClass:"last-updated-text"},[_vm._v("Last Updated:")]),(_vm.lastUpdatedDate)?_c('p',{staticClass:"last-updated-date"},[_vm._v(" "+_vm._s(_vm.lastUpdatedDate)+" ")]):_vm._e()]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }