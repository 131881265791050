<template>
  <div class="dmp-searchable-select">
    <label class="typo__label"
      >{{ label }} <span class="isMandatory" v-if="isMandatory">*</span>
      <b-tooltip type="is-info" v-if="isTooltip" :label="tooltipMsg" multilined>
        <b-icon icon="information-variant-circle" size="is-small"> </b-icon>
      </b-tooltip>
      <a
        v-if="isAdditionalInfoLink"
        :href="additionalLinkInfo.url"
        target="_blank"
        >{{ additionalLinkInfo.text }}</a
      ></label
    >
    <multiselect
      v-model="selectedValue"
      :options="trimmedData"
      :placeholder="placeholder"
      @select="handleSelect"
      @remove="handleDeSelect"
      @open="handleDropdown('open')"
      @close="handleDropdown"
      :select-on-tab="false"
      :multiple="false"
      :taggable="false"
      :label="text_field"
      :title="hoverLabel"
      :track-by="value_field"
      :loading="isLoading"
      :disabled="disabled"
      :show-labels="false"
      ref="multiselectRef"
    >
      <template slot="clear" slot-scope="props">
        <button
          class="close-icon"
          v-if="selectedValue && selectedValue.value"
          @click="remove()"
        >
          X
        </button>
      </template>
      <template slot="option" slot-scope="{ option }">
        <div
          @mouseover="handleOptionMouseOver(option)"
          @mouseleave="handleOptionMouseLeave(option)"
        >
          {{ option.text }}
        </div>
      </template>
      <template slot="noResult">
        <div class="custom-no-result">No data found</div>
      </template>
      <template v-slot:noOptions>
        <span>No data found</span>
      </template>
    </multiselect>

    <div v-if="errorToDisplay" class="required-error">
      {{ errorToDisplay }}
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'SearchableSelect',
  components: {
    Multiselect
  },
  props: {
    label: String,
    placeholder: String,
    data: Array,
    isLoading: Boolean,
    value_field: String,
    text_field: String,
    disabled: Boolean,
    value: [String, Number, Boolean, Object],
    hasEvidenceButton: Boolean,
    isMandatory: Boolean,
    mandatorySubmit: Boolean,
    textLength: Number,
    onChangeName: {
      type: Function
    },
    submitted: {
      type: Boolean
    },
    showSecondaryError: {
      type: Boolean,
      default: false
    },
    secondaryError: {
      type: String,
      default: ''
    },
    isAutoSelect: {
      type: Boolean,
      default: false
    },
    customErrorMessage: {
      type: String,
      default: ''
    },
    isTooltip: {
      type: Boolean,
      default: false
    },
    tooltipMsg: {
      type: String,
      default: ''
    },
    isAdditionalInfoLink: {
      type: Boolean,
      default: false
    },
    additionalLinkInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectedValue: this.value,
      hoverLabel: ''
    }
  },
  computed: {
    trimmedData: {
      get: function () {
        return this.data?.map((d) => {
          const temp = {}
          temp[this.value_field] = d[this.value_field]
          temp[this.text_field] = d[this.text_field]
          return temp
        })
      },
      set: function () {}
    },
    errorToDisplay() {
      if (this.isRequiredError()) {
        return this.customErrorMessage
          ? this.customErrorMessage
          : `${this.label} is required`
      } else if (
        this.submitted &&
        this.showSecondaryError &&
        this.secondaryError
      ) {
        return this.secondaryError
      } else {
        return ''
      }
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.selectedValue = this.data.find(
          (d) => d?.[this.value_field] === newValue?.[this.value_field]
        )
        this.selectedValue = this.selectedValue ?? newValue // fix for value not in list
      } else if (this.trimmedData.length !== 1 && this.isAutoSelect) {
        // condition will satisfy only for autoselect
        this.selectedValue = null
      } else if (!this.isAutoSelect) {
        // condition will satisfy only for non autoselect
        this.selectedValue = null
      }
      if (this.trimmedData.length !== 1 && this.isAutoSelect) {
        this.$emit('onChangeName', this.selectedValue)
      } else {
        this.$emit('onChangeName', newValue)
      }
    },

    // it will auto select value if the only one data present in list
    trimmedData() {
      if (
        this.trimmedData &&
        this.trimmedData.length === 1 &&
        this.isAutoSelect &&
        [null, undefined].includes(this.value)
      ) {
        this.selectedValue = this.trimmedData[0]
        this.$emit('onChangeName', this.selectedValue)
      }
    }
  },
  methods: {
    handleSelect(option) {
      let value = null
      if (option) {
        value = this.data.find((d) => {
          return d?.[this.value_field] === option?.[this.value_field]
        })
      }
      this.$emit('onChangeName', value)
    },
    handleDeSelect() {
      this.$emit('onChangeName', null)
    },

    handleDropdown(eventName) {
      // Scroll to the leftmost position when the dropdown opens or closes
      const dropdown = this.$refs.multiselectRef.$el.querySelector(
        '.multiselect__content-wrapper'
      )

      if (eventName === 'open') {
        this.$emit('openDropdown')
      }
      if (dropdown) {
        dropdown.scrollLeft = 0
      }
    },
    handleOptionMouseOver(option) {
      // Handle mouse over event on individual option
      this.hoverLabel = option.text
    },

    handleOptionMouseLeave(option) {
      // Handle mouse over event on individual option
      this.hoverLabel = ''
    },
    remove() {
      this.selectedValue = null
      this.$emit('onChangeName', null)
    },
    isRequiredError() {
      return (
        this.submitted &&
        this.isMandatory &&
        this.mandatorySubmit &&
        (!this.value ||
          (typeof this.value === 'object' &&
            (!('value' in this.value) ||
              this.value.value === null ||
              this.value.value === '')))
      )
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.content ul {
  margin-left: 0px;
  margin-top: 0px;
}
.dmp-searchable-select {
  display: flex;
  flex-direction: column;
  width: 100%;
  .required-error {
    color: red;
    position: relative;
    font-size: 12px;
  }

  .close-icon {
    position: absolute;
    width: 20px;
    height: 30px;
    top: 4px;
    right: 25px;
    padding: 4px 4px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    background: white;
    border: none;
  }

  .content ul {
    margin-left: 0px;
  }

  .typo__label {
    text-align: left;
    font-size: 12px;
    margin-top: 0px;
    font-family: Quicksand;
    font-weight: 600;
    letter-spacing: 0px;
    color: #7d7d7d;
    margin-bottom: 5px;
    opacity: 1;

    .b-tooltip {
      .tooltip-content {
        background: #c4c8f8;
        color: #181818;
        font-weight: 500;
      }
    }
  }
  .multiselect--disabled {
    opacity: 0.5;
  }
  .multiselect__select {
    background: transparent;
    width: 25px;
  }
  .isMandatory {
    color: red;
    font-size: 1rem;
  }
  .multiselect__placeholder {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    font-family: Quicksand;
    letter-spacing: 0.18px;
    color: #cccccc;
    opacity: 1;
  }

  .multiselect__tags {
    min-height: 36px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    font-family: Quicksand;
    letter-spacing: 0.18px;
    color: #cccccc;
    opacity: 1;
  }

  .multiselect__option {
    font-family: Quicksand;
    font-size: 13px;
    font-weight: 600;
    color: #1e1e1e;
  }
  .multiselect__single {
    font-family: Quicksand;
    font-size: 13px;
    font-weight: 600;
    width: 95%;
    color: #1e1e1e;
  }

  .data-not-found-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .text {
      text-align: left;
      font: 10px;
      letter-spacing: 0px;
      color: #e10d0d;
      opacity: 1;
    }
    .add-button {
      .button {
        background: #535eeb 0% 0% no-repeat padding-box;
        opacity: 1;
        text-align: left;
        font-size: 10px;
        font-weight: 500;
        font-family: Quicksand;
        letter-spacing: 0.18px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }
}

@media only screen and (min-width: 1450px) and (max-width: 1700px) {
  .dmp-searchable-select ::v-deep {
    .typo__label {
      font-size: 14px;
    }
    .multiselect__option {
      font-size: 14px;
    }
    .multiselect__single {
      font-size: 14px;
    }
  }
}
@media (min-width: 1700px) {
  .dmp-searchable-select {
    .typo__label {
      font-size: 16px;
    }
    .multiselect__option {
      font-size: 16px;
    }
    .multiselect__single {
      font-size: 16px;
    }
  }
}
</style>
